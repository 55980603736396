/**
 *
 * Footer
 * ==========================================================================
 * All footer (the big blue bar below every page) related styles
 *
 *
 */

/*-------------------------------------------------------------------------*/

/* Sub footer above the blue main footer */

#sub-footer {
  padding: 0 10px;
  box-sizing: border-box;
  text-align: center;
  .footer-statistics {
  	color:#333;
  	opacity:.5;
  	font-weight:700;
  	font-size:.8333333336em;
  	padding:0;
    &:hover {
    	opacity:1;
    }
    a {
      color:#333;
    }
  }
  h3 {
    font-size: 1.4em;
    font-weight: 400;
    line-height: 1.2;
    color: #999;
    margin: 30px 0 0 0;
  }
  .collaborations {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 10px 0;
      }
    }
    img {
      width: 160px;
      height: auto;
    }
  }
  .funding {
    img {
      position: relative;
      max-width: 220px;
      height: auto;
      margin: 10px 0;
    }
  }
  @media screen and (min-width: $tabletViewportWidth) {
    padding: 0 60px 0 215px;
    text-align: left;
    .footer-statistics {
    	margin-top: 10px;
    }
    .collaborations ul {
    	display: flex;
    	align-items: center;
      li {
        position: relative;
        box-sizing: border-box;
        flex: 0 1 auto;
        padding: 0 10px;
        img {
          max-width: 100%;
          height: auto;
          box-sizing: border-box;
          margin: 0;
        }
      }
    }
    .home & {
      padding: 0 25px 0 18px;
    }
  }
  @media screen and (min-width: $ultraWideViewportWidth) {
    h3 {
    	margin-top: 50px;
    	white-space: nowrap;
    }
    .main-logo-bar {
      display: flex;
      justify-content: space-between;
      .collaborations {
        flex: 1 1 auto;
        ul li {
          flex: 1 1 auto;
        }
      }
      .funding {
        margin-left: 50px;
        img {
          max-width: 180px;
          margin-top: 15px;
        }
      }
      .home & {  
        padding-right: 30px;
      }
    }
  }
}

/*-------------------------------------------------------------------------*/

/* Main footer */

#footer {
  background: $hzdrBlueDark;
  margin-top: 30px;
  padding-bottom: 30px;
  .row {
    position: relative;
  }
  .membership {
    text-align: center;
    a {
      position: relative;
      display: inline-block;
      width: 130px;
      height: 80px;
      overflow: hidden;
      white-space: nowrap;
      text-indent: 200%;
      background-size: 130px 80px;
      &.member-helmholtz {
        background-image: url(../images/footer_hh_de.png);
        @media only screen and (min--moz-device-pixel-ratio: 2),only screen and (-o-min-device-pixel-ratio: 21),only screen and (-webkit-min-device-pixel-ratio: 2),only screen and (min-device-pixel-ratio: 2) {
          background-image: url(../images/footer_hh_de@2x.png);
        }
        html[lang="en"] & {
          background-image: url(../images/footer_hh_en.png);
          @media only screen and (min--moz-device-pixel-ratio: 2),only screen and (-o-min-device-pixel-ratio: 21),only screen and (-webkit-min-device-pixel-ratio: 2),only screen and (min-device-pixel-ratio: 2) {
            background-image: url(../images/footer_hh_en@2x.png);
          }
        }
      }
      &.member-dd-concept {
        background-image: url(../images/footer_ddconcept_de.png);
        @media only screen and (min--moz-device-pixel-ratio: 2),only screen and (-o-min-device-pixel-ratio: 21),only screen and (-webkit-min-device-pixel-ratio: 2),only screen and (min-device-pixel-ratio: 2) {
          background-image: url(../images/footer_ddconcept_de@2x.png);
        }
        html[lang="en"] & {
          background-image: url(../images/footer_ddconcept_en.png);
          @media only screen and (min--moz-device-pixel-ratio: 2),only screen and (-o-min-device-pixel-ratio: 21),only screen and (-webkit-min-device-pixel-ratio: 2),only screen and (min-device-pixel-ratio: 2) {
            background-image: url(../images/footer_ddconcept_en@2x.png);
          }
        }
      }
    }
    br {
      display: none;
    }
  }
  .adress {
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-width: 1px 0;
    padding: 20px 0;
    text-align: center;
    a {
      line-height: 24px;
      &.audit-link {
        position: relative;
        display: inline-block;
        float: none;
        width: 125px;
        height: 30px;
        background: url(../images/audit_beruffamilie.png) no-repeat center center;
        background-size: 125px 30px;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;
        img {
          display: none;
        }
        @media only screen and (min--moz-device-pixel-ratio: 2),only screen and (-o-min-device-pixel-ratio: 21),only screen and (-webkit-min-device-pixel-ratio: 2),only screen and (min-device-pixel-ratio: 2) {
          background-image: url(../images/audit_beruffamilie@2x.png);
        }
      }
    }
    .hlist ul {
      li {
        float: none;
        .last {
          display: block;
          float: none;
          text-align: center;
          width: 100%;
        }
      }
    }
  }
  .footer_content {
    position: relative;
    background: transparent;
    margin: 0 10px;
    padding: 30px 0;
    width: auto;
    .mod_navigation {
      float: none;
      ul {
        margin-bottom: 16px;
        display: block;
        float: none;
      }
    }
  }
  .footer-statistics {
    position: absolute;
    top: -75px;
    left: 0;
    color: #333;
    opacity: .5;
    font-weight: 700;
    font-size: .8333333336em;
    padding: 0;
    &:hover {
      opacity: 1;
    }
    a {
      color: #333;
    }
  }
  .route {
    position: relative;
    min-height: 150px;
    min-width: 210px;
    a.map-link {
      position: relative;
      width: 210px;
      height: 150px;
      opacity: .5;
      background: url(../images/footermap.png) no-repeat center bottom;
      background-size: 210px 150px;
      display: block;
      margin: 0 auto;
      &:hover {
        opacity: 1;
      }
    }
  }
  &.minifooter {
    padding-bottom: 0;
    .footer_content {
      padding: 5px;
      .row, .mod_navigation ul {
        margin: 0;
      }
    }
    .membership, .adress p, .route {
      display: none;
    }
    .adress {
      margin: 0;
      padding: 0;
      border: 0 none;
      float: right;
      width: auto;
    }
    .footer-statistics {
      position: relative;
      color: white;
      top: auto;
      left: 0;
      opacity: 1;
      font-size: 12px;
      a {
        color: white;
      }
    }
  }
  @media screen and (min-width: $tabletViewportWidth) {
    .footer_content {
      padding: 30px 0 0;
      margin: 0;
    }
    .row {
      margin: 0 55px;
      width: auto;
    }
    .col {
      width: 33%;
      text-align: left;
    }
    .footer-statistics {
      top: -60px;
      left: 160px;
    }
    .membership {
      text-align: left;
      a.member-dd-concept {
        margin: 20px 20px 0 10px;
      }
    }
    .adress {
      border: 0 none;
      padding: 0;
      ul li:nth-child(2) a {
        border-right: 1px solid white;
      }
      .hlist .last {
        display: inline;
        width: auto;
      }
      a.audit-link {
        width: 24px;
        height: 24px;
        background-position: left center;
        overflow: hidden;
        text-indent: 100%;
        line-height: 12px;
        margin: 0 0 -8px -2px;
        padding: 0;
        &:hover {
          position: absolute;
          width: 200px;
        }
      }
    }
    .route {
      margin: 20px 0;
      max-width: none;
      a.map-link {
        margin: 0;
        float: right;
      }
    }
  }
  @media screen and (min-width: $tabletLandscapeViewportWidth) {
    .row {
      margin: 0 55px 0 217px;
    }
    .footer-statistics {
      left: 0;
    }
    .membership a.member-dd-concept {
      margin: 8px 0 0;
    }
  }
  @media screen and (min-width: $desktopViewportWidth) {
    .footer_content {
      width: 1170px;
      margin: 0 auto;
      .fullsize & {
        width: auto;
      }
    }
    .adress br.mobile-break {
      display: none;
    }
    .membership a.member-dd-concept {
      margin-left: 30px;
    }
  }
  @media screen and (min-width: $ultraWideViewportWidth) {
    .footer_content {
      width: 1475px;
    }
    .membership a.member-dd-concept {
      margin-left: 40px;
    }
  }
}

/*-------------------------------------------------------------------------*/

/* EOF */
