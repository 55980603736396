/**
 *
 * The intranet toolbar
 * ==========================================================================
 * Setup for the blue icon bar pinned to the right border
 * of intranet pages
 *
 */

@import "variables";
@import "mixins";

/*-------------------------------------------------------------------------*/

.intranet {
  .intranet-toolbar {
    position: absolute;
    top: 0;
    right: 0;
    transition: $transition;
    transform: translateX(100%);
    z-index: 99;
    &.slide-in {
      transform: translateX(0);
      ul {
        box-shadow: -1px 2px 6px rgba(black,.15)
      }
    }
    button {
      position: absolute;
      top: 0;
      left: -40px;
      width: 40px;
      height: 40px;
      background: rgba($hzdrBlue,.95) url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTE3LjIgOC43bC0xLjgtLjkuMS0yLjMgNC0yLjNjLS4yLS4yLS42LS42LS44LS43LTEuNy0uNy0zLjYtLjMtNC45IDEtMS4yIDEuMi0xLjYgMy0xLjEgNC42TDMgMThzLTEuMiAxLjEuMiAyLjVjMS41IDEuNSAzLS4xIDMtLjFsOS4zLTkuNGMxLjYuNSAzLjQuMSA0LjYtMS4xIDEuMS0uOSAxLjUtMi4zIDEuMy0zLjZsLTQuMiAyLjR6IiBmaWxsPSIjZmZmIi8+PC9zdmc+) no-repeat center center;
      border: 0 none;
      outline: none;
      cursor: pointer;
    }
    ul {
      position: relative;
      background: rgba($hzdrBlue,.95);
      box-shadow: 1px 2px 6px rgba(black,.3);
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        padding: 7px 10px !important;
        margin: 0 !important;
        border-bottom: 1px dotted rgba(white,.5);
        &:before {
          display: none;
          content: none;
        }
        &:last-child {
          border-bottom: 0 none;
        }
        a {
          position: relative;
          padding: 5px 10px 5px 30px;
          color: white;
          text-decoration: none;
          display: block;
          img {
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -10px;
            height: 20px;
            width: 20px;
          }
          .entry-count, .badge {
            position: relative;
            background: white;
            color: $hzdrBlue;

            display: inline-block;
            text-align: center;
            vertical-align: bottom;

            font-size: 70%;
            line-height: 1;
            white-space: nowrap;

            border-radius: .25rem;
            padding: .25em .4em;
            margin-left: .4rem;
          }
        }
      }
    }
    @media screen and (min-width: $tabletViewportWidth) {
      button {
        top: 0;
      }
    }
    @media screen and (min-width: $tabletLandscapeViewportWidth) {
      top: 0;
      right: 15px;
      button {
        display: none;
      }
      ul {
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        opacity: 1;
        li {
          padding:0  !important;
          a {
            padding: 10px;
            text-indent: 40px;
            overflow: hidden;
            display: block;
            width: 40px;
            box-sizing: border-box;
            transition: $transition;
            color: rgba(white,0);
            white-space: nowrap;
            img {
              left: auto;
              right: 10px;
            }
            &:hover {
              background: rgba(white,.2);
              color: white;
            }
          }
        }
      }
      .no-touchevents & {
        &:hover {
          ul {
            li a {
              padding: 10px 40px 10px 10px;
              text-indent: 0;
              width: 240px;
              color: white !important;
            }
          }
        }
      }
      .touchevents-enabled & {
        button {
          display: block;
        }
        ul {
          right: 40px;
          opacity: 0;
          pointer-events: none;
          transform: scale(0) translateX(100%);

          li a {
            padding: 10px 40px 10px 10px;
            width: auto;
            color: white;
            text-indent: 0;
          }
        }
        &.slide-in {
          ul {
            opacity: 1;
            transform: scale(1) translateX(0);
            pointer-events: all;  
          }
        }
      }
    }
  }
}

/*-------------------------------------------------------------------------*/

// EOF
