/**
 *
 * Print styles
 * ==========================================================================
 * A few styles for website print outs
 *
 *
 */

/*-------------------------------------------------------------------------*/

@media only print {
  @page {
    margin:1cm 1cm 1cm;
  } 
  body {
    background:white;
    font-size:9pt;
    line-height:1.7;
  }
    
  #header {
    display:block;
    box-shadow:none;
    border-bottom:1px solid #ccc;
    height:3cm;
    margin:0;
    overflow:hidden;
  }
  
  #header .logo1:before {
    position:absolute;
    left:-30px;
    top:0;
    display:block;
    content:url(../images/headerlogo.png);
    transform:scale(.6);
  }
  
  #header .logo1 a {
    display:none;
  }
  
  #header .navigation-toggle {
    display:none;
  }
  
  #col4 {
    margin:0;
    box-shadow:none;
  }
  
  #col4_content {
    padding:0;
  }
  
  .home section h3:before {
    font-size:24px;
  }
  
  .home-service,.home-media {
    display:none;
  }
  
  .home section.home-sectors {
    position:absolute;
    top:-3.6cm;
    right:.4cm;
    height:auto;
    background:transparent;
    margin:0;
  }
  
  .sector-indicator,.sector-bars,.carousel-control,section.home-sectors .carousel-inner {
    display:none;
  }
  
  .home #col4 section.home-sectors ol.sectors-textcloud {
    display:block;
    list-style-type:none;
  }
  
  .home #col4 section.home-sectors .sectors-textcloud li {
    font-size:1.6em;
    background:transparent;
    color:#666;
    padding:0;
    text-align:right;
    line-height:.9em;
  }
  
  .home #col4 section.home-sectors .sectors-textcloud li.energy {
    padding-right:20px;
  }
  
  .home #col4 section.home-sectors .sectors-textcloud li.health {
    padding-right:40px;
  }
  
  .home #col4 section.home-sectors .sectors-textcloud li.matter {
    padding-right:0;
  }
  
  .home-news .teasertext {
    display:block;
  }
  
  .home-news .teasertext a.more {
    display:none;
  }
  
  .home-publications,.home-futureprojects {
    page-break-before:always;
    margin-top:130px;
  }
  
  .home #col4 section.home-locations .carousel-inner {
    background:transparent;
  }
  
  .home #col4 section.home-locations .carousel .item {
    width:auto;
    height:auto;
    float:left;
    display:block;
  }
  
  .home #col4 section.home-locations .carousel .item img {
    height:2.2cm;
    width:auto;
    margin:0 .2cm;
  }
  
  @page {
    margin:2cm 1cm 1cm;
  }
}

/*-------------------------------------------------------------------------*/

/* EOF */
