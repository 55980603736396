/**
 *
 * Navigations
 * ==========================================================================
 * All general styles for navigation elements
 *
 *
 */

/*-------------------------------------------------------------------------*/

/* Basic setup for header and sidebar navigation */

#navigation {
  position: relative;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  height: 0;
  overflow: hidden;
  transition: all 500ms ease-in-out;
  &.open {
    height: auto;
    //overflow: scroll;
    margin-bottom: 10px;
    .mobile-fwd {
      /* background-color: $hzdrBlueUltraLight; */
      padding: 10px 15px 10px 10px !important;
      margin-top: 10px;
      margin-bottom: -19px;
      border-top: 1px solid $hzdrOrange;
      border-bottom: 1px solid $hzdrBlueUltraLight;
      strong {
        font-size: inherit;
      }
    }
  }
  .inside {
    line-height:2.3em;
  }
  .col1, .col2, .col3 {
    background-color: $hzdrBlueDark;
    color:white;
    overflow:hidden;
  }
  .hlist ul {
    width: 99%;
  }
  .col2 {
    position: relative;
    width: 100%;
    background: #888;
    text-align: right;
    margin-bottom: 4px;
    padding: 6px 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    .hlist {
      position: relative;
      line-height: 1.6;
      ul {
        width: auto;
        overflow: visible;
        float: none;
        margin: 0 !important;
        li {
          position: relative;
          display: inline-block;
          float: none;
          padding: 0 5px;
          border-left: 1px solid white;
          &:first-child {
            border-left: 0 none;
          }
          a {
            padding: 5px;
            border: 0 none;
          }
        }
      }
    }
    .social-media {
      ul {
        width: auto;
        list-style: none;
        margin: 0;
        padding: 0;
        line-height: 1;
        li {
          display: inline-block;
          margin: 0 7px 0 0;
          float: left;
          a {
            position: relative;
            height: 24px;
            width: 24px;
            display: inline-block;
            @include text-hide();
            &:before {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: block;
              text-indent: 0;
              font-size: 30px;
              color: white;
            }
            &.icon-hzdr-facebook {
              display: none;
            }
          }
        }
      }
    }
  }
  .col3 {
    position: relative;
    margin: 30px 0 0;
    padding: 0;
    float: none;
    background: transparent;
    overflow: visible;
    .mod_navigation{
      border-left:1px solid white;
      width:100%;
    }
    .hlist {
      overflow: visible;
      margin: 0;
      width: 100%;
      display: block;
      ul {
        overflow: visible;
        margin: 0;
        width: 100%;
        li {
          position: relative;
          float: none;
          line-height: 1em;
          display: block;
          border-bottom: 1px solid $hzdrBlueUltraLight;
          a {
            color: $hzdrBlue;
            font-size: 1.1152em;
            font-weight: 400;
            border: 0 none;
            display: block;
            padding: 10px;
            &.active {
              color: $hzdrOrange;
            }
          }
          ul {
            display: none;
            float: none;
            background: $hzdrBlueUltraLight;
            margin: 10px;
            width: auto;
          }
          &.dropdown {
            > a.dropdown-toggle {
              &:before {
                position: relative;
                width: 0;
                height: 0;
                margin: 2px 5px 0 5px;
                display: block;
                border: 5px solid transparent;
                border-color: transparent transparent transparent $hzdrOrange;
                border-right-width: 0;
                content: " ";
                float: left;
              }
              .mobile-fwd {
                z-index: 1000;
                height: 1em;
                color: rgba(0, 89, 156, 1);
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                line-height: 1em;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAMAAAAGyf7hAAAABlBMVEUAPm5mm8Qiad3yAAAAAXRSTlMAQObYZgAAABtJREFUeNrtyDEBAAAMAiDsX3oBjOA4QZTfyTsb2wAapNOIqQAAAABJRU5ErkJggg==) no-repeat right center;
                padding: 0 20px 0 0;
                display: none;
                strong {
                  border: none;
                  padding: initial;
                  color: inherit;
                }
              }
            }
            &.open > a.dropdown-toggle {
              .mobile-fwd {
                display: block;
              }
              &:before {
                -webkit-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                transform: rotate(90deg);
              }
            }
          }
          &.open ul {
            display: block;
          }
          &.dropdown.open > a.dropdown-toggle {
            &:after {
              border-color: transparent transparent $hzdrOrange;
              content: "";
              float: right;
              margin: 0;
            }
          }
          ul li {
            border-bottom-color: white;
            a {
              font-size: .9em;
            }
          }
        }
      }
    }
    .mod_search {
      form {
        clear: both;
        padding: 30px 15px;
      }
      .type-text {
        padding: 7px 12px;
        width: 180px;
        background: $hzdrBlueUltraLight;
        border: 0 none;
        font-weight: 700;
        color: $hzdrBlueDark;
        &::placeholder {
          color: $hzdrBlue;
        }
        &:focus {
          color: $hzdrBlueDark;
        }
      }
      .btn {
        position: relative;
        width: 26px;
        height: 25px;
        padding: 0;
        margin: 0 10px 0 -30px;
        border: 0 none;
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNi45IiBoZWlnaHQ9IjE2LjkiPjxwYXRoIGZpbGw9IiMwMDU4OUMiIGQ9Ik0xNi43MTcgMTYuMDE2TDEzLjIwMSAxMi41YTcuNDk5IDcuNDk5IDAgMCAwIDEuODc2LTQuOTYxQzE1LjA3NyAzLjM4MiAxMS42OTUgMCA3LjUzOSAwUzAgMy4zODIgMCA3LjUzOHMzLjM4MiA3LjUzOSA3LjUzOSA3LjUzOWMxLjkgMCAzLjYzNC0uNzEyIDQuOTYxLTEuODc2bDMuNTE2IDMuNTE2YS40OTQuNDk0IDAgMCAwIC43MDIgMCAuNDk2LjQ5NiAwIDAgMC0uMDAxLS43MDF6TS45OTIgNy41MzhjMC0zLjYxIDIuOTM3LTYuNTQ3IDYuNTQ3LTYuNTQ3czYuNTQ3IDIuOTM3IDYuNTQ3IDYuNTQ3LTIuOTM3IDYuNTQ3LTYuNTQ3IDYuNTQ3Uy45OTIgMTEuMTQ4Ljk5MiA3LjUzOHoiLz48L3N2Zz4=) no-repeat center center;
        background-size: 17px 17px;
        text-indent: 200%;
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
        outline: none;
        cursor: pointer;
        &:focus, &:hover {
          transform: scale(1.2);
        }
      }
      a.staff-search {
        position: relative;
        font-size: 12px;
        font-weight: bold;
        text-decoration: none;
        padding-right: 10px;
        &:after {
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -3px;
          border: 3px solid transparent;
          border-left: 5px solid $hzdrOrangeDark;
          border-right-width: 0;
          content: " ";
        }
        &:hover {
          color: $hzdrOrange;
        }
      }
    }
  }
  @media screen and (min-width: $tabletViewportWidth) {
    position: relative;
    height: auto;
    box-shadow: none;
    overflow: visible;
    &.open {
      height: auto;
      overflow: visible;
    }
    .col2 {
      position: absolute;
      bottom: 20px;
      right: 25px;
      width: auto;
      background: transparent;
      margin: 0;
      padding: 0;
      z-index: 999;
      flex-direction: row;
      .hlist {
          line-height: 30px;
        ul {
          li {
            border-left: 1px solid $hzdrBlueUltraLight;
            a {
            font-size: 12px;
            color: $hzdrBlue;
            }
            &:first-child {
              border-left: 1px solid $hzdrBlueUltraLight;
            }
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
      .social-media {
        ul {
          display: inline-block;
          li {
            a {
              height: 30px;
              width: 30px;
              &:before {
                color: $hzdrBlue;
                font-size: 36px;
                line-height: 36px;
                text-align: center;
                text-indent: -3px;
              }
            }
          }
        }
      }
    }
    .col3 {
      position: absolute;
      top: -172px;
      left: 215px;
      right: 0;
      overflow: visible;
      padding: 0;
      margin: 0;
      .hlist {
        overflow: visible;
        margin: 0 25px 0 0;
        float: none;
        width: auto;
        height: 60px;
        display: block;
        ul {
          overflow: visible;
          margin: 0;
          width: 100%;
          float: none;
          display: flex;
          flex-wrap: nowrap;
          align-content: stretch;
          li {
            position: relative;
            padding: 0;
            flex: 1 0 auto;
            border-bottom: 0 none;
            a {
              position: relative;
              border-bottom: 4px solid $hzdrBlue;
              padding: 30px 5px 11px;
              font-size: 12px;
              text-align: center;
              &:hover {
                border-bottom-color: #999;
              }
            }
            &:first-child > a:before {
              position: absolute;
              top: 0;
              left: 0;
              width: 1px;
              height: 90%;
              background: $hzdrBlueUltraLight;
              content: " ";
              display: block;
              border: 0 none;
              margin: 0;
              transform: none !important;
            }
            &.open a {
              border-bottom-color: #999;
            }
            &.dropdown {
              > a.dropdown-toggle:before {
                display: none;
                content: none;
              }
              &.open > a.dropdown-toggle .mobile-fwd {
                display: none;
                .touchevents-enabled & {
                  position: absolute;
                  top: 73px;
                  left: 1px;
                  right: auto;
                  max-width: none;
                  text-align: left;
                  color: $hzdrBlue;
                  display: block;
                  background-size: 10px 12.5px;
                  background-color: #d8e5ef;
                  width: 170px;
                  padding: 10px 20px 10px 10px;
                  z-index: 1005;
                }
              }
              &:first-child > a:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                height: 90%;
                background: $hzdrBlueUltraLight;
                content: " ";
                display: block;
                border: 0 none;
                margin: 0;
                transform: none !important;
              }
            }
            a:after, &.dropdown > a:after {
              position: absolute;
              top: 0;
              right: 0;
              width: 1px;
              height: 90%;
              background: $hzdrBlueUltraLight;
              content: " ";
              border: 0 none;
              margin: 0;
            }
            a.active {
              border-bottom-color: $hzdrOrange;
            }
            ul {
              position: absolute;
              top: 120%;
              left: 0;
              border: 1px solid #d9e6f0;
              width: 200px;
              z-index: 1000;
              background: white;
              display: none;
              padding: 0;
              margin: 0;
              .touchevents-enabled & {
                padding-top: 30px;
              }
              li {
                float: none;
                border: 0 none;
                a {
                  border: 0 none;
                  font-size: 14px;
                  line-height: 1.4;
                  font-weight: 400;
                  margin: 4px 0;
                  padding: 7px 10px !important;
                  display: block;
                  hyphens: none;
                  text-align: left;
                  &:after {
                    display: none;
                  }
                  &:hover {
                    background: #555;
                    color: white;
                  }
                }
              }
            }
            &.dropdown:nth-last-child(-n+2) {
              ul {
                left: auto;
                right: 0;
              }
              &.open > a.dropdown-toggle .mobile-fwd {
                left: auto;
                right: 1px;
              }
            }
          }
          &:after {
            display: none;
            content: none;
          }
        }
      }
      .mod_search form {
        padding: 20px 0 0 0;
        display: inline-block;
      }
    }
  }
  @media screen and (min-width: $tabletLandscapeViewportWidth) {
    .col2 {
      right: 55px;
      .hlist ul li a {
        font-size: 14px;
      }
    }
    .col3 {
      .hlist {
        margin-right: 55px;
        ul li a {
          font-size: 1.1152em;
          padding: 30px 10px 11px 11px;
        }
      }
      .mod_search form {
        padding-top: 60px;
      }
    }
  }
  @media screen and (min-width: $desktopViewportWidth) {
    .col3 .hlist ul li {
      &.dropdown:nth-last-child(-n+2) {
        ul {
          left: 0;
          right: auto;
        }
        &.open > a.dropdown-toggle .mobile-fwd {
          left: 1px;
          right: auto;
        }
      }
    }
  }
}

/*-------------------------------------------------------------------------*/

/* Navigation toggle for mobile navigation */

.navigation-toggle {
  position: relative;
  float: right;
  padding: 7px 10px 10px 10px;
  margin-top: 45px;
  margin-right: 25px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid #eee;
  border-radius: 0;
  &:focus {
    outline: 0 none;
  }
  .icon-bar {
    position: relative;
    height: 3px;
    left: 0;
    width: 30px;
    background: $hzdrBlue;
    display: block;
    margin-top: 4px;
    -webkit-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  &.active .icon-bar {
    &:nth-of-type(2) {
      top: 7px;
      left: 8px;
      width: 15px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:nth-child(3) {
      background-color: transparent;
    }
    &:nth-of-type(4) {
      top: -7px;
      left: 8px;
      width: 15px;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
  @media screen and (min-width: $tabletViewportWidth) {
    display: none;
  }
}

/*-------------------------------------------------------------------------*/

/* Setup for vertical and horizontal lists */

ul.vlist {
  margin:0;
  padding:0;
  list-style-type:none;
  li {
    margin:0;
    padding:0;
    line-height:18px;
    ul {
      list-style-type:none;
      margin:0 0 3px 0;
      padding:0;
    }
  }
  a, strong, span {
    display:block;
    color:white;
    font-size:12px;
    font-weight:normal;
    padding:5px 5px 5px 0;
    text-decoration:none;
    border-bottom:1px #ddd solid;
  }
}

.hlist {
  position:relative;
  overflow:hidden;
  display:inline;
  line-height:0;
  padding:0;
  ul {
    overflow:hidden;
    margin:0;
    padding:0;
    display:inline;
    list-style-type:none;
    float: left;
    li {
      display:inline;
      float:left;
      margin:0;
      padding: 0 5px 0 0;
      a, span, strong {
        color:white;
        font-size:12px;
        margin:0;
        padding:0 5px 0 0;
        text-decoration:none;
        width:auto;
        border-right:1px white solid;
      }
      &:last-child {
        a, span, strong {
          border-right:0 none;
          padding:0;
        }
      }
    }
  }
}

/*-------------------------------------------------------------------------*/

/* The breadcrumb menu in the middle section */

#breadcrumb {
  background:transparent;
  overflow:visible;
  height:auto;
  .mod_navigation {
    height:auto;
    border-bottom:1px dotted #ccc;
    padding:8px 15px 8px 40px;
    width:auto;
    display:block;
    float:none;
    overflow:visible;
    ul {
      list-style:none;
      margin:0;
      display:block;
      float:none;
      li {
        position:relative;
        height:20px;
        background:#f2f2f3 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAUCAIAAAA2kktGAAAAl0lEQVR4AYWSBRLEIAxFe//bYhGs9tc3TJiiffFsMaZa234PIj6v/98FpZTnnCd0rufZgLCVQlDWWrchfKoVHLPYEL4xBozmXJbQ+Q/Keu82hE9EwImIDeHrvYODURN6Z6T3YUP4mPlpVJcQEb8icMwhF47jzOKkoLXmJHMMVHpRFkClkFNgVXVaBTH/QUbMBvRuX2a7fQ8IULKUndLb8QAAAABJRU5ErkJggg==) no-repeat left top;
        padding:0 4px 0 16px;
        margin:1px 0;
        line-height:18px;
        &:before {
          display: none;
          content: none;
        }
        &:after {
          position:absolute;
          top:0;
          right:-9px;
          width:0;
          height:0;
          border:10px solid transparent;
          border-color:transparent transparent transparent #f2f2f3;
          border-right-width:0;
          content:"";
          display:block;
        }
        &:first-child {
          background:#f2f2f3 url(../images/breadcrumbHomeIcon.png) no-repeat 7px center;
          background-size:12px 12px;
          padding-left:24px;
          @media only screen and (min--moz-device-pixel-ratio: 2),only screen and (-o-min-device-pixel-ratio: 21),only screen and (-webkit-min-device-pixel-ratio: 2),only screen and (min-device-pixel-ratio: 2) {
            background-image:url(../images/breadcrumbHomeIcon@2x.png);
          }
        }
        &:last-child {
          background:white url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAUCAIAAAA2kktGAAAAWUlEQVR4AaWSBRKAAAgE/f9v7e5WYg2G6aXuCKIoLsuyd2OC4jjpug6gMbMsZ0gMFdCeTdMwlCQpQGKogMRQgFZFAJKKMMTjcHGQ4L+YbAsbzK/CTydXkTEA8HK/9A2wiw4AAAAASUVORK5CYII=) no-repeat left top;
          padding-left:12px;
          a {
            color:#999;
            font-weight:700;
          }
        }
        &:last-child::after {
          display:none;
        }
        a {
          background:transparent;
          border:0 none;
          color:$hzdrBlue;
          font-size: 12px;
          padding:0;
          &:hover {
            color: $hzdrOrange;
          }
        }
      }
    }
  }
  @media screen and (min-width: $tabletViewportWidth) {
    margin-right:-10px;
  }
  .mod_navigation {
    padding:8px;
  }
}

/*--------------------------------------------------------------------------*/

/* Left navigation */

#col1 {
  position:absolute;
  top:0;
  left:-230px;
  width:225px;
  padding:0 5px 5px 0;
  background:#333;
  background:rgba(51,51,51,0.8);
  z-index:1000;
  transition:all 300ms ease-in-out;
  &.slide-in {
    left:0;
  }
  .mod_navigation {
    width:225px;
    height:330px;
    overflow:scroll;
    .active-section {
      background:white;
      padding:11px 0;
      a {
        color:$hzdrOrange;
        text-decoration:none;
        font-size:16px;
        padding:0 20px 0 8%;
        display:block;
      }
    }
    > p {
      background:transparent!important;
      padding:10px 5px!important;
      margin:0!important;
      border-bottom:1px dotted white;
      color:white;
    }
  }
  .vlist {
    box-shadow:0 1px 3px rgba(0,0,0,0.3);
    background: white;
    a {
      position:relative;
      color:$hzdrBlueDark;
      background: #d9d9d9;
      font-size:16px;
      border:0 none;
      padding:8px 25px 8px 8%;
      &:hover {
        background: #555;
        color: white;
      }
      &.trail {
        font-weight: bold;
      }
      &.active {
        background:$hzdrOrangeDark;
        color:white;
        font-weight: bold;
        &:after {
          position:absolute;
          left:0;
          top:50%;
          margin-top:-5px;
          width:0;
          height:0;
          border:5px solid transparent;
          border-color:transparent transparent transparent white;
          content:"";
        }
      }
    }
    .subtrail a {
      background: #ababab;
      font-weight: bold;
      color: $hzdrBlueDark;
      &:hover {
        background: #555;
        color: white;
      }
    }
    ul {
      a {
        background:white;
        padding-left:11%;
        border-bottom: 1px solid #eee;
      }
    }
  }
  .ce_image {
    text-align:center;
    padding: 0 0 10px 0;
    margin: 10px auto;
    border-bottom:1px solid white;
  }
  .ce_text {
    padding:0 15px 10px 15px;
    border-bottom:1px solid white;
    color:$hzdrBlueDark;
    h1 {
      margin: 5px 0 15px 0;
      color:$hzdrBlueDark;
    }
    h2 {
      margin:0;
      color:$hzdrBlueDark;
    }
  }
  .logout {
    margin:10px 0 0 0;
    padding:0;
    background:none;
    color:#ED8C01;
    border:none;
  }
  p a {
    color:white;
  }
  hr {
    display:none;
  }
  #col1_content > p {
    background:transparent!important;
    padding:10px 5px!important;
    margin:0!important;
    border-bottom:1px dotted white;
    color:white;
  }
  @media screen and (min-width: $tabletViewportWidth) {
    position:relative;
    left:0;
    width:220px;
    z-index:auto;
    background:transparent;
    .mod_navigation {
      width:230px;
      height:auto;
      overflow:visible;
      > p {
        border-bottom:1px dotted #999;
        color:#555;
      }
    }
    p a {
      color:#555!important;
    }
    .vlist a.active {
      &:before {
        border-top-color: white;
      }
      &:after {
    	  left:auto;
    	  right:12px;
    	  margin-top:-10px;
    	  border-color:transparent white transparent transparent;
    	  border-width:10px;
    	}
    }
    #col1_content > p {
      border-bottom:1px dotted #999;
      color:#555;
    }
  }
}

/*--------------------------------------------------------------------------*/

/* Right navigation (Sidebar) */

#col2 {
  display: none;
  #col2_content p {
    padding: 5px
  }
  a, a:visited {
    color:$hzdrBlueDark;
    text-decoration:none;
  }
  a:hover {
    text-decoration:underline;
  }
  a:visited {
    color:#4D87C7;
  }
  .mod_eventlist {
    margin:0 0 5px 0;
    div {
      margin:0;
    }
    .ce_image {
      margin:0;
      padding:0;
    }
    .event {
      width:170px;
      padding: 2px 5px 0 5px;
    }
  }
  .mod_navigation {
    margin: 0;
  }
  .mod_download {
    .vlist li {
      background-color:#F2F2F3;
      a {
        color:$hzdrBlueDark;
        background:url("../images/nav_for.png") no-repeat scroll 90% 50%;
      }
    }
  }
  .ce_image {
    text-align:center; margin: 10px auto;
  }
  @media screen and (min-width: $tabletViewportWidth) {
    position:relative;
    z-index:200;
    background:#f2f2f3;
    padding:10px 0;
    display:block;
  }
}

/*--------------------------------------------------------------------------*/

/* Navigation footer */

#footer {
  .mod_navigation {
    float:right;
    img {
      margin-top:1px;
    }
  }
}

/*-------------------------------------------------------------------------*/

/* Contrast settings */
/* [TJ: Is this thing in use anymore? Could those lines be removed?] */

#contrast {
  float:right;
  overflow:hidden;
  a {
    border-right:none;
    display:block;
  }
  img {
    margin:6.5px 0;
  }
}

/*-------------------------------------------------------------------------*/
/* page action buttons */

.page-actions {
  position: relative;
  list-style: none;
  margin: -45px 0 0 0;
  padding: 0 25px 0 0;
  display: flex;
  justify-content: flex-end;
  a {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    @include text-hide();
    display: inline-block;
    background: rgba($hzdrOrangeDark,.8);
    margin: 5px;
    text-align: center;
    overflow: hidden;
    img {
      position: relative;
      margin-top: 8px;
    }
  }
  @media screen and (min-width: $tabletViewportWidth) {
    margin: -35px 45px -10px 0;
  }
}
/*-------------------------------------------------------------------------*/



/* EOF */
