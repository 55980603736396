/**
 *
 * Intranet Homepage
 * ==========================================================================
 * All special styles for the intranet areas homepage
 *
 *
 */

@import "variables";
@import "mixins";

/*-------------------------------------------------------------------------*/

/* Basic intranet homepage settings */

.intranet {
  &.home {
    #col4 section {
      border: 0 none;
      font-size: 16px;
      line-height: 24px;
      &:first-child {
        margin-top: 20px;
      }
      .section-head {
        border-bottom: 2px solid $hzdrBlue;
        padding-bottom: 10px;
        h3 {
          border-bottom: 0 none;
        }
        .addition-links {
          display: none;
        }
      }
      ul {
        margin: 8px 0 0 0;
        li {
          border-bottom: 1px solid $hzdrBlueUltraLight;
        }
      }
      a {
        color: $hzdrBlue;
        &.button {
          border: 1px solid $hzdrOrangeDark;
          color: $hzdrOrangeDark;
          margin: 20px 0;
          padding: 10px;
          display: inline-block;
          text-decoration: none;
        }
        &.intranet-home-more {
          background: transparent;
          padding: 0;
          margin: 10px 0;
          font-weight: bold;
          text-decoration: none;
          &:after {
            position: relative;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-left: 6px solid $hzdrOrange;
            border-right-width: 0;
            content: " ";
            display: inline-block;
            margin: 0 0 0 5px;
            top: -1px;
          }
        }
        &:hover {
          color: $hzdrOrange;
        }
      }
    }
    #col4_content > .ce_text {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      .intranet-home-col-1 {
        order: 2;
      }
      .intranet-home-col-2 {
        order: 1;
      }
      .intranet-home-col-3 {
        order: 3;
        min-width: 100%;
      }
    }
    @media screen and (min-width: $tabletViewportWidth) {
      #col4 section {
        float: none;
        height: auto;
        width: auto;
        margin: 20px 0;
        flex: 0 0 100%;
        &:after {
          background: transparent;
        }
      }
    }
    @media screen and (min-width: $tabletLandscapeViewportWidth) {
      #col4 {
        section {
          margin: 40px 0 0 0;
          &:first-child {
            margin-top: 4px;
          }
          .section-head {
            padding-bottom: 8px;
          }
        }
        #col4_content > .ce_text {
          margin: 0 -10px;
          .intranet-home-col-1 {
            flex: 1 0 auto;
            width: 25%;
            order: 1;
          }
          .intranet-home-col-2 {
            flex: 2 0 auto;
            width: 50%;
            order: 2;
          }
          .intranet-home-col-3 {
            flex: 1 0 auto;
            width: 25%;
            min-width: inherit;
          }
        }
      }
    }
    @media screen and (min-width: $desktopViewportWidth) {
      #col4 section {
        .section-head {
          text-align: left;
          h3 {
            display: inline-block;
            vertical-align: middle;
          }
          ul.addition-links {
            position: relative;
            display: inline-block;
            margin: 0;
            vertical-align: middle;
            li {
              display: inline-block;
              border: 0 none;
              border-left: 1px solid #ddd;
              padding-left: 5px;
              margin: 0;
              a {
                position: relative;
                padding: 0 10px 0 0 !important;
                background: transparent;
                font-size: 12px;
                font-weight: bold;
                &:after {
                  position: absolute;
                  top: 50%;
                  right: 1px;
                  margin-top: -2px;
                  width: 0;
                  height: 0;
                  border: 3px solid transparent;
                  border-top-color: $hzdrOrangeDark;
                  border-bottom-width: 0;
                  content: " ";
                }
                &.add-news:after {
                  display: none;
                  content: none;
                }
                &:hover {
                  color: $hzdrOrange;
                }
              }
              &:first-child {
                padding-left: 0;
                border-left: 0 none;
              }
            }
          }
        }
      }
    }
  }
}

/*-------------------------------------------------------------------------*/

/* Intranet homepage events area */

.intranet.home #col4 section.intranet-home-events {
  h3:before {
    content: "a";
  }
  ul {
    li {
      margin: 0;
      a {
        color: $hzdrBlue;
        .date {
          color: #555;
        }
        .title {
          font-weight: bold;
          font-size: 16px;
        }
      }
      &:hover {
        background-color: $hzdrLightgrey;
      }
    }
  }
}

/*-------------------------------------------------------------------------*/

/* Intranet homepage new coleagues area */

.intranet.home #col4 section.intranet-home-colleagues {
  h3:before {
    content: "k";
    left: -2px;
  }
  ul {
    li {
      padding: 10px 0;
      a {
        background: transparent;
      }
      figure {
        display: flex;
        align-items: center;
        max-width: none;
        > a {
          margin-right: 15px;
          flex: 0 0 30%;
        }
        figcaption {
          background: transparent;
          color: black;
          padding: 0;
          h4 {
            margin: 0;
            a {
              padding: 0;
              font-weight: bold;
              text-decoration: none;
              font-size: 16px;
              color: $hzdrBlue;
            }
          }
          p.desc {
            font-size: 12px;
            margin: 10px 0;
          }
          a.intranet-home-more {
            margin: 0;
          }
        }
      }
    }
  }
  @media screen and (min-width: $phoneLandscapeViewportWidth) {
    ul {
      display: flex;
      margin: 5px -10px;
      li {
        padding: 10px;
      }
    }
  }
  @media screen and (min-width: $tabletLandscapeViewportWidth) {
    ul {
      flex-wrap: wrap;
      margin: 0;
      li {
        padding: 30px 0;
        figure {
          align-items: flex-start;
          figcaption {
            h4 {
            }
            p.desc {
              font-size: 16px;
              margin: 0;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: $desktopViewportWidth) {
    ul li:first-child {
      padding-top: 7px;
    }
  }
}

/*-------------------------------------------------------------------------*/

/* Intranet homepage news area */

.intranet.home #col4 section.intranet-home-news {
  h3:before {
    content: "j";
  }
  ul.news-list {
    position: relative;
    width: 100%;
    li {
      border-bottom: 0 none;
      margin: 0;
      .news-item {
        padding: 10px 0;
        border-bottom: 1px solid $hzdrBlueUltraLight;
        .news-metadata {
          margin-bottom: 10px;
          font-size: 12px;
          color: #555;
          .date {
            font-weight: bold;
            display: inline;
            font-size: 12px;
            border-right: 1px solid #555;
            padding-right: 5px;
            margin-right: 2px;
            color: #555;
          }
          .category {
            background: lighten($hzdrBlueUltraLight,3%);
            padding: 2px 5px;
            border-radius: 4px;
            text-decoration: none;
          }
        }
        h4 {
          a {
            font-size: 16px;
            text-decoration: none;
            font-weight: bold;
          }
        }
        p {
          margin: 10px 0;
          word-wrap: break-word;
          overflow-wrap: break-word;
        }
      }
      &.pinned {
        .news-item {
          padding: 10px;
          background: $hzdrBlueUltraLight;
          border-bottom: 2px solid $hzdrOrange;
          .news-metadata {
            .category {
              background: none;
            }
          }
          img {
            width: 100%;
            height: auto;
            margin: 10px 0;
          }
          @media screen and (min-width: $phoneLandscapeViewportWidth) {
            @include clearfix();
            img {
              float: left;
              width: 20%;
              margin: 0 10px 0 0;
            }
          }
          @media screen and (min-width: $desktopViewportWidth) {
            padding: 18px 15px 21px 15px;
            img {
              width: 100%;
              height: 200px;
              margin: -5px 0 25px 0;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
  ul.warnings-list {
    position: relative;
    width: 100%;
    margin: 0;
    li {
      padding: 10px 0;
      margin: 0;
      border-bottom: 1px solid $hzdrBlueUltraLight;
      a {
        position: relative;
        padding: 10px 30px 10px 34px;
        text-decoration: none;
        display: block;
        &:before {
          position: absolute;
          top: 50%;
          left: -4px;
          width: 32px;
          height: 32px;
          margin: -16px 0 0 0;
          font-family: hzdr-font;
          font-size: 32px;
          line-height: 1;
          display: block;
          font-weight: normal;
          content: "\75";
          color: #c00;
        }
        .entry-count {
          position: relative;
          background: $hzdrBlue;
          border-radius: 20px;
          max-height: 20px;
          line-height: 20px;
          min-width: 20px;
          text-align: center;
          color: white;
          font-weight: bold;
          font-size: 12px;
          padding: 2px;
          display: inline-block;
        }
      }
    }
  }
  @media screen and (min-width: $tabletLandscapeViewportWidth) {
    ul.news-list {
      margin: 4px 0 0 0;
      li {
        .news-item {
          padding: 30px 0;
          box-sizing: border-box;
        }
        &.pinned {
          .news-item {
            padding: 30px 15px;
          }
        }
      }
    }
  }
  @media screen and (min-width: $desktopViewportWidth) {
    text-align: center;
    h3 {
      text-align: left;
    }
    ul.warnings-list {
      text-align: left;
      li {
        padding: 0;
        a {
          padding: 19px 70px 20px 34px;
          &:before {
            margin-top: -18px;
          }
          .entry-count {
            position: absolute;
            top: 50%;
            margin-top: -11px;
            right: 30px;
          }
        }
        &:first-child {
          a {
            padding-top: 16px;
          }
        }
      }
    }
    ul.news-list {
      margin-top: 7px;
      box-sizing: border-box;
      column-count: 2;
      column-gap: 20px;
      li {
        text-align: left;
        box-sizing: border-box;
        break-inside: avoid-column;
        page-break-inside: avoid;
        .news-item {
          .news-metadata {
            margin: 0 0 -1px 0;
          }
          h4 {
            margin-bottom: 10px;
          }
          p {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

/*-------------------------------------------------------------------------*/

/* Intranet homepage shortcuts area */

.intranet.home #col4 section.intranet-home-shortcuts,
.intranet.home #col4 section.intranet-home-favorites {
  h4 {
    position: relative;
    margin: 10px 0;
    padding-left: 30px;
    font-size: 18px;
    color: #555;
    &:before {
      position: absolute;
      top: -5px;
      left: -5px;
      width: 30px;
      height: 30px;
      font-family: hzdr-font;
      font-size: 30px;
      line-height: 1;
      color: #f0781e;
      transform: scale(0.8);
    }
  }
  ul {
    min-width: 100%;
  }
}
.intranet.home #col4 section.intranet-home-shortcuts h3:before {
    content: "m";
    top: -2px;
}
.intranet.home #col4 section.intranet-home-favorites h3:before {
    content: "p";
    top: -2px;
}

/*-------------------------------------------------------------------------*/
