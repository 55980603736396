/**
 *
 * HZDR Specific stylings
 * ==========================================================================
 * Some very old stylings for content elements and tables
 *
 *
 *
 */

@import "variables";
@import "mixins";

/*-------------------------------------------------------------------------*/

#tbWebEdit {
  width: 180px;
  background-color: #f2f2f3;
  font-size: 80%;
  td {
    padding-left: 3px;
    border-top-width: 1px;
    border-top-style: dashed;
  }
}

#textobj {
  counter-reset: FigCnt;
  table {
    margin-bottom: 20px !important;
    td {
      vertical-align: top;
    }
    th {
      vertical-align: top;
      text-align: left;
      font-weight: bold;
    }
  }
}

.menuauswahlselektiert {
  font-weight: bold;
  color: #003e6e!important;
}

table {
  &.menu {
    border-collapse: collapse;
    margin-bottom: 15px;
    td {
      background-color: #f2f2f3;
      padding: 3px 2px 3px 2px;
      empty-cells:show;
    }
    th {
      background-color: #cedfec;
      padding: 3px 2px 3px 2px;
      empty-cells:show;
    }
    td, th {
      border: solid #e5eef5 2px !important;
    }
    a {
      text-decoration: none !important;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  &.menu_ohne_Rahmen {
    td, th {
      border: none;
      background-color: white;
    }
  }
  &.tbStandard, &.standard {
    border-collapse: collapse;
    border: 1px solid $hzdrBlueDark;
    empty-cells: show;
    caption {
      caption-side: bottom;
      padding-top: 6px;
      text-align: left;
      color: $hzdrBlue;
    }
    td {
      border: 1px solid $hzdrBlue;
      border-width: 0 1px 1px 0;
      padding: 6px;
      vertical-align: top;
      empty-cells: show;
    }
    th, thead td {
      border: 1px solid $hzdrBlueDark;
      border-width: 0 1px 1px 0;
      color: white;
      font-weight: bold;
      background-color: $hzdrBlue;
      padding: 6px;
      vertical-align: top;
      empty-cells: show;
    }
    th a {
      &:link, &:visited {
        color: white;
      }
    }
  }
  &.tbSimple {
    border-collapse: collapse;
    border: none;
    empty-cells: show;
    margin-top: 2px;
    margin-bottom: 2px;
    td {
      border: solid #cccccc 1px;
      padding: 2px;
      vertical-align: top;
      empty-cells: show;
    }
  }
  &.tbElbeProtokoll {
    border-collapse: collapse;
    border: none;
    empty-cells: show;
    margin-top: 2px;
    margin-bottom: 2px;
    td {
      border: solid #666666 1px;
      padding: 2px;
      vertical-align: top;
      empty-cells: show;
    }
  }
  &.tbSiteMap {
    border-collapse: collapse;
    border: none;
    margin-top: 2px;
    margin-bottom: 2px;
    width: 800px;
    td {
      border: none;
      padding: 0;
      font-size: 10pt;
      vertical-align: middle;
      empty-cells: show;
      img {
        border: 0;
        margin: 0;
      }
    }
  }
  &.tbNoBorder {
    border-collapse: collapse;
    border: none;
    empty-cells: show;
    td {
      border: none;
      padding: 2px 4px 2px 0px;
      vertical-align: top;
      empty-cells: show;
    }
  }
  &.tbSearch {
    width: 100%;
    border-collapse: collapse;
    border: solid #c3d2de 1px;
    border-bottom: none;
    empty-cells: show;
    margin-top: 2px;
    margin-bottom: 2px;
    td {
      background-color: #f2f2f3;
      border-bottom: solid #c3d2de 1px;
      padding: 2px 2px 2px 2px;
      vertical-align: top;
      empty-cells: show;
    }
    th {
      color: white;
      background-color: $hzdrBlue;
      padding: 4px 2px 4px 2px;
      vertical-align: top;
      empty-cells: show;
    }
    td, th {
      p {
        margin: 0;
      }
    }
  }
  &.tbInline {
    border-collapse: collapse;
    border: none;
    empty-cells: show;
    margin: 0px;
    td {
      border: none;
      padding: 0;
      margin: 0;
      vertical-align: top;
      empty-cells: show;
    }
  }
  &.tbInlineBorder {
    border-top: hidden;
    border-left: hidden;
    border-right: hidden;
    border-bottom: hidden;
    border-collapse: collapse;
    empty-cells: show;
    margin: 0;
    td {
      border: 1px solid #ccc;
      padding: 2px;
      margin: 0;
      vertical-align: top;
      empty-cells: show;
    }
  }
  &.tbSticky {
    th {
      position: sticky;
      top: 0;
    }
  }
}

.bild_links, .bild_rechts, .bild_zeile, .bild_zeile_mitte {
  width: 140px;
  border-collapse: collapse;
}

.bild_links {
  clear: both;
  float: left;
  margin: 6px 10px 2px 0px !important;
}

.bild_rechts {
  float: right;
  margin: 6px 0 2px 10px !important;
}

.bild_zeile {
  clear: both;
  margin: 2px 5px 2px 0;
}

.bild_zeile_mitte {
  clear: both;
  margin-left: auto !important;
  margin-right: auto !important;
}

.bild_links img, .bild_rechts img, .bild_zeile img, .bild_zeile_mitte img {
  padding: 0px !important;
  margin: 0px !important;
  border: 0px !important;
}

.bildunterschrift {
  padding: 0px !important;
  margin: 1px 1px 3px 1px;
}

.BildTabUnterschrift, .BildTabFoto, .BildTabDownload {
  padding: 2px !important;
  background-color: #d9d9d9;
  font-size: 90% !important;
}

.BildTabImg {
  padding: 0px;
  background-color: #999999;
}

.img_links {
  clear: both;
  float: left;
  margin: 5px 10px 5px 0 !important;
}

.img_rechts {
  float: right;
  margin: 5px 0 5px 10px !important;
}

.img_zeile {
  clear: both;
  margin: 2px 0 2px 0 !important;
}

.img_zeile_mitte {
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 2px;
  margin-bottom: 2px;
}

#visicard {
  width: 100%;
  td {
    padding: 3px 0px 3px 0px;
    vertical-align: top;
  }
  img {
    margin-right: 5px !important;
  }
}

.VisiCol1 {
  font-weight: bold;
  padding-right: 3px !important;
}

.VisiCol2 {
  width: 100%;
}

.ParaEdit {
  color: green;
  font-weight: bold;
  a {
    color: green;
    font-weight: bold;
  }
}

#col3 ul.tree,
#col4 ul.tree {
  margin: 0;
  margin: 0;
  list-style: none;
  ul {
    margin: 0;
    margin: 0;
    list-style: none;
    margin-left: 1em;
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 8px;
      border-left: 1px solid $hzdrOrange;
    }
    li {
      margin: 0;
      padding: 0 1.5em;
      line-height: 2em;
      color: $hzdrOrange;
      position: relative;
      background: transparent !important;
      &:before {
        content: "";
        display: block;
        width: 10px;
        height: 0;
        border: 1px solid $hzdrOrange;
        border-width: 1px 0 0 0;
        margin-top: -1px;
        position: absolute;
        top: 1em;
        left: 8px;
      }
      &:last-child:before {
        background: white;
        height: auto;
        top: 1em;
        bottom: 0;
      }
      ul {
        margin-left: .5em;
      }
    }
  }
}

.more_float {
  display: inline;
  padding: 0 12px 0 0;
  margin: 0 0 2px 0;
  background: url("../images/nav_more.png") no-repeat scroll right 50%;
  text-decoration: none;
}

.more_float_u {
  display: inline;
  padding: 0 12px 0 0;
  margin: 0 0 2px 0;
  background: url("../images/nav_more.png") no-repeat scroll right 50%;
}

.imgMobile {
  width: auto;
  max-width: 100%;
  height: auto;
}

.JobCols {
  display: flex;
  flex-direction: row;
  
  .JobColMain {
    width: 100%;
    flex: 1;
    
    & h2 {
      color: $hzdrBlueDark;
    }
    
    & p {
      word-break: break-word;
    }
  }
  
  .JobColLeft {
    width: 180px;
    font-size: 90%;
    padding-right: 20px;
  }
}


.tbJobColMain {
  width: 100%;
  padding: 0px;
  font-size: 11pt;
}

.tbJobColLeft {
  width: 140px;
  max-width: 140px;
  font-size: 9pt;
  padding: 0 20px 0 0;
}

.JobColLeft img, .tbJobColLeft img {
  width: 100%;
}

.JobColLeft p, .tbJobColLeft p {
  margin-bottom: 16px;
}

.tbJobColMain {
  h2 {
    color: $hzdrBlueDark;
  }
  h1:nth-of-type(1) {
    margin: 0 0 10px 0;
  }
  h2 {
    margin: 12px 0 8px 0;
  }
}

.JobQrCodeR {
  display: none;
}

@media only screen and (max-width: 900px) {
  .JobCols {
    flex-direction: column-reverse;
  }
  
  .JobColLeft {
    width: 100% !important;
    font-size: 100%;
    p {
      margin-bottom: 10px;
    }
    img {
      width: 100px;
      float: right;
    }
  }
  
  .ImgBerufUndFamilie {
    width: 100px;
    float: right;
  }
  
  .ImgHzdr {
    display: none;
  }
  
  .JobQrCodeR {
    display: inline;
  }
  
  .JobQrCode {
    display: none;
  }
}

.NlToParaBr {
  p, ul {
    margin-bottom: 0.5em;
  }
}

/* EOF */
