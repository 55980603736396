/**
 *
 * Intranet Indicator
 * ==========================================================================
 * Setup for intranet indicator 
 * (which is also shown on websites if user is logged in)
 *
 */

@import "variables";
@import "mixins";

/*-------------------------------------------------------------------------*/

.intranet-indicator {
  display: none;
  .logged-in & {
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    border-top: 2px solid $hzdrOrange;
    display: block;
    .intranet-indicator-wrapper {
      position: relative;
      margin: 0 25px;
    }
    .location-select {
      position: absolute;
      bottom: 15px;
      left: 0;
      font-weight: bold;
      label {
        color: $hzdrOrangeDark;
        font-size: 12px;
        font-weight: bold;
      }
      i.dropdown-icon {
        position: absolute;
        top: 50%;
        right: 7px;
        margin-top: -2px;
        width: 0;
        height: 0;
        border: 4px solid transparent;
        border-bottom-width: 0;
        border-top-color: #999;
      }
      select {
        padding: 4px 18px 4px 6px;
        background: white;
        border-radius: 0;
        border: 1px solid #ddd;
        border-bottom-width: 2px;
        color: $hzdrBlueDark;
        padding-right: 15px;
        appearance: none;
        font-size: 12px;
      }
    }
    @media screen and (min-width: $phoneLandscapeViewportWidth) {
      .location-select {
        bottom: -50px;
        right: 30px;
        left: auto;
        z-index: 30;
      }
    }
    @media screen and (min-width: $tabletViewportWidth) {
      z-index: 99;
      .intranet-indicator-wrapper {
        margin: 0;
      }
      .location-select {
        bottom: -43px;
        right: 50px;
      }
    }
    @media screen and (min-width: $tabletLandscapeViewportWidth) {
      .intranet-indicator-wrapper {
        &:before {
          right: 76px;
        }
      }
      .location-select {
        right: 65px;
      }
    }
  }
}

/*-------------------------------------------------------------------------*/

// EOF
