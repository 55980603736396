/**
 *
 * Icons
 * ==========================================================================
 * Some icons delivered by an iconfont
 *
 *
 */

/*-------------------------------------------------------------------------*/

@font-face {
  font-family:"hzdr-font";
  src:url(../fonts/hzdr-font.eot);
  src:url(../fonts/hzdr-font.eot?#iefix) format("embedded-opentype"),url(../fonts/hzdr-font.woff) format("woff"),url(../fonts/hzdr-font.ttf) format("truetype"),url(../fonts/hzdr-font.svg#hzdr-font) format("svg");
  font-weight:400;
  font-style:normal;
}

[data-icon]:before {
  font-family:"hzdr-font"!important;
  content:attr(data-icon);
  font-style:normal!important;
  font-weight:400!important;
  font-variant:normal!important;
  text-transform:none!important;
  speak:none;
  line-height:1;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}

.home section h3:before,[class^="icon-"]:before,[class*=" icon-"]:before {
  font-family:"hzdr-font"!important;
  font-style:normal!important;
  font-weight:400!important;
  font-variant:normal!important;
  text-transform:none!important;
  speak:none;
  line-height:1;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}

.icon-hzdr-dates:before {
  content: "\61";
}
.icon-hzdr-facebook:before {
  content: "\62";
}
.icon-hzdr-facilities:before {
  content: "\63";
}
.icon-hzdr-future:before {
  content: "\64";
}
.icon-hzdr-helmholtz:before {
  content: "\65";
}
.icon-hzdr-larry:before {
  content: "\66";
}
.icon-hzdr-lcoations:before {
  content: "\67";
}
.icon-hzdr-media:before {
  content: "\68";
}
.icon-hzdr-network:before {
  content: "\69";
}
.icon-hzdr-news:before {
  content: "\6a";
}
.icon-hzdr-partners:before {
  content: "\6b";
}
.icon-hzdr-publications:before {
  content: "\6c";
}
.icon-hzdr-youtube:before {
  content: "\6e";
}
.icon-hzdr-linkedin:before, .icon-hzdr-mastodon:before {
  font-size: 26px !important;
  font-family: "Font Awesome 5 Brands" !important;
}
.icon-hzdr-linkedin:before {
  content: "\f08c";
}
.icon-hzdr-mastodon:before {
  content: "\f4f6";
}
.icon-hzdr-cancer:before {
  content: "\6f";
}
.icon-hzdr-star-blank:before {
  content: "\70";
}
.icon-hzdr-star-filled:before {
  content: "\71";
}
.icon-hzdr-forestfire:before {
  content: "\72";
}
.icon-hzdr-login:before {
  content: "\73";
}
.icon-hzdr-service:before {
  content: "\6d";
}
.icon-hzdr-tools:before {
  content: "\74";
}
.icon-hzdr-helmholtz:before {
  content: "\65";
}
.icon-hzdr-warning-lowlevel:before {
  content: "\75";
}
.icon-hzdr-warning-it:before {
  content: "\76";
}
.icon-hzdr-warning-important:before {
  content: "\77";
}

/*-------------------------------------------------------------------------*/

/* EOF */
