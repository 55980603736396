/**
 *
 * Basic layout settings
 * ==========================================================================
 * These styles represent the basic column and
 * content designs and holds also some recurring style
 * classes
 *
 *
 */

@import "variables";
@import "mixins";

/*-------------------------------------------------------------------------*/

/* Basic structural settings */

* {
  margin: 0;
}
body {
  background:#ccc url(../images/bgnoise.png) repeat 0 0 fixed;
  margin: 0;
}
#header, #nav, #main, #footer {
  clear:both;
}
.page_margins {
  position: relative;
  background:transparent;
  min-width:320px;
  &.index {
    min-width:1024px;
  }
}
#main {
  position: relative;
  margin: 0;
  width:100%;
  margin:0 auto;
  @media screen and (min-width: $desktopViewportWidth) {
    width:1170px;
  }
}
#footer {
  &.col3 {
    padding: 0 180px;
    color:white;
    font-size:10px;
  }
  .footer_content {
    padding: 0;
    color:white;
    background: $hzdrBlue;
    line-height:20px;
    width:100%;
    font-size:12px;
  }
  .col4 {
    padding:0 0 0 180px;
  }
}
#nav ul {
  margin: 0 0 0 10px;
}
#col1, #header .logo1, #nav .col1 {
  float: left;
  width: 180px;
  margin:0;
}
#col2, #header .logo2, #nav .col2 {
  float: right;
  width: 200px;
  margin:0;
}
#col3,
#header .logo3 {
  margin: 0 180px 0 180px;
}
#nav .col3 {
  margin: 0 180px 0 0;
}
#col5 {
  width:auto;
  margin:0;
  position:relative;
  background-color: white;
  padding:0.5em 0.5em 0 0.5em;
}
.row,#navigation,#main {
  width:100%;
  margin:0 auto;
  @media screen and (min-width: $desktopViewportWidth) {
    width:1170px;
    .fullsize & {
      width:auto;
    }
  }
  @media screen and (min-width: $ultraWideViewportWidth) {
    width:1475px;
  }
}


/*-------------------------------------------------------------------------*/

/* Formatting the columns */
.col {
  width:100%;
  margin:20px 0;
  @media screen and (min-width: $tabletViewportWidth) {
    position:relative;
    width:50%;
    float:left;
  }
  @media (min-width: 992px) {
    position:relative;
    width:33.333333333333%;
  }
}
#col2, #col3, #col4 {
  box-shadow:0 1px 3px rgba(0,0,0,0.3);
}
#col3, #col4 {
  position: relative;
  background: white;
  margin:7px;
}
#col3 {
  @media screen and (min-width: $tabletViewportWidth) {
    margin:0 60px 0 215px;
    padding:0 150px 60px 0;
  }
  @media screen and (min-width: $tabletLandscapeViewportWidth) {
    margin-left: 215px;
  }
}
#col4 {
  width:auto;
  margin:0;
  margin-bottom: 7px;
  position:relative;
  @media screen and (min-width: $tabletViewportWidth) {
    margin:0 0 0 217px;
  }
  @media screen and (min-width: $tabletLandscapeViewportWidth) {
    margin-right: 55px;
  }
}
#col3_content, #col4_content {
  padding: 15px 15px;
  overflow: hidden;
  @media screen and (min-width: $tabletLandscapeViewportWidth) {
    min-height: 600px;
  }
}
#col3_content img,#col4_content img {
  margin: 0;
  padding: 0;
}
#col3_content .ce_text img.float_left, #col4_content .ce_text img.float_left {
  margin: 0 15px 12px 0;
}
#col3_content .ce_text .float_right, #col4_content .ce_text .float_right {
  margin: 0 0 12px 15px;
}
#col4_content {
  padding:20px 20px 40px;
}
.fullsize #col5 {
  display:table;
  min-width:100%;
  padding:0;
}
#eyecatcher {
  display:none;

  @media screen and (min-width: $tabletViewportWidth) {
    display: block;
    margin-bottom: 20px;
    background-size: auto 100%;
    background-position: left top;
    max-width: none !important;
    img {
      display: none;
    }
  }

  @media screen and (max-width: $ultraWideViewportWidth) {
    height: 120px !important;
  }

  @media screen and (max-width: $desktopViewportWidth) {
    height: 90px !important;
  }
}

/*-------------------------------------------------------------------------*/

/* The overlay bars at the end of the main column */

.column-footer-bars {
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
  height:20px;
  div {
    position:absolute;
    bottom:0;
    background:$hzdrBlue;
    height:10px;
    opacity:.1;
    &.full {
      left:0;
      width:100%;
    }
    &.half {
      left:15px;
      right:0;
    }
    &.third {
      right:0;
      width:50%;
      height:20px;
    }
  }
  @media screen and (min-width: $tabletViewportWidth) {
    div {
      &.half {
        left:25px;
      }
    }
  }
  @media screen and (min-width: $desktopViewportWidth) {
    div {
      &.half {
        left:34%;
        right:0;
      }
      &.third {
        width:33%;
        padding:0 0 0 10px;
      }
    }
  }
}

/*-------------------------------------------------------------------------*/

/* Sidebar toggle for mobile views */

.sidebar-toggle {
  position:absolute;
  top:0;
  right:-40px;
  height:40px;
  width:40px;
  z-index:1000;
  display:block;
  border:0 none;
  background:rgba(51,51,51,0.8);
  .sidebar-icon {
    width:35px;
    height:40px;
    display:block;
    background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYCAQAAABQQhX9AAAAN0lEQVR4AWP4DwEMhCAtFDKAlA0hhWCAWyEaoNxEuBSUTTOFYIBbIRqgiokMw8bX6Fpomx6JhADvcivxMplvkwAAAABJRU5ErkJggg==) no-repeat center center;
    background-size:20px 24px;
    @media only screen and (min--moz-device-pixel-ratio: 2),only screen and (-o-min-device-pixel-ratio: 21),only screen and (-webkit-min-device-pixel-ratio: 2),only screen and (min-device-pixel-ratio: 2) {
      background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAwAQAAAABLAAU2AAAAAnRSTlMAAHaTzTgAAAAzSURBVHgBY/jDAAQESANSSf4DcNL+P5REEzdgZsBF/kGQ/xEkQhyhkhxzGBigJHb3owEAzKYkSeZdpG4AAAAASUVORK5CYII=);
    }
  }
  &:focus {
    outline:none;
  }
  @media screen and (min-width: $tabletViewportWidth) {
    display: none;
  }
}
.mobile-sidebar {
  @media screen and (min-width: $tabletViewportWidth) {
    display:none;
  }
}


/*-------------------------------------------------------------------------*/

/* Recurring used classes and elements */

.clearfix, .home-media .media-oneslide ul, .row, #navigation .col3 .hlist ul, #footer .membership, .home-service ul {
  &:after {
    position: relative;
    width: 100%;
    height: 1px;
    display: table;
    clear: both;
    content: " ";
  }
}

.f-screenreader {
  position:absolute;
  width:1px;
  height:1px;
  padding:0;
  margin:-1px;
  overflow:hidden;
  clip:rect(0,0,0,0);
  border:0;
}

/*-------------------------------------------------------------------------*/

/* EOF */
