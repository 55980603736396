/**
 *
 * Header
 * ==========================================================================
 * All header (the big white bar above every page) related styles
 *
 *
 */

/*-------------------------------------------------------------------------*/

#header {
  position: relative;
  height: 150px;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
  .logo1 a {
    margin: 25px 0 0 25px;
    width: 160px;
    height: 105px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
    background: url(../images/headerlogo.png) no-repeat 0 0;
    background-size: 100%;
    @media only screen and (min--moz-device-pixel-ratio: 2),only screen and (-o-min-device-pixel-ratio: 21),only screen and (-webkit-min-device-pixel-ratio: 2),only screen and (min-device-pixel-ratio: 2) {
      background-image:url(../images/headerlogo@2x.png);
    }
  }
  .menu-label {
    position: absolute;
    top: 102px;
    right: 25px;
    width: 50px;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    color: $hzdrBlueDark;
    display: block;
    opacity: 0.3;
    line-height: 1.2;
    text-transform: uppercase;
    &:before {
      position: absolute;
      top: -7px;
      right: 50%;
      margin-right: -4px;
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-bottom-color: $hzdrBlueDark;
      border-top-width: 0;
      content: " ";
    }
  }
  @media screen and (min-width: $tabletViewportWidth) {
    .menu-label {
      display: none;
    }
    .logo1 a {
      margin-left: 20px;
    }
  }
}

/*-------------------------------------------------------------------------*/

/* EOF */
