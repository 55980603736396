/**
 *
 * Mixins
 * ==========================================================================
 * Own mixins for clearfixes, text removal,
 * gradients and some other minor stuff
 *
 *
 *
 */

/*-------------------------------------------------------------------------*/

@mixin clearfix() {
  &:before, &:after {
    content: " ";
    display: table;
    background: transparent;
  }
  &:after {
    clear: both;
  }
}

@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin text-hide() {
  font: "0/0" a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

@mixin horgrad($start-color: "#555", $end-color: "#333", $start-percent: "0%", $end-percent: "100%") {
  background-image: -webkit-linear-gradient(left, $start-color $start-percent, $end-color $end-percent);
  background-image: -o-linear-gradient(left, $start-color $start-percent, $end-color $end-percent);
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x;

}

@mixin vertgrad($start-color: "#555", $end-color: "#333", $start-percent: "0%", $end-percent: "100%") {
  background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);
  background-image: -o-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x;
}

/*-------------------------------------------------------------------------*/

// EOF
