/**
 *
 * Intranet
 * ==========================================================================
 * These styles are all related to the intranet home and subpages
 *
 *
 */

@import "variables";
@import "mixins";


/*-------------------------------------------------------------------------*/

/* Basic setup for intranet elements and overwrite for navigation */

.intranet {
  a.locked {
    position: relative;
    &:after {
      position: relative;
      width: 12px;
      height: 12px;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiI+PHBhdGggZD0iTTEwLjcgNC4xVjIuN0MxMC43IDEuMiA5LjUgMCA4IDBTNS4zIDEuMiA1LjMgMi43djEuNUM0LjYgNC40IDQgNS4xIDQgNnYyLjdjMCAxLjEuOSAyIDIgMmg0YzEuMSAwIDItLjkgMi0yVjZjMC0uOS0uNi0xLjYtMS4zLTEuOXpNOCA4Yy0uNSAwLS45LS40LS45LS45cy40LS45LjktLjkuOS40LjkuOS0uNC45LS45Ljl6bTIuMi00SDUuOFYyLjdDNS44IDEuNSA2LjguNSA4IC41czIuMiAxIDIuMiAyLjJWNHoiIG9wYWNpdHk9Ii4yNSIvPjwvc3ZnPg==) no-repeat center center;
      background-size: auto 12px;
      content: " ";
      display: inline-block;
      vertical-align: middle;
    }
  }
  #header .logo1 a {
    margin-top: 15px;
  }
  #main {
    overflow: hidden;
    padding-bottom: 5px;
  }
  #breadcrumb {
    display: block;
  }
  #navigation .col3 .hlist > ul > li:first-child a {
    padding-right: 40px;
    .intranet-home-icon {
      position: absolute;
      top: 0;
      right: 5px;
      width: 34px;
      height: 34px;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PGcgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDA1YWEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCI+PHBhdGggZD0iTTQuNzIgMTMuOTM1djcuMDM2aDUuMDQxdi00Ljg3NWg0LjY0MXY0Ljg3NWg0LjkwNHYtNy4wMzYiLz48cGF0aCBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNMy4wMjYgMTIuOTM1bDguOTg3LTguOTg3TDIxIDEyLjkzNSIvPjxwYXRoIGQ9Ik0xNS41MjMgMy4xMzJoMy42MDF2My42MDEiLz48L2c+PC9zdmc+) no-repeat center center;
      background-size: 24px;
      content: " ";
    }
    &.active .intranet-home-icon {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PGcgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRjA3ODFFIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCI+PHBhdGggZD0iTTQuNzIgMTMuOTM1djcuMDM2aDUuMDQxdi00Ljg3NWg0LjY0MXY0Ljg3NWg0LjkwNHYtNy4wMzYiLz48cGF0aCBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNMy4wMjYgMTIuOTM1bDguOTg3LTguOTg3TDIxIDEyLjkzNSIvPjxwYXRoIGQ9Ik0xNS41MjMgMy4xMzJoMy42MDF2My42MDEiLz48L2c+PC9zdmc+);
    }
  }
  @media screen and (min-width: $phoneLandscapeViewportWidth) {
    #header .logo1 a {
      margin-top: 25px;
    }
    #breadcrumb .mod_navigation {
      padding-right: 220px;
    }
  }
  @media screen and (min-width: $tabletViewportWidth) {
    #navigation .col3 .hlist ul li:first-child a {
      padding-right: 10px;
      .intranet-home-icon {
        position: relative;
        top: -4px;
        right: auto;
        margin: -12px 0;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

@import "intranet/intranet_toolbar";
@import "intranet/intranet_indicator";
@import "intranet/intranet_home";


/* EOF */
