/**
 *
 * Forms
 * ==========================================================================
 * Basic styles and overwrites based on YAML
 * for forms and its elements
 *
 *
 */

/*-------------------------------------------------------------------------*/

/* Basic forms settings */

.yform {
  padding:10px;
  overflow:hidden;
  fieldset {
    border:1px #ddd solid;
    background:#fafafa;
    margin:0 0 1em 0;
    padding:0.5em;
    display: block;
  }
  legend {
    font-size:125%; 
    font-weight:normal; 
    color:black;
    background:transparent;
    border:0;
    padding:0 0.5em;
  }
  label {
    color:#666;
    display: block;
    cursor: pointer;
  }
  .message {
    display:block;
    margin-bottom:0.5em;
    color:#666;
  }
  sup {
    color:#800;
    font-weight:bold;
  }
  input, textarea, select {
    border:1px solid #ddd;
  }
  div {
    &.type-text input, textarea, select {
      &:focus, &:hover, &:active {
        border:1px #a66 solid;
        background:white;
      }
    }
  }
  .type-button {
    input {
      border-top:1px #ddd solid;
      border-left:1px #ddd solid;
      border-right:1px #444 solid;
      border-bottom:1px #444 solid;
      color:black;
      background:#454545 url(images/button_gray.png) top left repeat-x;
      padding:5px 1em;
      &[type="reset"] {
        color:#300;
        background:#661717 url(images/button_red.png) top left repeat-x;
        &:focus, &:hover, &:active {
          background:#800; color:white;
        }
      }
      &[type="submit"] {
        color:#330;
        background:#5e5607 url(images/button_yellow.png) top left repeat-x; 
        &:focus, &:hover, &:active {
          background:#880; color:white;
        }
      }
      &:focus, &:hover, &:active {
        border-top:1px #444 solid;
        border-left:1px #444 solid;
        border-right:1px #ddd solid;
        border-bottom:1px #ddd solid;
        color:white;
        background:#555; 
      }
    }
  }
  fieldset:after, div.type-text:after, div.type-select:after, div.type-check:after, div.type-button:after {
    clear:both;
    content:" ";
    display:table;
    height:0;
    width: 100%;
    visibility:hidden;
    position: relative;
  }
  div {
    &.type-text, &.type-select, &.type-check, &.type-button {
      position:relative; 
      display:block;
      margin:0.5em 0;
      padding:3px 0.5em;  
    }
    &.type-button {
      padding:3px 0em;
      input {
        cursor:pointer;
        width:auto;
      }
    }
    &.type-text {
      input, textarea {
        position:relative;
        display:block;
        padding:0.3em 0.3em;
        width:58.5%;
      }
    }
    &.type-select select {
      position:relative;
      display:block;
      padding:0.3em 2px 0.3em 1px;
      width:60%;
      cursor:pointer;
      optgroup {
        font-style:normal;
        font-weight:bold;
      }
    }
    &.type-check {
      input {
        cursor:pointer;
        &:focus, &:hover, &:active {
          border:0 none;
        }
      }
      label {
        display:inline;
      }
    }
    &.error {
      border:1px #a00 dashed;
      background:#faf4f4;
      padding:0.5em;
      label {
        color:black;
        font-weight:bold;
      }
      .message {
        color:#800;
      }
    }
  }
  .full {
    div {
      &.type-text input, &.type-text textarea {
        width:95.5%;
        margin-right: -3px;
      }
      &.type-select select {
        width:97.0%;
        margin-right: -3px;
      }
    }
  }
  .columnar {
    .type-text, .type-select {
      label {
        float:left;
        width:30%;
      }
    }
    div {
      &.type-check input, &.error .message {
        margin-left:30%;
      }
      &.type-text input, &.type-text textarea {
        float:left;
        width:67.8%;
        margin-right: -3px;
      }
      &.type-select select {
        float:left;
        width:69.4%;
        margin-right: -3px;
      }
    }
    fieldset div.type-button, fieldset.columnar div.type-button {
      padding-left:30%;
    }
  }
}

/*-------------------------------------------------------------------------*/

/* EOF */
