/**
 *
 * Homepage
 * ==========================================================================
 * All styles for the HZDR homepage
 *
 *
 */

/*-------------------------------------------------------------------------*/

.home {
  #col1, #breadcrumb, #col4:before {
    display: none;
  }
  #col4_content .ce_text {
    overflow: visible;
  }
  #col4 section ul {
    margin: 4px 0 -1px;
    li {
      &:before {
        display: none;
      }
    }
  }
  section {
    border-bottom: 1px solid $hzdrBlueUltraLight;
    margin: 50px 0;
    .subhead, .date {
      color: $hzdrBlueDark;
      font-weight: 700;
      display: block;
      margin: 1px 0 -1px;
      font-size: .8571423em;
    }
    .subhead {
      font-size: .833333339em;
    }
    .date strong {
      color: #888;
    }
    img {
      max-width: 101%;
    }
    a.home-more {
      display: block;
      padding: 6px 0;
      color: $hzdrBlue;
      text-decoration: none;
      font-size: .8em;
      font-weight: 700;
      &:after {
        position: relative;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 0 4px 6px;
        border-color: transparent transparent transparent $hzdrBlueDark;
        content: "";
        display: inline-block;
        margin-left: 4px;
      }
    }
    &.home-news .news-control a.more-news span:after {
      position: relative;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 0 4px 6px;
      border-color: transparent transparent transparent $hzdrOrange;
      content: "";
      display: inline-block;
      margin-left: 4px;
    }
  }
  #col4 section ul.link-list {
    margin: 0;
    min-width: 100%;
    li {
    	line-height: 24px;
    	margin: 0;
    	&:before {
    	  display: none;
    	}
    }
  }
  section {
    h3 {
      position: relative;
      border-bottom: 1px solid $hzdrBlueUltraLight;
      color: $hzdrBlueDark;
      font-size: 22px;
      font-weight: 400;
      padding: 0 0 .3em 1.6em;
      line-height: 1.2em;
      margin: 0;
      a {
        text-decoration: none;
        margin-left: -4px;
        padding: 2px 4px;
        &:hover {
          background-color: $hzdrLightgrey;
          color: $hzdrBlue !important;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: -4px;
        width: 30px;
        height: 30px;
        font-family: "hzdr-font";
        font-size: 30px;
        content: "e";
        line-height: 1;
        color: $hzdrOrange;
      }
    }
    &.home-blacklivesmatter,
    &.home-userfacilities,
    &.home-publications,
    &.home-locations,
    &.home-network,
    &.home-media
    {
      @media screen and (max-width: $ultraWideViewportWidth) and (min-width: $tabletViewportWidth) {
        height: 350px !important;
      }
    }
    &.home-news h3:before,
    &.home-inmedia h3:before {
      content: 'j';
    }
    &.home-service h3:before {
      content: 'm';
    }
    &.home-dates h3:before {
      left: -6px;
      content: 'a';
    }
    &.home-publications h3:before {
      left: -2px;
      content: 'l';
    }
    &.home-userfacilities h3:before {
      content: 'c';
    }
    &.home-locations h3:before {
      left: -5px;
      content: 'g';
    }
    &.home-network h3:before {
      content: 'i';
    }
    &.home-media h3:before {
      left: -1px;
      content: 'h';
    }
    &.home-futureprojects h3:before {
      content: 'd';
    }
    &.home-intpartners h3:before {
      left: -2px;
      content: 'k';
    }
    &.home-cancerresearch h3:before {
      left: -3px;
      content: 'o';
    }
    &.home-sectors {
      position: relative;
      margin: -20px -20px 28px;
      height: 200px;
      border: 0 none;
      background: #999;
      padding: 0;
      h3 {
        display: none;
      }
      h4, .sectors-textcloud {
        font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
        font-weight: 300;
      }
      .sectors-slider {
        position: relative;
      }
      .item {
        background: transparent center center no-repeat;
        background-size: cover;
        height: 200px;
        &.energy {
          background-image: url(../images/homeheader-energy-small.jpg);
        }
        &.health {
          background-image: url(../images/homeheader-health-small.jpg);
        }
        &.matter {
          background-image: url(../images/homeheader-matter-small.jpg);
        }
      }
      .sector-indicator {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        transition: all 500ms ease-in-out;
      }
      .sectors-textcloud {
        display: none;
      }
    }
  }
}

.home-sectors {
  .sector-bars {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 5px;
    div {
      position: relative;
      top: 0;
      float: left;
      height: 5px;
      width: 15%;
      transition: all 1s ease-in;
    }
  }
  &.energy .sector-bars div.energy, &.health .sector-bars div.health, &.matter .sector-bars div.matter {
    width: 70%;
    top: -5px;
    bottom: auto;
  }
  &.energy .sector-indicator, .sector-bars div.energy {
    background-color: $topicEnergy;
  }
  &.health .sector-indicator, .sector-bars div.health {
    background-color: $topicHealth;
  }
  &.matter .sector-indicator, .sector-bars div.matter {
    background-color: $topicMatter;
  }
}

.home .carousel-control {
  position: absolute;
  top: 50%;
  margin-top: -30px;
  width: 30px;
  height: 30px;
  background: no-repeat center center;
  background-size: 13px 25px;
  &.left {
    left: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAZCAMAAADdclTDAAAABlBMVEUAPm7///9gMRkKAAAAAXRSTlMAQObYZgAAABtJREFUeNrVxyEBAAAAwjDoX/qWCGduWXXnrwASbwAaolgWmwAAAABJRU5ErkJggg==);
  }
  &.right {
    right: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAZCAMAAADdclTDAAAABlBMVEUAPm7///9gMRkKAAAAAXRSTlMAQObYZgAAABpJREFUeNrVxyEBAAAAgzDev/QtFZgbwyKt5BJ7ABqxVtWjAAAAAElFTkSuQmCC);
  }
}

.home-sectors:hover .carousel-control, .home-locations:hover .carousel-control, .home-media:hover .carousel-control {
  display: block;
}

.home-sectors .carousel-control:hover, .home-locations .carousel-control:hover, .home-media .carousel-control:hover {
  background-color: rgba(91, 91, 91, 0.7);
}

.home-sectors .sector-infos {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0 30px;
  h4 {
    color: white;
    font-size: 1.2em;
    text-shadow: (-3px) 0 6px rgba(0, 0, 0, 0.4), 3px 0 6px rgba(0, 0, 0, 0.4), 0 -3px 6px rgba(0, 0, 0, 0.4), 0 3px 6px rgba(0, 0, 0, 0.4);
    margin-bottom: 15px;
    .first-line {
      display: none;
    }
  }
  .more {
    font-size: .834em;
    border: 1px solid white;
    padding: 6px;
    display: inline-block;
    color: white;
    background: transparent;
  }
}

.home-news h4 a {
  font-weight: 400;
  text-decoration: none;
  line-height: 20px;
  font-size: 18px;
}

.home #col4 section ul li, .home-news .news-item {
  border-bottom: 1px dotted $hzdrBlueUltraLight;
  padding: 10px 0;
}

.home #col4_content .home-news .news-item {
  overflow: hidden;
}

.home-news {
  .news-item {
    &:last-child {
      border-bottom: 0 none;
    }
    h4, .subhead {
      margin: -2px 0 2px;
      color: #888;
    }
    img {
      max-width: 120px;
      height: auto;
      margin: 0 10px 0 0 !important;
    }
  }
  .news-control, .teasertext {
    display: none;
  }
}

.home {
  #col4 section ul li {
    background: transparent;
    padding: 0;
    > a {
      text-decoration: none;
      padding: 12px 30px 12px 0;
      display: block;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAMAAAAGyf7hAAAABlBMVEUAPm5mm8Qiad3yAAAAAXRSTlMAQObYZgAAABtJREFUeNrtyDEBAAAMAiDsX3oBjOA4QZTfyTsb2wAapNOIqQAAAABJRU5ErkJggg==) no-repeat right center;
      background-size: 20px 25px;
      @media only screen and (min--moz-device-pixel-ratio: 2),only screen and (-o-min-device-pixel-ratio: 21),only screen and (-webkit-min-device-pixel-ratio: 2),only screen and (min-device-pixel-ratio: 2) {
        background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAyCAMAAAAZbWmiAAAABlBMVEUAPm5mm8Qiad3yAAAAAXRSTlMAQObYZgAAACRJREFUeNrtyjENAAAIA7Dh3zQWSLgg7d1UhkzTvD89z/vy9hrRmwAyq9MlMAAAAABJRU5ErkJggg==);
      }
    }
  }
  section.home-service {
    background: $hzdrBlue;
    margin: 0 -20px;
    padding: 0 25px;
    h3 {
      color: white;
      font-size: 1.2em;
      padding: 10px 25px;
      margin: 0 -25px 15px;
      height: auto;
      &:before {
        color: white;
        left: auto;
        right: 15px;
        top: 7px;
      }
    }
  }
  #col4 section.home-service ul {
    margin-bottom: 15px;
    margin-left: -4px;
    li {
      border: 0 none !important;
      float: left;
      margin-bottom: 5px;
      a {
        color: white;
        padding: 0 4px;
        background: transparent !important;
        border-right: 1px solid white;
        line-height: 1.2em;
      }
      &:last-child a {
        border-right: 0 none;
      }
    }
  }
}

.home-dates {
  a img.float_left {
    max-height: 60px;
    width: auto;
    margin: 0 15px 0 0 !important;
  }
  ul li a:after {
    position: relative;
    display: table;
    clear: both;
    content: "";
  }
}

.home-locations figure {
  position: relative;
  overflow: hidden;
}

.home #col4 {
  .carousel-inner {
    background: #444 url(../images/locationbg.jpg) no-repeat center center;
    background-size: cover;
  }
  #locations-slider.carousel .item, #media-slider.carousel .item {
    text-align: center;
    line-height: 10px;
  }
  .carousel .item img {
    border: 1px solid white;
    border-width: 0 1px;
  }
  section {
    &.home-locations ol.carousel-indicators, &.home-media ul.media-subnav {
      list-style-type: none;
      text-align: center;
      margin: 10px 0 0;
    }
    &.home-locations ol.carousel-indicators li {
      display: inline-block;
      color: #333;
      font-size: .833333339em;
      margin: 0;
      cursor: pointer;
    }
  }
}

.home-media .media-subnav li {
  display: inline-block;
  color: #333;
  font-size: .833333339em;
  margin: 0;
  cursor: pointer;
}

.home #col4 section.home-locations ol.carousel-indicators li, .home-media .media-subnav li:before {
  display: none;
}

.home #col4 section.home-locations ol.carousel-indicators li:after, .home-media .media-subnav li:after {
  content: "\00a0\2022";
}

.home #col4 section.home-locations ol.carousel-indicators li:last-child::after, .home-media .media-subnav li:last-child::after {
  display: none;
}

.home #col4 section {
  &.home-locations {
    ol.carousel-indicators li.active {
      color: $hzdrBlueDark;
      font-weight: 700;
    }
    .carousel {
      position: relative;
      margin: 8px 0 0 -1px;
    }
  }
  &.home-media .carousel {
    position: relative;
    margin: 8px 0 0 -1px;
  }
}

.home-media #media-slider {
  .carousel-inner .item {
    text-align: center;
    overflow: hidden;
  }
  .carousel-control {
    margin-top: -15px;
  }
  .carousel-inner .item .item-bg {
    position: absolute;
    top: -30px;
    right: -30px;
    bottom: -30px;
    left: -30px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    filter: blur(5px) grayscale(90%);
  }
}

.home-locations figcaption, .home #col4 section.home-media .item .media-link {
  position: absolute;
  right: 20px;
  left: 20px;
  bottom: 12px;
  overflow: hidden;
}

.home-locations figcaption a {
  border: 1px solid white;
  background: rgba(0, 63, 111, 0.8);
  text-align: center;
  color: white;
  padding: 6px 10px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  font-size: .833339em;
}

.home #col4 section.home-media {
  .item .media-link a {
    border: 1px solid white;
    background: rgba(0, 63, 111, 0.8);
    text-align: center;
    color: white;
    padding: 6px 10px;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
    box-sizing: border-box;
    font-size: .833339em;
  }
  .media-subnav li {
    border: 0 none;
    line-height: 1.5em;
    padding-top: 2px;
    margin-bottom: 10px;
    a {
      padding: 0;
      background: transparent;
      display: inline;
      border: 0 none;
    }
  }
}

.home-cancerresearch p {
  margin: 20px 0;
}

.home #col4_content section.home-cancerresearch img {
  max-width: 30%;
  margin: 10px 0 20px;
}

.home-cancerresearch img, .home-intpartners img {
  opacity: .4;
}


@media screen and (min-width: $tabletViewportWidth) {
  .home {
    #col4 {
      margin: 0 25px 0 18px;
    }
    section a.home-more {
      padding: 6px;
    }
    #col4 section ul.link-list li:hover {
      background-color: $hzdrLightgrey;
      a {
        color: $hzdrBlue;
      }
    }
    section {
      position: relative;
      float: left;
      width: 50%;
      height: 300px;
      padding: 0 10px;
      box-sizing: border-box;
      margin: 30px 0;
      border-bottom: 0 none;
      a.home-more:hover {
        background-color: $hzdrLightgrey;
      }
      &:after {
        position: absolute;
        bottom: 0;
        left: 10px;
        right: 0;
        height: 1px;
        background: $hzdrBlueUltraLight;
        content: "";
      }
      overflow: hidden;
      &.home-sectors {
        float: none;
        width: auto;
        overflow: visible;
      }
      h3 {
        height: 1.588em;
        line-height: 1.38888889em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 0 0 1.6em;
      }
    }
  }
  .home-sectors {
    .sector-indicator {
      top: -3px;
      left: -3px;
      width: 30px;
      height: 30px;
      background: white;
    }
    .sector-infos h4 {
      font-size: 1.4em;
    }
    .sector-bars {
      bottom: -10px;
      height: 10px;
      div {
        height: 10px;
      }
    }
    &.energy .sector-bars div.energy, &.health .sector-bars div.health, &.matter .sector-bars div.matter {
      top: -10px;
    }
  }
  .home section.home-sectors {
    .sectors-textcloud {
      position: absolute;
      bottom: 25px;
      right: 20px;
      display: block;
      text-transform: lowercase;
      list-style-type: none !important;
    }
    .sector-infos {
      padding: 0 45% 30px 60px;
      width: 50%;
    }
  }
  .home-sectors .sector-infos .more {
    position: absolute;
    &:hover {
      background: white;
      color: $hzdrBlue;
    }
  }
  .home {
    #col4 section.home-sectors .sectors-textcloud li {
      font-size: 1.4em;
      background: transparent;
      color: white;
      opacity: .4;
      padding: 0;
      text-align: right;
      line-height: .9em;
      cursor: pointer;
      transition: all 500ms ease-in-out;
      text-shadow: -3px 0 6px rgba(0,0,0,0.4), 3px 0 6px rgba(0,0,0,0.4), 0 -3px 6px rgba(0,0,0,0.4), 0 3px 6px rgba(0,0,0,0.4);
      &.energy {
        padding-right: 20px;
      }
      &.health {
        padding-right: 40px;
      }
      &.matter {
        padding-right: 0;
      }
      &.active {
        font-size: 2.4em;
        opacity: 1;
        margin-bottom: .1em;
      }
    }
    section {
      &.home-news {
        .news-item {
          position: absolute;
          top: 50px;
          right: 0;
          left: 10px;
          height: 133px;
          padding-left: 220px;
          visibility: hidden;
          opacity: 0;
          transform: scale(0.5);
          transition: all 200ms ease-in-out;
          border: 0 none;
          &.active {
            visibility: visible;
            opacity: 1;
            transform: scale(1);
          }
        }
        .news-item h4 {
          margin-bottom: 6px;
          font-size: 1.1em;
        }
        .news-item {
          .teasertext {
            display: block;
            font-size: 14px;
            p {
              display: inline;
              line-height: 20px;
            }
            a.more {
              display: inline;
              font-weight: 700;
              line-height: 20px;
            }
          }
          img {
            position: absolute;
            top: 10px;
            left: 0;
            width: 200px;
            height: 133px;
            max-width: none;
          }
        }
        .news-control {
          position: absolute;
          bottom: 1px;
          left: 10px;
          right: 0;
          display: block;
          a {
            position: relative;
            border: 1px dotted $hzdrBlueUltraLight;
            border-width: 1px 1px 0 0;
            display: block;
            height: 50px;
            width: 32%;
            float: left;
            white-space: nowrap;
            font-size: 0;
            padding: 3px 6px;
            box-sizing: border-box;
            text-align: center;
            line-height: 1.3;
            &:before {
              content: '';
              display: inline-block;
              height: 100%;
              vertical-align: middle;
              margin-right: -.25em;
            }
            span {
              font-size: 12px;
              display: inline-block;
              vertical-align: middle;
              white-space: normal;
            }
            &.active {
              color: $hzdrOrange;
              border-top: 1px dotted transparent;
              &:after {
                position: absolute;
                left: 50%;
                top: -5px;
                margin-left: -5px;
                width: 0;
                height: 0;
                border: 5px solid transparent;
                border-color: transparent transparent $hzdrOrange;
                border-top-width: 0;
                content: "";
              }
            }
            &.more-news {
              border-width: 1px 0 0;
              font-weight: 700;
              width: 4%;
              overflow: hidden;
              text-indent: 200%;
              span:after {
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -7px 0 0 -4px;
                text-indent: 0;
                border-width: 7px 0 7px 10px;
              }
              &:hover {
                background: $hzdrLightgrey;
              }
            }
          }
        }
      }
      &.home-service {
        width: 25%;
        height: 266px;
        float: right;
        margin-top: 62px;
        padding: 0 15px;
        &:after {
          display: none;
        }
      }
      &.home-sectors:after {
        display: none;
      }
    }
    #col4 section.home-service {
      h3 {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 700;
        line-height: 1.4;
      }
      ul {
        display: table-cell;
        vertical-align: middle;
        height: 160px;
        li {
          float: none;
          margin: 8px 0;
          font-size: 1em;
          margin: 0 0 5px;
          height: auto;
          &:before {
            display: none;
          }
          a {
            border: 0 none;
            font-size: 14px;
            padding: 1px 0;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    section {
      &.home-news {
        width: 75%;
        overflow: hidden;
        padding-right: 0;
      }
      &.home-locations {
        #locations-slider {
          position: relative;
          height: 220px;
          margin-top: 10px;
        }
        .carousel-inner {
          height: 180px;
        }
      }
    }
  }
  .home-intpartners a:hover img, .home-cancerresearch a:hover img {
    opacity: 1;
  }
  .home-intpartners {
    &.row-count-2 li {
      height: 111px;
      img {
        height: 65px;
        width: auto;
        margin-top: 16px !important;
      }
    }
    &.row-count-3 li {
      height: 74px;
      img {
        height: 52px;
        width: auto;
      }
    }
    &.row-count-4 li {
      height: 55px;
      img {
        height: 32px;
        width: auto;
      }
    }
  }
  .home #col4_content section.home-cancerresearch p {
    font-size: .83333339em;
    line-height: 1.4;
    margin: 10px 0;
    a.more {
      display: inline;
      margin-left: 3px;
      font-weight: 700;
    }
  }
  .home-media #media-slider {
    position: relative;
    height: 180px;
    margin-top: 10px;
  }
  .home-locations .carousel-inner figure {
    height: 180px;
  }
  .home-media #media-slider .carousel-inner {
    height: 180px;
    .item {
      height: 180px;
    }
  }
  .home-locations figcaption {
    background: transparent;
    a:hover {
      background: white;
      color: $hzdrBlueDark;
    }
  }
  .home {
    #col4 section.home-media .item .media-link a:hover {
      background: white;
      color: $hzdrBlueDark;
    }
    section a.home-more {
      padding: 5px 6px;
    }
    #col1 .mod_navigation > p, #col1_content > p {
      border-bottom: 1px dotted white;
      color: white;
    }
    #col1 p a {
      color: white !important;
    }
    #footer {
      .footer-statistics {
        left: -30px;
      }
      .adress a.audit-link {
        width: 150px;
      }
    }
  }
}

@media screen and (min-width: $tabletLandscapeViewportWidth) {
  .home {
    &.cms-edit #col1 {
      position: fixed;
      left: -235px;
      top: 120px;
      width: 225px;
      display: block;
      background: #333;
      background: rgba(51, 51, 51, 0.8);
      padding: 5px;
      z-index: 1100;
      &.slide-in {
        left: 0;
      }
      .col1_content {
        height: 500px;
        overflow: scroll;
        overflow-y: auto;
      }
      .sidebar-toggle {
        display: block;
        cursor: pointer;
        .sidebar-icon {
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYCAQAAABQQhX9AAAA9klEQVQoz82SIQ7CQBBFJ02KI2gMqpZA0nuQVFUgKnA9SZEcYhNuwAlWtG65AQk0DRZX8xDdlLJbBIKElTMvf3b+fJGfPQISFDUtLTWKhGAMizFoMuYiIszJ0BhiF9vQkDq1kJSGzbtaw8rBCq5ErGh6VQKMp3bgRs6FiBRj/0qC9tQqTihyroRokq6syBysZMaEEzcKETJU16i7TYeYCDMqDtaBumu1o1hJ0VdbB2TP2cdeoB3NlAd3lh7Wj7bLsOPIljvnITZcxtqDpuDIg71jVm9PgCFlAWh2TB3sZbg94ZrFSFSGJ/wUChEvFF/E7Ivg/vV7AouG/mg5mQrbAAAAAElFTkSuQmCC);
          width: 30px;
        }
      }
      .mod_navigation {
        width: 225px;
      }
    }
    #col4 {
      margin: 0 55px 0 18px;
    }
    section {
      &.home-news {
        width: 81%;
      }
      &.home-service {
        width: 19%;
      }
      &.home-sectors .item {
        &.energy {
          background-image: url(../images/homeheader-energy.jpg);
        }
        &.health {
          background-image: url(../images/homeheader-health.jpg);
        }
        &.matter {
          background-image: url(../images/homeheader-matter.jpg);
        }
      }
      &.home-news .news-item h4 {
        margin-bottom: 16px;
      }
    }
    #col4_content section.home-news .news-control {
      display: block;
    }
  }
  .home-cancerresearch p {
    margin: 20px 0;
  }
  .home #footer {
    .footer_content .row {
      margin: 0 55px 0 15px;
    }
    .footer-statistics {
      left: 3px;
    }
  }
}

@media screen and (min-width: $desktopViewportWidth) {
  .home {
    section {
      width: 33%;
      &.home-sectors {
        height: 300px;
        #sectors-slider, .item {
          height: 300px;
        }
        .sector-infos h4 {
          font-size: 1.8em;
        }
        .sectors-textcloud {
          right: 40px;
          font-size: 1.3em;
        }
        .carousel-inner {
          height: 300px;
          &:after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 30%;
            content: " ";
            @include horgrad(rgba(0,0,0,0), rgba(0,0,0,0.25),0,100%);
          }
        }
      }
      &.home-news {
        height: 280px;
        .news-control a {
          width: 28%;
          &.more-news {
            width: 16%;
            text-indent: 0;
            white-space: normal;
            padding: 0 15px;
            span:after {
              left: auto;
              right: 0;
              border-width: 5px 0 5px 7px;
            }
          }
        }
        .news-item h4 {
          margin: 5px 0 10px;
        }
        .news-image-container {
          border: 1px dotted $hzdrBlueUltraLight;
          border-width: 1px 0;
          padding: 15px 0;
        }
      }
      &.home-service {
        height: 248px;
        h3 {
          padding: 13px 25px 6px;
          &:before {
            top: 7px;
          }
        }
      }
    }
    #col4 section.home-service ul {
      height: 150px;
      li {
        margin-bottom: 3px;
      }
    }
  }
}

/*-------------------------------------------------------------------------*/

/* Carousel Settings */

.carousel-inner {
  position:relative;
  width:100%;
  overflow:hidden;
  > .item {
    position:relative;
    display:none;
    transition:.6s ease-in-out left;
  }
  > img,
  > a > img {
    line-height:1;
  }
  @media all and (transform-3d),(-webkit-transform-3d) {
    > .item {
      transition:transform .6s ease-in-out;
      backface-visibility:hidden;
      perspective:1000px;
    }
    > .item.next,
    .item.active.right {
      left:0;
      transform:translate3d(100%,0,0);
    }
    > .item.prev,
    .item.active.left {
      left:0;
      transform:translate3d(-100%,0,0);
    }
    > .item.next.left,
    .item.prev.right,
    .item.active {
      left:0;
      transform:translate3d(0,0,0);
    }
  }
  > .active,
  > .next,
  > .prev {
    display:block;
  }
  > .active {
    left:0;
  }
  > .next,
  > .prev {
    position:absolute;
    top:0;
    width:100%;
  }
  > .next,
  > .active.right {
    left:100%;
  }
  > .prev,
  > .active.left {
    left:-100%;
  }
  > .next.left,
  > .prev.right {
    left:0;
  }
}

/*-------------------------------------------------------------------------*/

/* EOF */
