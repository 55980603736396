/**
 *
 * HZDR Specific stylings
 * ==========================================================================
 * Some very old stylings for content elements and tables
 *
 *
 *
 */
/**
 *
 * Project variables
 * ==========================================================================
 * Value settings for colors, type, breakpoints and
 * base settings for size calculations
 * 
 *
 *
 */
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/* EOF */
/**
 *
 * Mixins
 * ==========================================================================
 * Own mixins for clearfixes, text removal,
 * gradients and some other minor stuff
 *
 *
 *
 */
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
#tbWebEdit {
  width: 180px;
  background-color: #f2f2f3;
  font-size: 80%; }
  #tbWebEdit td {
    padding-left: 3px;
    border-top-width: 1px;
    border-top-style: dashed; }

#textobj {
  counter-reset: FigCnt; }
  #textobj table {
    margin-bottom: 20px !important; }
    #textobj table td {
      vertical-align: top; }
    #textobj table th {
      vertical-align: top;
      text-align: left;
      font-weight: bold; }

.menuauswahlselektiert {
  font-weight: bold;
  color: #003e6e !important; }

table.menu {
  border-collapse: collapse;
  margin-bottom: 15px; }
  table.menu td {
    background-color: #f2f2f3;
    padding: 3px 2px 3px 2px;
    empty-cells: show; }
  table.menu th {
    background-color: #cedfec;
    padding: 3px 2px 3px 2px;
    empty-cells: show; }
  table.menu td, table.menu th {
    border: solid #e5eef5 2px !important; }
  table.menu a {
    text-decoration: none !important; }
    table.menu a:hover {
      text-decoration: underline !important; }

table.menu_ohne_Rahmen td, table.menu_ohne_Rahmen th {
  border: none;
  background-color: white; }

table.tbStandard, table.standard {
  border-collapse: collapse;
  border: 1px solid #003d6d;
  empty-cells: show; }
  table.tbStandard caption, table.standard caption {
    caption-side: bottom;
    padding-top: 6px;
    text-align: left;
    color: #005aa0; }
  table.tbStandard td, table.standard td {
    border: 1px solid #005aa0;
    border-width: 0 1px 1px 0;
    padding: 6px;
    vertical-align: top;
    empty-cells: show; }
  table.tbStandard th, table.tbStandard thead td, table.standard th, table.standard thead td {
    border: 1px solid #003d6d;
    border-width: 0 1px 1px 0;
    color: white;
    font-weight: bold;
    background-color: #005aa0;
    padding: 6px;
    vertical-align: top;
    empty-cells: show; }
  table.tbStandard th a:link, table.tbStandard th a:visited, table.standard th a:link, table.standard th a:visited {
    color: white; }

table.tbSimple {
  border-collapse: collapse;
  border: none;
  empty-cells: show;
  margin-top: 2px;
  margin-bottom: 2px; }
  table.tbSimple td {
    border: solid #cccccc 1px;
    padding: 2px;
    vertical-align: top;
    empty-cells: show; }

table.tbElbeProtokoll {
  border-collapse: collapse;
  border: none;
  empty-cells: show;
  margin-top: 2px;
  margin-bottom: 2px; }
  table.tbElbeProtokoll td {
    border: solid #666666 1px;
    padding: 2px;
    vertical-align: top;
    empty-cells: show; }

table.tbSiteMap {
  border-collapse: collapse;
  border: none;
  margin-top: 2px;
  margin-bottom: 2px;
  width: 800px; }
  table.tbSiteMap td {
    border: none;
    padding: 0;
    font-size: 10pt;
    vertical-align: middle;
    empty-cells: show; }
    table.tbSiteMap td img {
      border: 0;
      margin: 0; }

table.tbNoBorder {
  border-collapse: collapse;
  border: none;
  empty-cells: show; }
  table.tbNoBorder td {
    border: none;
    padding: 2px 4px 2px 0px;
    vertical-align: top;
    empty-cells: show; }

table.tbSearch {
  width: 100%;
  border-collapse: collapse;
  border: solid #c3d2de 1px;
  border-bottom: none;
  empty-cells: show;
  margin-top: 2px;
  margin-bottom: 2px; }
  table.tbSearch td {
    background-color: #f2f2f3;
    border-bottom: solid #c3d2de 1px;
    padding: 2px 2px 2px 2px;
    vertical-align: top;
    empty-cells: show; }
  table.tbSearch th {
    color: white;
    background-color: #005aa0;
    padding: 4px 2px 4px 2px;
    vertical-align: top;
    empty-cells: show; }
  table.tbSearch td p, table.tbSearch th p {
    margin: 0; }

table.tbInline {
  border-collapse: collapse;
  border: none;
  empty-cells: show;
  margin: 0px; }
  table.tbInline td {
    border: none;
    padding: 0;
    margin: 0;
    vertical-align: top;
    empty-cells: show; }

table.tbInlineBorder {
  border-top: hidden;
  border-left: hidden;
  border-right: hidden;
  border-bottom: hidden;
  border-collapse: collapse;
  empty-cells: show;
  margin: 0; }
  table.tbInlineBorder td {
    border: 1px solid #ccc;
    padding: 2px;
    margin: 0;
    vertical-align: top;
    empty-cells: show; }

table.tbSticky th {
  position: sticky;
  top: 0; }

.bild_links, .bild_rechts, .bild_zeile, .bild_zeile_mitte {
  width: 140px;
  border-collapse: collapse; }

.bild_links {
  clear: both;
  float: left;
  margin: 6px 10px 2px 0px !important; }

.bild_rechts {
  float: right;
  margin: 6px 0 2px 10px !important; }

.bild_zeile {
  clear: both;
  margin: 2px 5px 2px 0; }

.bild_zeile_mitte {
  clear: both;
  margin-left: auto !important;
  margin-right: auto !important; }

.bild_links img, .bild_rechts img, .bild_zeile img, .bild_zeile_mitte img {
  padding: 0px !important;
  margin: 0px !important;
  border: 0px !important; }

.bildunterschrift {
  padding: 0px !important;
  margin: 1px 1px 3px 1px; }

.BildTabUnterschrift, .BildTabFoto, .BildTabDownload {
  padding: 2px !important;
  background-color: #d9d9d9;
  font-size: 90% !important; }

.BildTabImg {
  padding: 0px;
  background-color: #999999; }

.img_links {
  clear: both;
  float: left;
  margin: 5px 10px 5px 0 !important; }

.img_rechts {
  float: right;
  margin: 5px 0 5px 10px !important; }

.img_zeile {
  clear: both;
  margin: 2px 0 2px 0 !important; }

.img_zeile_mitte {
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 2px;
  margin-bottom: 2px; }

#visicard {
  width: 100%; }
  #visicard td {
    padding: 3px 0px 3px 0px;
    vertical-align: top; }
  #visicard img {
    margin-right: 5px !important; }

.VisiCol1 {
  font-weight: bold;
  padding-right: 3px !important; }

.VisiCol2 {
  width: 100%; }

.ParaEdit {
  color: green;
  font-weight: bold; }
  .ParaEdit a {
    color: green;
    font-weight: bold; }

#col3 ul.tree,
#col4 ul.tree {
  margin: 0;
  margin: 0;
  list-style: none; }
  #col3 ul.tree ul,
  #col4 ul.tree ul {
    margin: 0;
    margin: 0;
    list-style: none;
    margin-left: 1em;
    position: relative; }
    #col3 ul.tree ul:before,
    #col4 ul.tree ul:before {
      content: "";
      display: block;
      width: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 8px;
      border-left: 1px solid #f0791d; }
    #col3 ul.tree ul li,
    #col4 ul.tree ul li {
      margin: 0;
      padding: 0 1.5em;
      line-height: 2em;
      color: #f0791d;
      position: relative;
      background: transparent !important; }
      #col3 ul.tree ul li:before,
      #col4 ul.tree ul li:before {
        content: "";
        display: block;
        width: 10px;
        height: 0;
        border: 1px solid #f0791d;
        border-width: 1px 0 0 0;
        margin-top: -1px;
        position: absolute;
        top: 1em;
        left: 8px; }
      #col3 ul.tree ul li:last-child:before,
      #col4 ul.tree ul li:last-child:before {
        background: white;
        height: auto;
        top: 1em;
        bottom: 0; }
      #col3 ul.tree ul li ul,
      #col4 ul.tree ul li ul {
        margin-left: .5em; }

.more_float {
  display: inline;
  padding: 0 12px 0 0;
  margin: 0 0 2px 0;
  background: url("../images/nav_more.png") no-repeat scroll right 50%;
  text-decoration: none; }

.more_float_u {
  display: inline;
  padding: 0 12px 0 0;
  margin: 0 0 2px 0;
  background: url("../images/nav_more.png") no-repeat scroll right 50%; }

.imgMobile {
  width: auto;
  max-width: 100%;
  height: auto; }

.JobCols {
  display: flex;
  flex-direction: row; }
  .JobCols .JobColMain {
    width: 100%;
    flex: 1; }
    .JobCols .JobColMain h2 {
      color: #003d6d; }
    .JobCols .JobColMain p {
      word-break: break-word; }
  .JobCols .JobColLeft {
    width: 180px;
    font-size: 90%;
    padding-right: 20px; }

.tbJobColMain {
  width: 100%;
  padding: 0px;
  font-size: 11pt; }

.tbJobColLeft {
  width: 140px;
  max-width: 140px;
  font-size: 9pt;
  padding: 0 20px 0 0; }

.JobColLeft img, .tbJobColLeft img {
  width: 100%; }

.JobColLeft p, .tbJobColLeft p {
  margin-bottom: 16px; }

.tbJobColMain h2 {
  color: #003d6d; }

.tbJobColMain h1:nth-of-type(1) {
  margin: 0 0 10px 0; }

.tbJobColMain h2 {
  margin: 12px 0 8px 0; }

.JobQrCodeR {
  display: none; }

@media only screen and (max-width: 900px) {
  .JobCols {
    flex-direction: column-reverse; }
  .JobColLeft {
    width: 100% !important;
    font-size: 100%; }
    .JobColLeft p {
      margin-bottom: 10px; }
    .JobColLeft img {
      width: 100px;
      float: right; }
  .ImgBerufUndFamilie {
    width: 100px;
    float: right; }
  .ImgHzdr {
    display: none; }
  .JobQrCodeR {
    display: inline; }
  .JobQrCode {
    display: none; } }

.NlToParaBr p, .NlToParaBr ul {
  margin-bottom: 0.5em; }

/* EOF */

/**
 *
 * Basic layout settings
 * ==========================================================================
 * These styles represent the basic column and
 * content designs and holds also some recurring style
 * classes
 *
 *
 */
/**
 *
 * Project variables
 * ==========================================================================
 * Value settings for colors, type, breakpoints and
 * base settings for size calculations
 * 
 *
 *
 */
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/* EOF */
/**
 *
 * Mixins
 * ==========================================================================
 * Own mixins for clearfixes, text removal,
 * gradients and some other minor stuff
 *
 *
 *
 */
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/* Basic structural settings */
* {
  margin: 0; }

body {
  background: #ccc url(../images/bgnoise.png) repeat 0 0 fixed;
  margin: 0; }

#header, #nav, #main, #footer {
  clear: both; }

.page_margins {
  position: relative;
  background: transparent;
  min-width: 320px; }
  .page_margins.index {
    min-width: 1024px; }

#main {
  position: relative;
  margin: 0;
  width: 100%;
  margin: 0 auto; }
  @media screen and (min-width: 1200px) {
    #main {
      width: 1170px; } }

#footer.col3 {
  padding: 0 180px;
  color: white;
  font-size: 10px; }

#footer .footer_content {
  padding: 0;
  color: white;
  background: #005aa0;
  line-height: 20px;
  width: 100%;
  font-size: 12px; }

#footer .col4 {
  padding: 0 0 0 180px; }

#nav ul {
  margin: 0 0 0 10px; }

#col1, #header .logo1, #nav .col1 {
  float: left;
  width: 180px;
  margin: 0; }

#col2, #header .logo2, #nav .col2 {
  float: right;
  width: 200px;
  margin: 0; }

#col3,
#header .logo3 {
  margin: 0 180px 0 180px; }

#nav .col3 {
  margin: 0 180px 0 0; }

#col5 {
  width: auto;
  margin: 0;
  position: relative;
  background-color: white;
  padding: 0.5em 0.5em 0 0.5em; }

.row, #navigation, #main {
  width: 100%;
  margin: 0 auto; }
  @media screen and (min-width: 1200px) {
    .row, #navigation, #main {
      width: 1170px; }
      .fullsize .row, .fullsize #navigation, .fullsize #main {
        width: auto; } }
  @media screen and (min-width: 1500px) {
    .row, #navigation, #main {
      width: 1475px; } }

/*-------------------------------------------------------------------------*/
/* Formatting the columns */
.col {
  width: 100%;
  margin: 20px 0; }
  @media screen and (min-width: 768px) {
    .col {
      position: relative;
      width: 50%;
      float: left; } }
  @media (min-width: 992px) {
    .col {
      position: relative;
      width: 33.333333333333%; } }

#col2, #col3, #col4 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3); }

#col3, #col4 {
  position: relative;
  background: white;
  margin: 7px; }

@media screen and (min-width: 768px) {
  #col3 {
    margin: 0 60px 0 215px;
    padding: 0 150px 60px 0; } }

@media screen and (min-width: 1024px) {
  #col3 {
    margin-left: 215px; } }

#col4 {
  width: auto;
  margin: 0;
  margin-bottom: 7px;
  position: relative; }
  @media screen and (min-width: 768px) {
    #col4 {
      margin: 0 0 0 217px; } }
  @media screen and (min-width: 1024px) {
    #col4 {
      margin-right: 55px; } }

#col3_content, #col4_content {
  padding: 15px 15px;
  overflow: hidden; }
  @media screen and (min-width: 1024px) {
    #col3_content, #col4_content {
      min-height: 600px; } }

#col3_content img, #col4_content img {
  margin: 0;
  padding: 0; }

#col3_content .ce_text img.float_left, #col4_content .ce_text img.float_left {
  margin: 0 15px 12px 0; }

#col3_content .ce_text .float_right, #col4_content .ce_text .float_right {
  margin: 0 0 12px 15px; }

#col4_content {
  padding: 20px 20px 40px; }

.fullsize #col5 {
  display: table;
  min-width: 100%;
  padding: 0; }

#eyecatcher {
  display: none; }
  @media screen and (min-width: 768px) {
    #eyecatcher {
      display: block;
      margin-bottom: 20px;
      background-size: auto 100%;
      background-position: left top;
      max-width: none !important; }
      #eyecatcher img {
        display: none; } }
  @media screen and (max-width: 1500px) {
    #eyecatcher {
      height: 120px !important; } }
  @media screen and (max-width: 1200px) {
    #eyecatcher {
      height: 90px !important; } }

/*-------------------------------------------------------------------------*/
/* The overlay bars at the end of the main column */
.column-footer-bars {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px; }
  .column-footer-bars div {
    position: absolute;
    bottom: 0;
    background: #005aa0;
    height: 10px;
    opacity: .1; }
    .column-footer-bars div.full {
      left: 0;
      width: 100%; }
    .column-footer-bars div.half {
      left: 15px;
      right: 0; }
    .column-footer-bars div.third {
      right: 0;
      width: 50%;
      height: 20px; }
  @media screen and (min-width: 768px) {
    .column-footer-bars div.half {
      left: 25px; } }
  @media screen and (min-width: 1200px) {
    .column-footer-bars div.half {
      left: 34%;
      right: 0; }
    .column-footer-bars div.third {
      width: 33%;
      padding: 0 0 0 10px; } }

/*-------------------------------------------------------------------------*/
/* Sidebar toggle for mobile views */
.sidebar-toggle {
  position: absolute;
  top: 0;
  right: -40px;
  height: 40px;
  width: 40px;
  z-index: 1000;
  display: block;
  border: 0 none;
  background: rgba(51, 51, 51, 0.8); }
  .sidebar-toggle .sidebar-icon {
    width: 35px;
    height: 40px;
    display: block;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYCAQAAABQQhX9AAAAN0lEQVR4AWP4DwEMhCAtFDKAlA0hhWCAWyEaoNxEuBSUTTOFYIBbIRqgiokMw8bX6Fpomx6JhADvcivxMplvkwAAAABJRU5ErkJggg==) no-repeat center center;
    background-size: 20px 24px; }
    @media only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 21), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
      .sidebar-toggle .sidebar-icon {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAwAQAAAABLAAU2AAAAAnRSTlMAAHaTzTgAAAAzSURBVHgBY/jDAAQESANSSf4DcNL+P5REEzdgZsBF/kGQ/xEkQhyhkhxzGBigJHb3owEAzKYkSeZdpG4AAAAASUVORK5CYII=); } }
  .sidebar-toggle:focus {
    outline: none; }
  @media screen and (min-width: 768px) {
    .sidebar-toggle {
      display: none; } }

@media screen and (min-width: 768px) {
  .mobile-sidebar {
    display: none; } }

/*-------------------------------------------------------------------------*/
/* Recurring used classes and elements */
.clearfix:after, .home-media .media-oneslide ul:after, .row:after, #navigation .col3 .hlist ul:after, #footer .membership:after, .home-service ul:after {
  position: relative;
  width: 100%;
  height: 1px;
  display: table;
  clear: both;
  content: " "; }

.f-screenreader {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

/*-------------------------------------------------------------------------*/
/* EOF */

/**
 *
 * Components
 * ==========================================================================
 * Multiple SASS bindings for components like navigation,
 * forms, content styles aso.
 *
 *
 */
/**
 *
 * Project variables
 * ==========================================================================
 * Value settings for colors, type, breakpoints and
 * base settings for size calculations
 * 
 *
 *
 */
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/* EOF */
/**
 *
 * Mixins
 * ==========================================================================
 * Own mixins for clearfixes, text removal,
 * gradients and some other minor stuff
 *
 *
 *
 */
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/**
 *
 * Navigations
 * ==========================================================================
 * All general styles for navigation elements
 *
 *
 */
/*-------------------------------------------------------------------------*/
/* Basic setup for header and sidebar navigation */
#navigation {
  position: relative;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  height: 0;
  overflow: hidden;
  transition: all 500ms ease-in-out; }
  #navigation.open {
    height: auto;
    margin-bottom: 10px; }
    #navigation.open .mobile-fwd {
      /* background-color: $hzdrBlueUltraLight; */
      padding: 10px 15px 10px 10px !important;
      margin-top: 10px;
      margin-bottom: -19px;
      border-top: 1px solid #f0791d;
      border-bottom: 1px solid #ddebf5; }
      #navigation.open .mobile-fwd strong {
        font-size: inherit; }
  #navigation .inside {
    line-height: 2.3em; }
  #navigation .col1, #navigation .col2, #navigation .col3 {
    background-color: #003d6d;
    color: white;
    overflow: hidden; }
  #navigation .hlist ul {
    width: 99%; }
  #navigation .col2 {
    position: relative;
    width: 100%;
    background: #888;
    text-align: right;
    margin-bottom: 4px;
    padding: 6px 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse; }
    #navigation .col2 .hlist {
      position: relative;
      line-height: 1.6; }
      #navigation .col2 .hlist ul {
        width: auto;
        overflow: visible;
        float: none;
        margin: 0 !important; }
        #navigation .col2 .hlist ul li {
          position: relative;
          display: inline-block;
          float: none;
          padding: 0 5px;
          border-left: 1px solid white; }
          #navigation .col2 .hlist ul li:first-child {
            border-left: 0 none; }
          #navigation .col2 .hlist ul li a {
            padding: 5px;
            border: 0 none; }
    #navigation .col2 .social-media ul {
      width: auto;
      list-style: none;
      margin: 0;
      padding: 0;
      line-height: 1; }
      #navigation .col2 .social-media ul li {
        display: inline-block;
        margin: 0 7px 0 0;
        float: left; }
        #navigation .col2 .social-media ul li a {
          position: relative;
          height: 24px;
          width: 24px;
          display: inline-block;
          font: "0/0" a;
          color: transparent;
          text-shadow: none;
          background-color: transparent;
          border: 0; }
          #navigation .col2 .social-media ul li a:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            text-indent: 0;
            font-size: 30px;
            color: white; }
          #navigation .col2 .social-media ul li a.icon-hzdr-facebook {
            display: none; }
  #navigation .col3 {
    position: relative;
    margin: 30px 0 0;
    padding: 0;
    float: none;
    background: transparent;
    overflow: visible; }
    #navigation .col3 .mod_navigation {
      border-left: 1px solid white;
      width: 100%; }
    #navigation .col3 .hlist {
      overflow: visible;
      margin: 0;
      width: 100%;
      display: block; }
      #navigation .col3 .hlist ul {
        overflow: visible;
        margin: 0;
        width: 100%; }
        #navigation .col3 .hlist ul li {
          position: relative;
          float: none;
          line-height: 1em;
          display: block;
          border-bottom: 1px solid #ddebf5; }
          #navigation .col3 .hlist ul li a {
            color: #005aa0;
            font-size: 1.1152em;
            font-weight: 400;
            border: 0 none;
            display: block;
            padding: 10px; }
            #navigation .col3 .hlist ul li a.active {
              color: #f0791d; }
          #navigation .col3 .hlist ul li ul {
            display: none;
            float: none;
            background: #ddebf5;
            margin: 10px;
            width: auto; }
          #navigation .col3 .hlist ul li.dropdown > a.dropdown-toggle:before {
            position: relative;
            width: 0;
            height: 0;
            margin: 2px 5px 0 5px;
            display: block;
            border: 5px solid transparent;
            border-color: transparent transparent transparent #f0791d;
            border-right-width: 0;
            content: " ";
            float: left; }
          #navigation .col3 .hlist ul li.dropdown > a.dropdown-toggle .mobile-fwd {
            z-index: 1000;
            height: 1em;
            color: #00599c;
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            line-height: 1em;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAMAAAAGyf7hAAAABlBMVEUAPm5mm8Qiad3yAAAAAXRSTlMAQObYZgAAABtJREFUeNrtyDEBAAAMAiDsX3oBjOA4QZTfyTsb2wAapNOIqQAAAABJRU5ErkJggg==) no-repeat right center;
            padding: 0 20px 0 0;
            display: none; }
            #navigation .col3 .hlist ul li.dropdown > a.dropdown-toggle .mobile-fwd strong {
              border: none;
              padding: initial;
              color: inherit; }
          #navigation .col3 .hlist ul li.dropdown.open > a.dropdown-toggle .mobile-fwd {
            display: block; }
          #navigation .col3 .hlist ul li.dropdown.open > a.dropdown-toggle:before {
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg); }
          #navigation .col3 .hlist ul li.open ul {
            display: block; }
          #navigation .col3 .hlist ul li.dropdown.open > a.dropdown-toggle:after {
            border-color: transparent transparent #f0791d;
            content: "";
            float: right;
            margin: 0; }
          #navigation .col3 .hlist ul li ul li {
            border-bottom-color: white; }
            #navigation .col3 .hlist ul li ul li a {
              font-size: .9em; }
    #navigation .col3 .mod_search form {
      clear: both;
      padding: 30px 15px; }
    #navigation .col3 .mod_search .type-text {
      padding: 7px 12px;
      width: 180px;
      background: #ddebf5;
      border: 0 none;
      font-weight: 700;
      color: #003d6d; }
      #navigation .col3 .mod_search .type-text::placeholder {
        color: #005aa0; }
      #navigation .col3 .mod_search .type-text:focus {
        color: #003d6d; }
    #navigation .col3 .mod_search .btn {
      position: relative;
      width: 26px;
      height: 25px;
      padding: 0;
      margin: 0 10px 0 -30px;
      border: 0 none;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNi45IiBoZWlnaHQ9IjE2LjkiPjxwYXRoIGZpbGw9IiMwMDU4OUMiIGQ9Ik0xNi43MTcgMTYuMDE2TDEzLjIwMSAxMi41YTcuNDk5IDcuNDk5IDAgMCAwIDEuODc2LTQuOTYxQzE1LjA3NyAzLjM4MiAxMS42OTUgMCA3LjUzOSAwUzAgMy4zODIgMCA3LjUzOHMzLjM4MiA3LjUzOSA3LjUzOSA3LjUzOWMxLjkgMCAzLjYzNC0uNzEyIDQuOTYxLTEuODc2bDMuNTE2IDMuNTE2YS40OTQuNDk0IDAgMCAwIC43MDIgMCAuNDk2LjQ5NiAwIDAgMC0uMDAxLS43MDF6TS45OTIgNy41MzhjMC0zLjYxIDIuOTM3LTYuNTQ3IDYuNTQ3LTYuNTQ3czYuNTQ3IDIuOTM3IDYuNTQ3IDYuNTQ3LTIuOTM3IDYuNTQ3LTYuNTQ3IDYuNTQ3Uy45OTIgMTEuMTQ4Ljk5MiA3LjUzOHoiLz48L3N2Zz4=) no-repeat center center;
      background-size: 17px 17px;
      text-indent: 200%;
      white-space: nowrap;
      display: inline-block;
      overflow: hidden;
      outline: none;
      cursor: pointer; }
      #navigation .col3 .mod_search .btn:focus, #navigation .col3 .mod_search .btn:hover {
        transform: scale(1.2); }
    #navigation .col3 .mod_search a.staff-search {
      position: relative;
      font-size: 12px;
      font-weight: bold;
      text-decoration: none;
      padding-right: 10px; }
      #navigation .col3 .mod_search a.staff-search:after {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -3px;
        border: 3px solid transparent;
        border-left: 5px solid #d6650e;
        border-right-width: 0;
        content: " "; }
      #navigation .col3 .mod_search a.staff-search:hover {
        color: #f0791d; }
  @media screen and (min-width: 768px) {
    #navigation {
      position: relative;
      height: auto;
      box-shadow: none;
      overflow: visible; }
      #navigation.open {
        height: auto;
        overflow: visible; }
      #navigation .col2 {
        position: absolute;
        bottom: 20px;
        right: 25px;
        width: auto;
        background: transparent;
        margin: 0;
        padding: 0;
        z-index: 999;
        flex-direction: row; }
        #navigation .col2 .hlist {
          line-height: 30px; }
          #navigation .col2 .hlist ul li {
            border-left: 1px solid #ddebf5; }
            #navigation .col2 .hlist ul li a {
              font-size: 12px;
              color: #005aa0; }
            #navigation .col2 .hlist ul li:first-child {
              border-left: 1px solid #ddebf5; }
            #navigation .col2 .hlist ul li:last-child {
              padding-right: 0; }
        #navigation .col2 .social-media ul {
          display: inline-block; }
          #navigation .col2 .social-media ul li a {
            height: 30px;
            width: 30px; }
            #navigation .col2 .social-media ul li a:before {
              color: #005aa0;
              font-size: 36px;
              line-height: 36px;
              text-align: center;
              text-indent: -3px; }
      #navigation .col3 {
        position: absolute;
        top: -172px;
        left: 215px;
        right: 0;
        overflow: visible;
        padding: 0;
        margin: 0; }
        #navigation .col3 .hlist {
          overflow: visible;
          margin: 0 25px 0 0;
          float: none;
          width: auto;
          height: 60px;
          display: block; }
          #navigation .col3 .hlist ul {
            overflow: visible;
            margin: 0;
            width: 100%;
            float: none;
            display: flex;
            flex-wrap: nowrap;
            align-content: stretch; }
            #navigation .col3 .hlist ul li {
              position: relative;
              padding: 0;
              flex: 1 0 auto;
              border-bottom: 0 none; }
              #navigation .col3 .hlist ul li a {
                position: relative;
                border-bottom: 4px solid #005aa0;
                padding: 30px 5px 11px;
                font-size: 12px;
                text-align: center; }
                #navigation .col3 .hlist ul li a:hover {
                  border-bottom-color: #999; }
              #navigation .col3 .hlist ul li:first-child > a:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                height: 90%;
                background: #ddebf5;
                content: " ";
                display: block;
                border: 0 none;
                margin: 0;
                transform: none !important; }
              #navigation .col3 .hlist ul li.open a {
                border-bottom-color: #999; }
              #navigation .col3 .hlist ul li.dropdown > a.dropdown-toggle:before {
                display: none;
                content: none; }
              #navigation .col3 .hlist ul li.dropdown.open > a.dropdown-toggle .mobile-fwd {
                display: none; }
                .touchevents-enabled #navigation .col3 .hlist ul li.dropdown.open > a.dropdown-toggle .mobile-fwd {
                  position: absolute;
                  top: 73px;
                  left: 1px;
                  right: auto;
                  max-width: none;
                  text-align: left;
                  color: #005aa0;
                  display: block;
                  background-size: 10px 12.5px;
                  background-color: #d8e5ef;
                  width: 170px;
                  padding: 10px 20px 10px 10px;
                  z-index: 1005; }
              #navigation .col3 .hlist ul li.dropdown:first-child > a:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                height: 90%;
                background: #ddebf5;
                content: " ";
                display: block;
                border: 0 none;
                margin: 0;
                transform: none !important; }
              #navigation .col3 .hlist ul li a:after, #navigation .col3 .hlist ul li.dropdown > a:after {
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 90%;
                background: #ddebf5;
                content: " ";
                border: 0 none;
                margin: 0; }
              #navigation .col3 .hlist ul li a.active {
                border-bottom-color: #f0791d; }
              #navigation .col3 .hlist ul li ul {
                position: absolute;
                top: 120%;
                left: 0;
                border: 1px solid #d9e6f0;
                width: 200px;
                z-index: 1000;
                background: white;
                display: none;
                padding: 0;
                margin: 0; }
                .touchevents-enabled #navigation .col3 .hlist ul li ul {
                  padding-top: 30px; }
                #navigation .col3 .hlist ul li ul li {
                  float: none;
                  border: 0 none; }
                  #navigation .col3 .hlist ul li ul li a {
                    border: 0 none;
                    font-size: 14px;
                    line-height: 1.4;
                    font-weight: 400;
                    margin: 4px 0;
                    padding: 7px 10px !important;
                    display: block;
                    hyphens: none;
                    text-align: left; }
                    #navigation .col3 .hlist ul li ul li a:after {
                      display: none; }
                    #navigation .col3 .hlist ul li ul li a:hover {
                      background: #555;
                      color: white; }
              #navigation .col3 .hlist ul li.dropdown:nth-last-child(-n+2) ul {
                left: auto;
                right: 0; }
              #navigation .col3 .hlist ul li.dropdown:nth-last-child(-n+2).open > a.dropdown-toggle .mobile-fwd {
                left: auto;
                right: 1px; }
            #navigation .col3 .hlist ul:after {
              display: none;
              content: none; }
        #navigation .col3 .mod_search form {
          padding: 20px 0 0 0;
          display: inline-block; } }
  @media screen and (min-width: 1024px) {
    #navigation .col2 {
      right: 55px; }
      #navigation .col2 .hlist ul li a {
        font-size: 14px; }
    #navigation .col3 .hlist {
      margin-right: 55px; }
      #navigation .col3 .hlist ul li a {
        font-size: 1.1152em;
        padding: 30px 10px 11px 11px; }
    #navigation .col3 .mod_search form {
      padding-top: 60px; } }
  @media screen and (min-width: 1200px) {
    #navigation .col3 .hlist ul li.dropdown:nth-last-child(-n+2) ul {
      left: 0;
      right: auto; }
    #navigation .col3 .hlist ul li.dropdown:nth-last-child(-n+2).open > a.dropdown-toggle .mobile-fwd {
      left: 1px;
      right: auto; } }

/*-------------------------------------------------------------------------*/
/* Navigation toggle for mobile navigation */
.navigation-toggle {
  position: relative;
  float: right;
  padding: 7px 10px 10px 10px;
  margin-top: 45px;
  margin-right: 25px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid #eee;
  border-radius: 0; }
  .navigation-toggle:focus {
    outline: 0 none; }
  .navigation-toggle .icon-bar {
    position: relative;
    height: 3px;
    left: 0;
    width: 30px;
    background: #005aa0;
    display: block;
    margin-top: 4px;
    -webkit-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out; }
  .navigation-toggle.active .icon-bar:nth-of-type(2) {
    top: 7px;
    left: 8px;
    width: 15px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .navigation-toggle.active .icon-bar:nth-child(3) {
    background-color: transparent; }
  .navigation-toggle.active .icon-bar:nth-of-type(4) {
    top: -7px;
    left: 8px;
    width: 15px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  @media screen and (min-width: 768px) {
    .navigation-toggle {
      display: none; } }

/*-------------------------------------------------------------------------*/
/* Setup for vertical and horizontal lists */
ul.vlist {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  ul.vlist li {
    margin: 0;
    padding: 0;
    line-height: 18px; }
    ul.vlist li ul {
      list-style-type: none;
      margin: 0 0 3px 0;
      padding: 0; }
  ul.vlist a, ul.vlist strong, ul.vlist span {
    display: block;
    color: white;
    font-size: 12px;
    font-weight: normal;
    padding: 5px 5px 5px 0;
    text-decoration: none;
    border-bottom: 1px #ddd solid; }

.hlist {
  position: relative;
  overflow: hidden;
  display: inline;
  line-height: 0;
  padding: 0; }
  .hlist ul {
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: inline;
    list-style-type: none;
    float: left; }
    .hlist ul li {
      display: inline;
      float: left;
      margin: 0;
      padding: 0 5px 0 0; }
      .hlist ul li a, .hlist ul li span, .hlist ul li strong {
        color: white;
        font-size: 12px;
        margin: 0;
        padding: 0 5px 0 0;
        text-decoration: none;
        width: auto;
        border-right: 1px white solid; }
      .hlist ul li:last-child a, .hlist ul li:last-child span, .hlist ul li:last-child strong {
        border-right: 0 none;
        padding: 0; }

/*-------------------------------------------------------------------------*/
/* The breadcrumb menu in the middle section */
#breadcrumb {
  background: transparent;
  overflow: visible;
  height: auto; }
  #breadcrumb .mod_navigation {
    height: auto;
    border-bottom: 1px dotted #ccc;
    padding: 8px 15px 8px 40px;
    width: auto;
    display: block;
    float: none;
    overflow: visible; }
    #breadcrumb .mod_navigation ul {
      list-style: none;
      margin: 0;
      display: block;
      float: none; }
      #breadcrumb .mod_navigation ul li {
        position: relative;
        height: 20px;
        background: #f2f2f3 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAUCAIAAAA2kktGAAAAl0lEQVR4AYWSBRLEIAxFe//bYhGs9tc3TJiiffFsMaZa234PIj6v/98FpZTnnCd0rufZgLCVQlDWWrchfKoVHLPYEL4xBozmXJbQ+Q/Keu82hE9EwImIDeHrvYODURN6Z6T3YUP4mPlpVJcQEb8icMwhF47jzOKkoLXmJHMMVHpRFkClkFNgVXVaBTH/QUbMBvRuX2a7fQ8IULKUndLb8QAAAABJRU5ErkJggg==) no-repeat left top;
        padding: 0 4px 0 16px;
        margin: 1px 0;
        line-height: 18px; }
        #breadcrumb .mod_navigation ul li:before {
          display: none;
          content: none; }
        #breadcrumb .mod_navigation ul li:after {
          position: absolute;
          top: 0;
          right: -9px;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-color: transparent transparent transparent #f2f2f3;
          border-right-width: 0;
          content: "";
          display: block; }
        #breadcrumb .mod_navigation ul li:first-child {
          background: #f2f2f3 url(../images/breadcrumbHomeIcon.png) no-repeat 7px center;
          background-size: 12px 12px;
          padding-left: 24px; }
          @media only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 21), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
            #breadcrumb .mod_navigation ul li:first-child {
              background-image: url(../images/breadcrumbHomeIcon@2x.png); } }
        #breadcrumb .mod_navigation ul li:last-child {
          background: white url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAUCAIAAAA2kktGAAAAWUlEQVR4AaWSBRKAAAgE/f9v7e5WYg2G6aXuCKIoLsuyd2OC4jjpug6gMbMsZ0gMFdCeTdMwlCQpQGKogMRQgFZFAJKKMMTjcHGQ4L+YbAsbzK/CTydXkTEA8HK/9A2wiw4AAAAASUVORK5CYII=) no-repeat left top;
          padding-left: 12px; }
          #breadcrumb .mod_navigation ul li:last-child a {
            color: #999;
            font-weight: 700; }
        #breadcrumb .mod_navigation ul li:last-child::after {
          display: none; }
        #breadcrumb .mod_navigation ul li a {
          background: transparent;
          border: 0 none;
          color: #005aa0;
          font-size: 12px;
          padding: 0; }
          #breadcrumb .mod_navigation ul li a:hover {
            color: #f0791d; }
  @media screen and (min-width: 768px) {
    #breadcrumb {
      margin-right: -10px; } }
  #breadcrumb .mod_navigation {
    padding: 8px; }

/*--------------------------------------------------------------------------*/
/* Left navigation */
#col1 {
  position: absolute;
  top: 0;
  left: -230px;
  width: 225px;
  padding: 0 5px 5px 0;
  background: #333;
  background: rgba(51, 51, 51, 0.8);
  z-index: 1000;
  transition: all 300ms ease-in-out; }
  #col1.slide-in {
    left: 0; }
  #col1 .mod_navigation {
    width: 225px;
    height: 330px;
    overflow: scroll; }
    #col1 .mod_navigation .active-section {
      background: white;
      padding: 11px 0; }
      #col1 .mod_navigation .active-section a {
        color: #f0791d;
        text-decoration: none;
        font-size: 16px;
        padding: 0 20px 0 8%;
        display: block; }
    #col1 .mod_navigation > p {
      background: transparent !important;
      padding: 10px 5px !important;
      margin: 0 !important;
      border-bottom: 1px dotted white;
      color: white; }
  #col1 .vlist {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    background: white; }
    #col1 .vlist a {
      position: relative;
      color: #003d6d;
      background: #d9d9d9;
      font-size: 16px;
      border: 0 none;
      padding: 8px 25px 8px 8%; }
      #col1 .vlist a:hover {
        background: #555;
        color: white; }
      #col1 .vlist a.trail {
        font-weight: bold; }
      #col1 .vlist a.active {
        background: #d6650e;
        color: white;
        font-weight: bold; }
        #col1 .vlist a.active:after {
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -5px;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-color: transparent transparent transparent white;
          content: ""; }
    #col1 .vlist .subtrail a {
      background: #ababab;
      font-weight: bold;
      color: #003d6d; }
      #col1 .vlist .subtrail a:hover {
        background: #555;
        color: white; }
    #col1 .vlist ul a {
      background: white;
      padding-left: 11%;
      border-bottom: 1px solid #eee; }
  #col1 .ce_image {
    text-align: center;
    padding: 0 0 10px 0;
    margin: 10px auto;
    border-bottom: 1px solid white; }
  #col1 .ce_text {
    padding: 0 15px 10px 15px;
    border-bottom: 1px solid white;
    color: #003d6d; }
    #col1 .ce_text h1 {
      margin: 5px 0 15px 0;
      color: #003d6d; }
    #col1 .ce_text h2 {
      margin: 0;
      color: #003d6d; }
  #col1 .logout {
    margin: 10px 0 0 0;
    padding: 0;
    background: none;
    color: #ED8C01;
    border: none; }
  #col1 p a {
    color: white; }
  #col1 hr {
    display: none; }
  #col1 #col1_content > p {
    background: transparent !important;
    padding: 10px 5px !important;
    margin: 0 !important;
    border-bottom: 1px dotted white;
    color: white; }
  @media screen and (min-width: 768px) {
    #col1 {
      position: relative;
      left: 0;
      width: 220px;
      z-index: auto;
      background: transparent; }
      #col1 .mod_navigation {
        width: 230px;
        height: auto;
        overflow: visible; }
        #col1 .mod_navigation > p {
          border-bottom: 1px dotted #999;
          color: #555; }
      #col1 p a {
        color: #555 !important; }
      #col1 .vlist a.active:before {
        border-top-color: white; }
      #col1 .vlist a.active:after {
        left: auto;
        right: 12px;
        margin-top: -10px;
        border-color: transparent white transparent transparent;
        border-width: 10px; }
      #col1 #col1_content > p {
        border-bottom: 1px dotted #999;
        color: #555; } }

/*--------------------------------------------------------------------------*/
/* Right navigation (Sidebar) */
#col2 {
  display: none; }
  #col2 #col2_content p {
    padding: 5px; }
  #col2 a, #col2 a:visited {
    color: #003d6d;
    text-decoration: none; }
  #col2 a:hover {
    text-decoration: underline; }
  #col2 a:visited {
    color: #4D87C7; }
  #col2 .mod_eventlist {
    margin: 0 0 5px 0; }
    #col2 .mod_eventlist div {
      margin: 0; }
    #col2 .mod_eventlist .ce_image {
      margin: 0;
      padding: 0; }
    #col2 .mod_eventlist .event {
      width: 170px;
      padding: 2px 5px 0 5px; }
  #col2 .mod_navigation {
    margin: 0; }
  #col2 .mod_download .vlist li {
    background-color: #F2F2F3; }
    #col2 .mod_download .vlist li a {
      color: #003d6d;
      background: url("../images/nav_for.png") no-repeat scroll 90% 50%; }
  #col2 .ce_image {
    text-align: center;
    margin: 10px auto; }
  @media screen and (min-width: 768px) {
    #col2 {
      position: relative;
      z-index: 200;
      background: #f2f2f3;
      padding: 10px 0;
      display: block; } }

/*--------------------------------------------------------------------------*/
/* Navigation footer */
#footer .mod_navigation {
  float: right; }
  #footer .mod_navigation img {
    margin-top: 1px; }

/*-------------------------------------------------------------------------*/
/* Contrast settings */
/* [TJ: Is this thing in use anymore? Could those lines be removed?] */
#contrast {
  float: right;
  overflow: hidden; }
  #contrast a {
    border-right: none;
    display: block; }
  #contrast img {
    margin: 6.5px 0; }

/*-------------------------------------------------------------------------*/
/* page action buttons */
.page-actions {
  position: relative;
  list-style: none;
  margin: -45px 0 0 0;
  padding: 0 25px 0 0;
  display: flex;
  justify-content: flex-end; }
  .page-actions a {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    font: "0/0" a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
    display: inline-block;
    background: rgba(214, 101, 14, 0.8);
    margin: 5px;
    text-align: center;
    overflow: hidden; }
    .page-actions a img {
      position: relative;
      margin-top: 8px; }
  @media screen and (min-width: 768px) {
    .page-actions {
      margin: -35px 45px -10px 0; } }

/*-------------------------------------------------------------------------*/
/* EOF */
/**
 *
 * Content
 * ==========================================================================
 * Design for content elements
 *
 *
 */
/*-------------------------------------------------------------------------*/
/* Basic HTML elements setup */
h1, h2, h3, h4, h5, h6, ul, #col2 ul, #col3 ul, #col4 ul, #col5 ul, #col3 table, #col4 table, #col5 table, #col3 caption, #col4 caption, #col5 caption {
  margin: 0 0 8px 0; }

textarea, pre, code, kbd, samp, var, tt {
  font-family: Consolas, "Lucida Console", "Andale Mono", "Bitstream Vera Sans Mono", "Courier New", Courier; }

body {
  font-family: Arial,sans-serif;
  font-size: 16px;
  color: black; }

/*-------------------------------------------------------------------------*/
/* Headlines */
h1, h2, h3, h4, h5, h6 {
  font-family: Arial,sans-serif;
  font-weight: normal;
  color: black; }

h1 {
  font-size: 24px;
  color: #005aa0;
  font-weight: bold;
  line-height: 36px;
  margin: 0 0 20px 0; }

/*h1:first-of-type {
  margin-top: 0px;
}*/
h2 {
  font-size: 20px;
  font-weight: bold;
  color: #005aa0;
  line-height: 30px;
  margin: 0 0 14px 0; }
  p h2 +, ul h2 + {
    margin-top: 16px; }

h3 {
  font-size: 18px;
  font-weight: bold;
  color: #005aa0;
  line-height: 25px;
  margin: 0 0 12px 0; }
  p h3 +, ul h3 + {
    margin-top: 14px; }

h4 {
  font-size: 16px;
  font-weight: bold;
  color: black; }

h5, h6 {
  font-size: 100%; }

/*-------------------------------------------------------------------------*/
/* Lists */
#col3 ul, #col4 ul, #col5 ul {
  margin: 0 0 1rem 0;
  padding: 0;
  list-style-type: none !important;
  overflow: hidden; }
  #col3 ul li, #col4 ul li, #col5 ul li {
    position: relative;
    padding-left: 16px;
    margin: 0 0 5px 0;
    line-height: 24px; }
    #col3 ul li:before, #col4 ul li:before, #col5 ul li:before {
      position: absolute;
      top: 8px;
      left: 0;
      width: 6px;
      height: 6px;
      background: #f0791d;
      content: " ";
      box-sizing: border-box; }
    #col3 ul li ul, #col4 ul li ul, #col5 ul li ul {
      margin-top: 5px; }
    #col3 ul li li:before, #col4 ul li li:before, #col5 ul li li:before {
      border: 2px solid #f0791d;
      background: white; }
    #col3 ul li li li:before, #col4 ul li li li:before, #col5 ul li li li:before {
      border-width: 1px; }
    #col3 ul li p, #col4 ul li p, #col5 ul li p {
      margin: 0;
      padding: 0;
      line-height: 24px; }
  #col3 ul ul, #col4 ul ul, #col5 ul ul {
    list-style-type: none;
    margin: 0 0 3px 0;
    display: block; }

#col3 ol, #col3 dl, #col4 ol, #col4 dl, #col5 ol, #col5 dl {
  padding: 0;
  line-height: 24px; }

#col3 ol, #col4 ol, #col5 ol {
  list-style-type: decimal;
  margin: 0 0 1rem 20px; }
  #col3 ol li, #col4 ol li, #col5 ol li {
    margin: 0 0 5px 0; }
  #col3 ol ol, #col4 ol ol, #col5 ol ol {
    list-style-type: lower-latin;
    margin: 5px 0 5px 20px; }

#col3 dl dt, #col4 dl dt, #col5 dl dt {
  font-weight: bold; }

#col3 dl dd, #col4 dl dd, #col5 dl dd {
  margin: 0 0 1em 0.8em; }

#col4_content ul > li, #col4_content ul > li > p {
  line-height: 18px; }

/*-------------------------------------------------------------------------*/
/* Text designs */
p {
  line-height: 24px;
  margin-bottom: 1rem; }
  p[style^="text-align: justify;"] {
    text-align: left !important; }
  p.abstract {
    font-weight: bold;
    line-height: 22px;
    margin: 0 0 1rem 0; }
  #col2 p {
    line-height: 1.4; }

blockquote {
  margin: 40px 0;
  border-left: 1px solid #005aa0;
  padding: 10px 0 10px 20px;
  font-size: 18px; }
  blockquote footer {
    font-weight: bold;
    color: #005aa0; }
    blockquote footer cite {
      font-style: normal;
      font-size: 14px; }
  @media screen and (min-width: 1200px) {
    blockquote {
      padding-right: 100px; } }

strong, b {
  font-weight: bold; }

em, i {
  font-style: italic; }

big {
  font-size: 116.667%; }

small {
  font-size: 91.667%; }

pre {
  line-height: 1.5em;
  margin: 0 0 1em 0; }

pre, code, kbd, tt, samp, var {
  font-size: 110% !important; }

pre, code {
  font-family: "Courier New", Courier; }

kbd, samp, var, tt {
  color: #666;
  font-weight: bold; }

var, dfn {
  font-style: italic; }

acronym, abbr {
  border-bottom: 1px #aaa dotted;
  font-variant: small-caps;
  letter-spacing: .07em;
  cursor: help; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super;
  font-size: smaller; }

hr {
  position: relative;
  height: 1px;
  color: white;
  background: transparent;
  margin: 20px 0;
  border: 0;
  border-bottom: 1px solid #005aa0;
  clear: both; }
  hr.dashed {
    margin: 0 0 10px 0;
    padding: 0;
    display: block;
    overflow: hidden;
    border-bottom-style: dashed; }
  hr.solid {
    margin: 0 0 10px 0;
    padding: 0;
    display: block;
    overflow: hidden;
    border-bottom-style: solid; }

/*-------------------------------------------------------------------------*/
/* Links */
a {
  color: #005aa0;
  background: transparent;
  text-decoration: underline; }
  a:visited {
    color: #2c8eda; }

#footer a {
  color: white;
  line-height: 20px; }

#main a:hover, #main a:active {
  color: #f0791d; }

/*-------------------------------------------------------------------------*/
/* Images with captions */
p.icaption_left {
  float: left;
  display: inline;
  margin: 0 1em 0.15em 0; }

p.icaption_right {
  float: right;
  display: inline;
  margin: 0 0 0.15em 1em; }

p.icaption_left img, p.icaption_right img {
  padding: 0;
  border: 1px #888 solid; }

p.icaption_left strong, p.icaption_right strong {
  display: block;
  overflow: hidden;
  margin-top: 2px;
  padding: 0.3em 0.5em;
  background: #eee;
  font-weight: normal;
  font-size: 91.667%; }

#col4_content .main-content-image,
#col3_content .main-content-image {
  position: relative;
  max-width: 100%;
  height: auto;
  margin: 0 0 40px 0; }

figure.image {
  display: table;
  line-height: 0;
  background: #e8e8e8;
  padding: 1px; }
  figure.image img {
    max-width: 100%;
    height: auto;
    margin: 0; }
  figure.image figcaption, figure.image figcaption p {
    font-size: 14px;
    line-height: 18px; }
  figure.image figcaption {
    display: table-caption;
    caption-side: bottom;
    max-width: 100%;
    padding: 10px;
    background: #e8e8e8; }
    figure.image figcaption p {
      margin: 2px 0; }
      figure.image figcaption p.figDownload, figure.image figcaption p.figCopyright {
        color: #555; }
    figure.image figcaption *:empty {
      display: none; }

figure, aside.figContainer {
  position: relative;
  margin-bottom: 20px; }
  figure.align-left, aside.figContainer.align-left {
    margin-right: 15px;
    float: left;
    max-width: 60%; }
    figure.align-left:nth-last-child(2), aside.figContainer.align-left:nth-last-child(2) {
      margin-right: 0px; }
  figure.align-right, aside.figContainer.align-right {
    margin-left: 15px;
    float: right;
    max-width: 60%; }
  figure.align-center, aside.figContainer.align-center {
    margin-left: auto;
    margin-right: auto; }

/*-------------------------------------------------------------------------*/
/* Generic content classes */
.highlight {
  color: #c30; }

.dimmed {
  color: #888; }

.info {
  background: #f8f8f8;
  color: #666;
  padding: 10px;
  margin-bottom: 0.5em;
  font-size: 91.7%; }

.note {
  background: #efe;
  color: #040;
  border: 2px #484 solid;
  padding: 10px;
  margin-bottom: 1em; }

.important {
  background: #ffe;
  color: #440;
  border: 2px #884 solid;
  padding: 10px;
  margin-bottom: 1em; }

.warning {
  background: #fee;
  color: #400;
  border: 2px #844 solid;
  padding: 10px;
  margin-bottom: 1em; }

.float_left {
  float: left;
  display: inline;
  margin-right: 1em;
  margin-bottom: 0.2em; }

.float_right {
  float: right;
  display: inline;
  margin-left: 1em;
  margin-bottom: 0.2em; }

.center {
  display: block;
  text-align: center;
  margin: 0.5em auto; }

/*-------------------------------------------------------------------------*/
/* Boxes and headlines */
.subc3box.b643 {
  width: 634px; }
  .subc3box.b643 a {
    text-decoration: none; }

.subc3box h1 {
  font-size: 12px; }

.subc3box .ce_text a {
  text-decoration: none; }
  .subc3box .ce_text a:hover {
    text-decoration: underline; }

.subc3box .ce_text > a {
  margin-left: 8px; }

.subc3box .subcl {
  margin: 0 11px 0 0;
  padding: 0; }

.subc3box .subc {
  margin: 0 5.5px;
  padding: 0; }

.subc3box .subcr {
  margin: 0 0 0 11px;
  padding: 0; }

.subc3box .subcl h1.box, .subc3box .subc h1.box, .subc3box .subcr h1.box {
  font-size: 12px;
  height: 31px;
  padding: 0 10px;
  background: url("../images/main-page_subc_bg.png") no-repeat scroll left top;
  line-height: 2.5em;
  display: block;
  margin: 0; }

.subc3box h1.box_634 {
  font-size: 12px;
  height: 31px;
  padding: 0 10px;
  background: url("../images/h1_box-634.png") no-repeat scroll left top;
  line-height: 2.5em;
  display: block;
  margin: 0; }

#col2_content h1 {
  margin: 0 0 13px 0; }

#col2_content .ce_text {
  margin: 3px 0 10px 0; }

.ce_image .caption {
  color: white;
  background: #868889;
  padding: 3px; }
  .ce_image .caption a {
    display: block;
    line-height: 16px;
    color: white;
    text-decoration: none; }
    .ce_image .caption a.download {
      background: url("../images/caption.png") no-repeat scroll left top;
      padding-left: 20px; }

#footer .ce_text {
  overflow: hidden;
  padding: 0 0 0 5px;
  float: left; }

.gray_box {
  border: 1px solid #005aa0;
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 3px 0;
  overflow: hidden; }
  .gray_box h1 {
    font-size: 120%; }
  .gray_box .c80l a {
    line-height: 22px;
    text-decoration: none;
    display: block; }
  .gray_box a:hover {
    text-decoration: underline; }

.more {
  display: block;
  padding: 0 12px 0 0;
  margin: 0 0 2px 0;
  text-align: right;
  background: url("../images/nav_more.png") no-repeat scroll right 50%;
  text-decoration: none; }
  .more.left {
    display: inline; }

.c10l {
  float: left;
  width: 10%; }

.c90r {
  float: right;
  width: 90%;
  margin-left: -5px; }

#col3 .mod_search span, #col4 .mod_search span {
  color: #003d6d;
  font-weight: bold; }

#col3 .mod_search .form_text span, #col4 .mod_search .form_text span {
  margin: 0 20px 0 0; }

#col3 .mod_search .form_checkbox, #col4 .mod_search .form_checkbox {
  margin: 10px 0 0 65px; }
  #col3 .mod_search .form_checkbox input, #col4 .mod_search .form_checkbox input {
    border: 1px solid #003d6d;
    background-color: white; }

#col3 .mod_search .form_checkbox span, #col4 .mod_search .form_checkbox span {
  margin: 0 15px 0 8px; }

#col3 .mod_search .type-image, #col4 .mod_search .type-image {
  margin: 0 0 0 10px; }

#col3 .mod_search .type-text, #col4 .mod_search .type-text {
  border: 1px solid black;
  padding: 2px;
  width: 350px; }

/*-------------------------------------------------------------------------*/
/* Sidebar styles */
.sidebar h1, #col2_content h1 {
  display: block;
  font-size: 12px;
  font-weight: normal;
  padding: 5px 0 5px 10%;
  text-decoration: none;
  color: white;
  margin: 0;
  line-height: 16px;
  background: #555; }
  .sidebar h1.blue, #col2_content h1.blue {
    background-color: #004776; }

.sidebar .sidebarelement, #col2_content .sidebarelement {
  margin-top: 1px; }
  .sidebar .sidebarelement h1, #col2_content .sidebarelement h1 {
    padding-left: 6px; }
    .sidebar .sidebarelement h1 a, #col2_content .sidebarelement h1 a {
      color: white; }
  .sidebar .sidebarelement .sidebartext, #col2_content .sidebarelement .sidebartext {
    font-size: 0.79em;
    line-height: 1.3em;
    margin: 5px 0 7px 0; }
    .sidebar .sidebarelement .sidebartext h1, #col2_content .sidebarelement .sidebartext h1 {
      font-weight: bold;
      margin: 0 0 5px 0;
      clear: both; }
    .sidebar .sidebarelement .sidebartext h2, .sidebar .sidebarelement .sidebartext h3, #col2_content .sidebarelement .sidebartext h2, #col2_content .sidebarelement .sidebartext h3 {
      font-size: 11px;
      margin: 0 5px 3px 5px;
      clear: both; }
    .sidebar .sidebarelement .sidebartext p, #col2_content .sidebarelement .sidebartext p {
      margin: 5px 0;
      padding-bottom: 0;
      padding-top: 0;
      clear: both; }
    .sidebar .sidebarelement .sidebartext ul, .sidebar .sidebarelement .sidebartext ol, #col2_content .sidebarelement .sidebartext ul, #col2_content .sidebarelement .sidebartext ol {
      margin: 5px 0 5px 3px;
      padding: 0 0 3px 3px; }
    .sidebar .sidebarelement .sidebartext hr, #col2_content .sidebarelement .sidebartext hr {
      color: white;
      background: transparent;
      margin: 6px 6px 6px 3px;
      padding: 0 0 0 0;
      border-bottom: 1px #005aa0 dashed; }
    .sidebar .sidebarelement .sidebartext img, #col2_content .sidebarelement .sidebartext img {
      margin: 0 3px 0 0;
      max-width: 200px; }

.sidebar .sidebarpic, #col2_content .sidebarpic {
  width: 180px;
  text-align: center; }
  .sidebar .sidebarpic img, #col2_content .sidebarpic img {
    max-width: 200px; }

.sidebar .sidbar90pic, #col2_content .sidbar90pic {
  width: 100px;
  max-width: 100px !important; }

/*-------------------------------------------------------------------------*/
/* Tables */
table.intra {
  width: auto;
  border-collapse: separate;
  border: 0;
  margin-top: 2em;
  margin-bottom: 1em;
  border-spacing: 1px; }
  table.intra th, table.intra td {
    border: 0;
    padding: 0.5em;
    padding-left: 1.5em; }
  table.intra thead th, table.intra thead td {
    background: #005aa0;
    color: white; }
  table.intra tbody th.odd, table.intra tbody td.odd {
    background: #dce5f2; }
  table.intra tbody th.even, table.intra tbody td.even {
    background: white; }
  table.intra tbody tr:hover th, table.intra tbody tr:hover td {
    background: #eddbc1; }
  table.intra tfoot th, table.intra tfoot td {
    background: #a9abac;
    color: white;
    font-weight: bold; }

table.tbSiteMap td img {
  display: block; }

table caption {
  font-weight: bold;
  font-size: 80%; }

table.full {
  width: 100%; }

table.fixed {
  table-layout: fixed; }

table tbody th {
  text-align: left; }

table h1, table h2, table h3 {
  margin: 0;
  padding: 0;
  line-height: 1.3em; }

/*-------------------------------------------------------------------------*/
/* Contact Informations inside content area */
#col4 .additional-content .contact-container {
  border: 1px solid #a0d5ff;
  border-width: 1px 0;
  padding: 20px 0; }
  #col4 .additional-content .contact-container li {
    padding: 10px 0; }
    #col4 .additional-content .contact-container li:before {
      display: none; }
    #col4 .additional-content .contact-container li h4 {
      margin: 0; }
    #col4 .additional-content .contact-container li p {
      line-height: 20px; }
  @media screen and (min-width: 480px) {
    #col4 .additional-content .contact-container {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px; }
      #col4 .additional-content .contact-container li {
        flex: 1 0 auto;
        padding: 10px; } }

/*-------------------------------------------------------------------------*/
/* EOF */
/**
 *
 * Icons
 * ==========================================================================
 * Some icons delivered by an iconfont
 *
 *
 */
/*-------------------------------------------------------------------------*/
@font-face {
  font-family: "hzdr-font";
  src: url(../fonts/hzdr-font.eot);
  src: url(../fonts/hzdr-font.eot?#iefix) format("embedded-opentype"), url(../fonts/hzdr-font.woff) format("woff"), url(../fonts/hzdr-font.ttf) format("truetype"), url(../fonts/hzdr-font.svg#hzdr-font) format("svg");
  font-weight: 400;
  font-style: normal; }

[data-icon]:before {
  font-family: "hzdr-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.home section h3:before, [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "hzdr-font" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-hzdr-dates:before {
  content: "\61"; }

.icon-hzdr-facebook:before {
  content: "\62"; }

.icon-hzdr-facilities:before {
  content: "\63"; }

.icon-hzdr-future:before {
  content: "\64"; }

.icon-hzdr-helmholtz:before {
  content: "\65"; }

.icon-hzdr-larry:before {
  content: "\66"; }

.icon-hzdr-lcoations:before {
  content: "\67"; }

.icon-hzdr-media:before {
  content: "\68"; }

.icon-hzdr-network:before {
  content: "\69"; }

.icon-hzdr-news:before {
  content: "\6a"; }

.icon-hzdr-partners:before {
  content: "\6b"; }

.icon-hzdr-publications:before {
  content: "\6c"; }

.icon-hzdr-youtube:before {
  content: "\6e"; }

.icon-hzdr-linkedin:before, .icon-hzdr-mastodon:before {
  font-size: 26px !important;
  font-family: "Font Awesome 5 Brands" !important; }

.icon-hzdr-linkedin:before {
  content: "\f08c"; }

.icon-hzdr-mastodon:before {
  content: "\f4f6"; }

.icon-hzdr-cancer:before {
  content: "\6f"; }

.icon-hzdr-star-blank:before {
  content: "\70"; }

.icon-hzdr-star-filled:before {
  content: "\71"; }

.icon-hzdr-forestfire:before {
  content: "\72"; }

.icon-hzdr-login:before {
  content: "\73"; }

.icon-hzdr-service:before {
  content: "\6d"; }

.icon-hzdr-tools:before {
  content: "\74"; }

.icon-hzdr-helmholtz:before {
  content: "\65"; }

.icon-hzdr-warning-lowlevel:before {
  content: "\75"; }

.icon-hzdr-warning-it:before {
  content: "\76"; }

.icon-hzdr-warning-important:before {
  content: "\77"; }

/*-------------------------------------------------------------------------*/
/* EOF */
/**
 *
 * Forms
 * ==========================================================================
 * Basic styles and overwrites based on YAML
 * for forms and its elements
 *
 *
 */
/*-------------------------------------------------------------------------*/
/* Basic forms settings */
.yform {
  padding: 10px;
  overflow: hidden; }
  .yform fieldset {
    border: 1px #ddd solid;
    background: #fafafa;
    margin: 0 0 1em 0;
    padding: 0.5em;
    display: block; }
  .yform legend {
    font-size: 125%;
    font-weight: normal;
    color: black;
    background: transparent;
    border: 0;
    padding: 0 0.5em; }
  .yform label {
    color: #666;
    display: block;
    cursor: pointer; }
  .yform .message {
    display: block;
    margin-bottom: 0.5em;
    color: #666; }
  .yform sup {
    color: #800;
    font-weight: bold; }
  .yform input, .yform textarea, .yform select {
    border: 1px solid #ddd; }
  .yform div.type-text input:focus, .yform div.type-text input:hover, .yform div.type-text input:active, .yform div textarea:focus, .yform div textarea:hover, .yform div textarea:active, .yform div select:focus, .yform div select:hover, .yform div select:active {
    border: 1px #a66 solid;
    background: white; }
  .yform .type-button input {
    border-top: 1px #ddd solid;
    border-left: 1px #ddd solid;
    border-right: 1px #444 solid;
    border-bottom: 1px #444 solid;
    color: black;
    background: #454545 url(images/button_gray.png) top left repeat-x;
    padding: 5px 1em; }
    .yform .type-button input[type="reset"] {
      color: #300;
      background: #661717 url(images/button_red.png) top left repeat-x; }
      .yform .type-button input[type="reset"]:focus, .yform .type-button input[type="reset"]:hover, .yform .type-button input[type="reset"]:active {
        background: #800;
        color: white; }
    .yform .type-button input[type="submit"] {
      color: #330;
      background: #5e5607 url(images/button_yellow.png) top left repeat-x; }
      .yform .type-button input[type="submit"]:focus, .yform .type-button input[type="submit"]:hover, .yform .type-button input[type="submit"]:active {
        background: #880;
        color: white; }
    .yform .type-button input:focus, .yform .type-button input:hover, .yform .type-button input:active {
      border-top: 1px #444 solid;
      border-left: 1px #444 solid;
      border-right: 1px #ddd solid;
      border-bottom: 1px #ddd solid;
      color: white;
      background: #555; }
  .yform fieldset:after, .yform div.type-text:after, .yform div.type-select:after, .yform div.type-check:after, .yform div.type-button:after {
    clear: both;
    content: " ";
    display: table;
    height: 0;
    width: 100%;
    visibility: hidden;
    position: relative; }
  .yform div.type-text, .yform div.type-select, .yform div.type-check, .yform div.type-button {
    position: relative;
    display: block;
    margin: 0.5em 0;
    padding: 3px 0.5em; }
  .yform div.type-button {
    padding: 3px 0em; }
    .yform div.type-button input {
      cursor: pointer;
      width: auto; }
  .yform div.type-text input, .yform div.type-text textarea {
    position: relative;
    display: block;
    padding: 0.3em 0.3em;
    width: 58.5%; }
  .yform div.type-select select {
    position: relative;
    display: block;
    padding: 0.3em 2px 0.3em 1px;
    width: 60%;
    cursor: pointer; }
    .yform div.type-select select optgroup {
      font-style: normal;
      font-weight: bold; }
  .yform div.type-check input {
    cursor: pointer; }
    .yform div.type-check input:focus, .yform div.type-check input:hover, .yform div.type-check input:active {
      border: 0 none; }
  .yform div.type-check label {
    display: inline; }
  .yform div.error {
    border: 1px #a00 dashed;
    background: #faf4f4;
    padding: 0.5em; }
    .yform div.error label {
      color: black;
      font-weight: bold; }
    .yform div.error .message {
      color: #800; }
  .yform .full div.type-text input, .yform .full div.type-text textarea {
    width: 95.5%;
    margin-right: -3px; }
  .yform .full div.type-select select {
    width: 97.0%;
    margin-right: -3px; }
  .yform .columnar .type-text label, .yform .columnar .type-select label {
    float: left;
    width: 30%; }
  .yform .columnar div.type-check input, .yform .columnar div.error .message {
    margin-left: 30%; }
  .yform .columnar div.type-text input, .yform .columnar div.type-text textarea {
    float: left;
    width: 67.8%;
    margin-right: -3px; }
  .yform .columnar div.type-select select {
    float: left;
    width: 69.4%;
    margin-right: -3px; }
  .yform .columnar fieldset div.type-button, .yform .columnar fieldset.columnar div.type-button {
    padding-left: 30%; }

/*-------------------------------------------------------------------------*/
/* EOF */
/**
 *
 * Print styles
 * ==========================================================================
 * A few styles for website print outs
 *
 *
 */
/*-------------------------------------------------------------------------*/
@media only print {
  @page {
    margin: 1cm 1cm 1cm; }
  body {
    background: white;
    font-size: 9pt;
    line-height: 1.7; }
  #header {
    display: block;
    box-shadow: none;
    border-bottom: 1px solid #ccc;
    height: 3cm;
    margin: 0;
    overflow: hidden; }
  #header .logo1:before {
    position: absolute;
    left: -30px;
    top: 0;
    display: block;
    content: url(../images/headerlogo.png);
    transform: scale(0.6); }
  #header .logo1 a {
    display: none; }
  #header .navigation-toggle {
    display: none; }
  #col4 {
    margin: 0;
    box-shadow: none; }
  #col4_content {
    padding: 0; }
  .home section h3:before {
    font-size: 24px; }
  .home-service, .home-media {
    display: none; }
  .home section.home-sectors {
    position: absolute;
    top: -3.6cm;
    right: .4cm;
    height: auto;
    background: transparent;
    margin: 0; }
  .sector-indicator, .sector-bars, .carousel-control, section.home-sectors .carousel-inner {
    display: none; }
  .home #col4 section.home-sectors ol.sectors-textcloud {
    display: block;
    list-style-type: none; }
  .home #col4 section.home-sectors .sectors-textcloud li {
    font-size: 1.6em;
    background: transparent;
    color: #666;
    padding: 0;
    text-align: right;
    line-height: .9em; }
  .home #col4 section.home-sectors .sectors-textcloud li.energy {
    padding-right: 20px; }
  .home #col4 section.home-sectors .sectors-textcloud li.health {
    padding-right: 40px; }
  .home #col4 section.home-sectors .sectors-textcloud li.matter {
    padding-right: 0; }
  .home-news .teasertext {
    display: block; }
  .home-news .teasertext a.more {
    display: none; }
  .home-publications, .home-futureprojects {
    page-break-before: always;
    margin-top: 130px; }
  .home #col4 section.home-locations .carousel-inner {
    background: transparent; }
  .home #col4 section.home-locations .carousel .item {
    width: auto;
    height: auto;
    float: left;
    display: block; }
  .home #col4 section.home-locations .carousel .item img {
    height: 2.2cm;
    width: auto;
    margin: 0 .2cm; }
  @page {
    margin: 2cm 1cm 1cm; } }

/*-------------------------------------------------------------------------*/
/* EOF */
/*-------------------------------------------------------------------------*/
/* EOF */

/**
 *
 * Modules
 * ==========================================================================
 * Multiple SASS bindings for website modules like header, footer or
 * special pages like the homepage
 *
 *
 */
/**
 *
 * Project variables
 * ==========================================================================
 * Value settings for colors, type, breakpoints and
 * base settings for size calculations
 * 
 *
 *
 */
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/* EOF */
/**
 *
 * Mixins
 * ==========================================================================
 * Own mixins for clearfixes, text removal,
 * gradients and some other minor stuff
 *
 *
 *
 */
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/**
 *
 * Header
 * ==========================================================================
 * All header (the big white bar above every page) related styles
 *
 *
 */
/*-------------------------------------------------------------------------*/
#header {
  position: relative;
  height: 150px;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 10px; }
  #header .logo1 a {
    margin: 25px 0 0 25px;
    width: 160px;
    height: 105px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
    background: url(../images/headerlogo.png) no-repeat 0 0;
    background-size: 100%; }
    @media only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 21), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
      #header .logo1 a {
        background-image: url(../images/headerlogo@2x.png); } }
  #header .menu-label {
    position: absolute;
    top: 102px;
    right: 25px;
    width: 50px;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    color: #003d6d;
    display: block;
    opacity: 0.3;
    line-height: 1.2;
    text-transform: uppercase; }
    #header .menu-label:before {
      position: absolute;
      top: -7px;
      right: 50%;
      margin-right: -4px;
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-bottom-color: #003d6d;
      border-top-width: 0;
      content: " "; }
  @media screen and (min-width: 768px) {
    #header .menu-label {
      display: none; }
    #header .logo1 a {
      margin-left: 20px; } }

/*-------------------------------------------------------------------------*/
/* EOF */
/**
 *
 * Footer
 * ==========================================================================
 * All footer (the big blue bar below every page) related styles
 *
 *
 */
/*-------------------------------------------------------------------------*/
/* Sub footer above the blue main footer */
#sub-footer {
  padding: 0 10px;
  box-sizing: border-box;
  text-align: center; }
  #sub-footer .footer-statistics {
    color: #333;
    opacity: .5;
    font-weight: 700;
    font-size: .8333333336em;
    padding: 0; }
    #sub-footer .footer-statistics:hover {
      opacity: 1; }
    #sub-footer .footer-statistics a {
      color: #333; }
  #sub-footer h3 {
    font-size: 1.4em;
    font-weight: 400;
    line-height: 1.2;
    color: #999;
    margin: 30px 0 0 0; }
  #sub-footer .collaborations ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    #sub-footer .collaborations ul li {
      margin: 10px 0; }
  #sub-footer .collaborations img {
    width: 160px;
    height: auto; }
  #sub-footer .funding img {
    position: relative;
    max-width: 220px;
    height: auto;
    margin: 10px 0; }
  @media screen and (min-width: 768px) {
    #sub-footer {
      padding: 0 60px 0 215px;
      text-align: left; }
      #sub-footer .footer-statistics {
        margin-top: 10px; }
      #sub-footer .collaborations ul {
        display: flex;
        align-items: center; }
        #sub-footer .collaborations ul li {
          position: relative;
          box-sizing: border-box;
          flex: 0 1 auto;
          padding: 0 10px; }
          #sub-footer .collaborations ul li img {
            max-width: 100%;
            height: auto;
            box-sizing: border-box;
            margin: 0; }
      .home #sub-footer {
        padding: 0 25px 0 18px; } }
  @media screen and (min-width: 1500px) {
    #sub-footer h3 {
      margin-top: 50px;
      white-space: nowrap; }
    #sub-footer .main-logo-bar {
      display: flex;
      justify-content: space-between; }
      #sub-footer .main-logo-bar .collaborations {
        flex: 1 1 auto; }
        #sub-footer .main-logo-bar .collaborations ul li {
          flex: 1 1 auto; }
      #sub-footer .main-logo-bar .funding {
        margin-left: 50px; }
        #sub-footer .main-logo-bar .funding img {
          max-width: 180px;
          margin-top: 15px; }
      .home #sub-footer .main-logo-bar {
        padding-right: 30px; } }

/*-------------------------------------------------------------------------*/
/* Main footer */
#footer {
  background: #003d6d;
  margin-top: 30px;
  padding-bottom: 30px; }
  #footer .row {
    position: relative; }
  #footer .membership {
    text-align: center; }
    #footer .membership a {
      position: relative;
      display: inline-block;
      width: 130px;
      height: 80px;
      overflow: hidden;
      white-space: nowrap;
      text-indent: 200%;
      background-size: 130px 80px; }
      #footer .membership a.member-helmholtz {
        background-image: url(../images/footer_hh_de.png); }
        @media only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 21), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
          #footer .membership a.member-helmholtz {
            background-image: url(../images/footer_hh_de@2x.png); } }
        html[lang="en"] #footer .membership a.member-helmholtz {
          background-image: url(../images/footer_hh_en.png); }
          @media only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 21), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
            html[lang="en"] #footer .membership a.member-helmholtz {
              background-image: url(../images/footer_hh_en@2x.png); } }
      #footer .membership a.member-dd-concept {
        background-image: url(../images/footer_ddconcept_de.png); }
        @media only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 21), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
          #footer .membership a.member-dd-concept {
            background-image: url(../images/footer_ddconcept_de@2x.png); } }
        html[lang="en"] #footer .membership a.member-dd-concept {
          background-image: url(../images/footer_ddconcept_en.png); }
          @media only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 21), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
            html[lang="en"] #footer .membership a.member-dd-concept {
              background-image: url(../images/footer_ddconcept_en@2x.png); } }
    #footer .membership br {
      display: none; }
  #footer .adress {
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-width: 1px 0;
    padding: 20px 0;
    text-align: center; }
    #footer .adress a {
      line-height: 24px; }
      #footer .adress a.audit-link {
        position: relative;
        display: inline-block;
        float: none;
        width: 125px;
        height: 30px;
        background: url(../images/audit_beruffamilie.png) no-repeat center center;
        background-size: 125px 30px;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap; }
        #footer .adress a.audit-link img {
          display: none; }
        @media only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 21), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
          #footer .adress a.audit-link {
            background-image: url(../images/audit_beruffamilie@2x.png); } }
    #footer .adress .hlist ul li {
      float: none; }
      #footer .adress .hlist ul li .last {
        display: block;
        float: none;
        text-align: center;
        width: 100%; }
  #footer .footer_content {
    position: relative;
    background: transparent;
    margin: 0 10px;
    padding: 30px 0;
    width: auto; }
    #footer .footer_content .mod_navigation {
      float: none; }
      #footer .footer_content .mod_navigation ul {
        margin-bottom: 16px;
        display: block;
        float: none; }
  #footer .footer-statistics {
    position: absolute;
    top: -75px;
    left: 0;
    color: #333;
    opacity: .5;
    font-weight: 700;
    font-size: .8333333336em;
    padding: 0; }
    #footer .footer-statistics:hover {
      opacity: 1; }
    #footer .footer-statistics a {
      color: #333; }
  #footer .route {
    position: relative;
    min-height: 150px;
    min-width: 210px; }
    #footer .route a.map-link {
      position: relative;
      width: 210px;
      height: 150px;
      opacity: .5;
      background: url(../images/footermap.png) no-repeat center bottom;
      background-size: 210px 150px;
      display: block;
      margin: 0 auto; }
      #footer .route a.map-link:hover {
        opacity: 1; }
  #footer.minifooter {
    padding-bottom: 0; }
    #footer.minifooter .footer_content {
      padding: 5px; }
      #footer.minifooter .footer_content .row, #footer.minifooter .footer_content .mod_navigation ul {
        margin: 0; }
    #footer.minifooter .membership, #footer.minifooter .adress p, #footer.minifooter .route {
      display: none; }
    #footer.minifooter .adress {
      margin: 0;
      padding: 0;
      border: 0 none;
      float: right;
      width: auto; }
    #footer.minifooter .footer-statistics {
      position: relative;
      color: white;
      top: auto;
      left: 0;
      opacity: 1;
      font-size: 12px; }
      #footer.minifooter .footer-statistics a {
        color: white; }
  @media screen and (min-width: 768px) {
    #footer .footer_content {
      padding: 30px 0 0;
      margin: 0; }
    #footer .row {
      margin: 0 55px;
      width: auto; }
    #footer .col {
      width: 33%;
      text-align: left; }
    #footer .footer-statistics {
      top: -60px;
      left: 160px; }
    #footer .membership {
      text-align: left; }
      #footer .membership a.member-dd-concept {
        margin: 20px 20px 0 10px; }
    #footer .adress {
      border: 0 none;
      padding: 0; }
      #footer .adress ul li:nth-child(2) a {
        border-right: 1px solid white; }
      #footer .adress .hlist .last {
        display: inline;
        width: auto; }
      #footer .adress a.audit-link {
        width: 24px;
        height: 24px;
        background-position: left center;
        overflow: hidden;
        text-indent: 100%;
        line-height: 12px;
        margin: 0 0 -8px -2px;
        padding: 0; }
        #footer .adress a.audit-link:hover {
          position: absolute;
          width: 200px; }
    #footer .route {
      margin: 20px 0;
      max-width: none; }
      #footer .route a.map-link {
        margin: 0;
        float: right; } }
  @media screen and (min-width: 1024px) {
    #footer .row {
      margin: 0 55px 0 217px; }
    #footer .footer-statistics {
      left: 0; }
    #footer .membership a.member-dd-concept {
      margin: 8px 0 0; } }
  @media screen and (min-width: 1200px) {
    #footer .footer_content {
      width: 1170px;
      margin: 0 auto; }
      .fullsize #footer .footer_content {
        width: auto; }
    #footer .adress br.mobile-break {
      display: none; }
    #footer .membership a.member-dd-concept {
      margin-left: 30px; } }
  @media screen and (min-width: 1500px) {
    #footer .footer_content {
      width: 1475px; }
    #footer .membership a.member-dd-concept {
      margin-left: 40px; } }

/*-------------------------------------------------------------------------*/
/* EOF */
/**
 *
 * Homepage
 * ==========================================================================
 * All styles for the HZDR homepage
 *
 *
 */
/*-------------------------------------------------------------------------*/
.home #col1, .home #breadcrumb, .home #col4:before {
  display: none; }

.home #col4_content .ce_text {
  overflow: visible; }

.home #col4 section ul {
  margin: 4px 0 -1px; }
  .home #col4 section ul li:before {
    display: none; }

.home section {
  border-bottom: 1px solid #ddebf5;
  margin: 50px 0; }
  .home section .subhead, .home section .date {
    color: #003d6d;
    font-weight: 700;
    display: block;
    margin: 1px 0 -1px;
    font-size: .8571423em; }
  .home section .subhead {
    font-size: .833333339em; }
  .home section .date strong {
    color: #888; }
  .home section img {
    max-width: 101%; }
  .home section a.home-more {
    display: block;
    padding: 6px 0;
    color: #005aa0;
    text-decoration: none;
    font-size: .8em;
    font-weight: 700; }
    .home section a.home-more:after {
      position: relative;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 0 4px 6px;
      border-color: transparent transparent transparent #003d6d;
      content: "";
      display: inline-block;
      margin-left: 4px; }
  .home section.home-news .news-control a.more-news span:after {
    position: relative;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 6px;
    border-color: transparent transparent transparent #f0791d;
    content: "";
    display: inline-block;
    margin-left: 4px; }

.home #col4 section ul.link-list {
  margin: 0;
  min-width: 100%; }
  .home #col4 section ul.link-list li {
    line-height: 24px;
    margin: 0; }
    .home #col4 section ul.link-list li:before {
      display: none; }

.home section h3 {
  position: relative;
  border-bottom: 1px solid #ddebf5;
  color: #003d6d;
  font-size: 22px;
  font-weight: 400;
  padding: 0 0 .3em 1.6em;
  line-height: 1.2em;
  margin: 0; }
  .home section h3 a {
    text-decoration: none;
    margin-left: -4px;
    padding: 2px 4px; }
    .home section h3 a:hover {
      background-color: #e8e8e8;
      color: #005aa0 !important; }
  .home section h3:before {
    position: absolute;
    top: 0;
    left: -4px;
    width: 30px;
    height: 30px;
    font-family: "hzdr-font";
    font-size: 30px;
    content: "e";
    line-height: 1;
    color: #f0791d; }

@media screen and (max-width: 1500px) and (min-width: 768px) {
  .home section.home-blacklivesmatter, .home section.home-userfacilities, .home section.home-publications, .home section.home-locations, .home section.home-network, .home section.home-media {
    height: 350px !important; } }

.home section.home-news h3:before,
.home section.home-inmedia h3:before {
  content: 'j'; }

.home section.home-service h3:before {
  content: 'm'; }

.home section.home-dates h3:before {
  left: -6px;
  content: 'a'; }

.home section.home-publications h3:before {
  left: -2px;
  content: 'l'; }

.home section.home-userfacilities h3:before {
  content: 'c'; }

.home section.home-locations h3:before {
  left: -5px;
  content: 'g'; }

.home section.home-network h3:before {
  content: 'i'; }

.home section.home-media h3:before {
  left: -1px;
  content: 'h'; }

.home section.home-futureprojects h3:before {
  content: 'd'; }

.home section.home-intpartners h3:before {
  left: -2px;
  content: 'k'; }

.home section.home-cancerresearch h3:before {
  left: -3px;
  content: 'o'; }

.home section.home-sectors {
  position: relative;
  margin: -20px -20px 28px;
  height: 200px;
  border: 0 none;
  background: #999;
  padding: 0; }
  .home section.home-sectors h3 {
    display: none; }
  .home section.home-sectors h4, .home section.home-sectors .sectors-textcloud {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 300; }
  .home section.home-sectors .sectors-slider {
    position: relative; }
  .home section.home-sectors .item {
    background: transparent center center no-repeat;
    background-size: cover;
    height: 200px; }
    .home section.home-sectors .item.energy {
      background-image: url(../images/homeheader-energy-small.jpg); }
    .home section.home-sectors .item.health {
      background-image: url(../images/homeheader-health-small.jpg); }
    .home section.home-sectors .item.matter {
      background-image: url(../images/homeheader-matter-small.jpg); }
  .home section.home-sectors .sector-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    transition: all 500ms ease-in-out; }
  .home section.home-sectors .sectors-textcloud {
    display: none; }

.home-sectors .sector-bars {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 5px; }
  .home-sectors .sector-bars div {
    position: relative;
    top: 0;
    float: left;
    height: 5px;
    width: 15%;
    transition: all 1s ease-in; }

.home-sectors.energy .sector-bars div.energy, .home-sectors.health .sector-bars div.health, .home-sectors.matter .sector-bars div.matter {
  width: 70%;
  top: -5px;
  bottom: auto; }

.home-sectors.energy .sector-indicator, .home-sectors .sector-bars div.energy {
  background-color: #ffd228; }

.home-sectors.health .sector-indicator, .home-sectors .sector-bars div.health {
  background-color: #d23264; }

.home-sectors.matter .sector-indicator, .home-sectors .sector-bars div.matter {
  background-color: #f0791d; }

.home .carousel-control {
  position: absolute;
  top: 50%;
  margin-top: -30px;
  width: 30px;
  height: 30px;
  background: no-repeat center center;
  background-size: 13px 25px; }
  .home .carousel-control.left {
    left: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAZCAMAAADdclTDAAAABlBMVEUAPm7///9gMRkKAAAAAXRSTlMAQObYZgAAABtJREFUeNrVxyEBAAAAwjDoX/qWCGduWXXnrwASbwAaolgWmwAAAABJRU5ErkJggg==); }
  .home .carousel-control.right {
    right: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAZCAMAAADdclTDAAAABlBMVEUAPm7///9gMRkKAAAAAXRSTlMAQObYZgAAABpJREFUeNrVxyEBAAAAgzDev/QtFZgbwyKt5BJ7ABqxVtWjAAAAAElFTkSuQmCC); }

.home-sectors:hover .carousel-control, .home-locations:hover .carousel-control, .home-media:hover .carousel-control {
  display: block; }

.home-sectors .carousel-control:hover, .home-locations .carousel-control:hover, .home-media .carousel-control:hover {
  background-color: rgba(91, 91, 91, 0.7); }

.home-sectors .sector-infos {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0 30px; }
  .home-sectors .sector-infos h4 {
    color: white;
    font-size: 1.2em;
    text-shadow: -3px 0 6px rgba(0, 0, 0, 0.4), 3px 0 6px rgba(0, 0, 0, 0.4), 0 -3px 6px rgba(0, 0, 0, 0.4), 0 3px 6px rgba(0, 0, 0, 0.4);
    margin-bottom: 15px; }
    .home-sectors .sector-infos h4 .first-line {
      display: none; }
  .home-sectors .sector-infos .more {
    font-size: .834em;
    border: 1px solid white;
    padding: 6px;
    display: inline-block;
    color: white;
    background: transparent; }

.home-news h4 a {
  font-weight: 400;
  text-decoration: none;
  line-height: 20px;
  font-size: 18px; }

.home #col4 section ul li, .home-news .news-item {
  border-bottom: 1px dotted #ddebf5;
  padding: 10px 0; }

.home #col4_content .home-news .news-item {
  overflow: hidden; }

.home-news .news-item:last-child {
  border-bottom: 0 none; }

.home-news .news-item h4, .home-news .news-item .subhead {
  margin: -2px 0 2px;
  color: #888; }

.home-news .news-item img {
  max-width: 120px;
  height: auto;
  margin: 0 10px 0 0 !important; }

.home-news .news-control, .home-news .teasertext {
  display: none; }

.home #col4 section ul li {
  background: transparent;
  padding: 0; }
  .home #col4 section ul li > a {
    text-decoration: none;
    padding: 12px 30px 12px 0;
    display: block;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAMAAAAGyf7hAAAABlBMVEUAPm5mm8Qiad3yAAAAAXRSTlMAQObYZgAAABtJREFUeNrtyDEBAAAMAiDsX3oBjOA4QZTfyTsb2wAapNOIqQAAAABJRU5ErkJggg==) no-repeat right center;
    background-size: 20px 25px; }
    @media only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 21), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
      .home #col4 section ul li > a {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAyCAMAAAAZbWmiAAAABlBMVEUAPm5mm8Qiad3yAAAAAXRSTlMAQObYZgAAACRJREFUeNrtyjENAAAIA7Dh3zQWSLgg7d1UhkzTvD89z/vy9hrRmwAyq9MlMAAAAABJRU5ErkJggg==); } }

.home section.home-service {
  background: #005aa0;
  margin: 0 -20px;
  padding: 0 25px; }
  .home section.home-service h3 {
    color: white;
    font-size: 1.2em;
    padding: 10px 25px;
    margin: 0 -25px 15px;
    height: auto; }
    .home section.home-service h3:before {
      color: white;
      left: auto;
      right: 15px;
      top: 7px; }

.home #col4 section.home-service ul {
  margin-bottom: 15px;
  margin-left: -4px; }
  .home #col4 section.home-service ul li {
    border: 0 none !important;
    float: left;
    margin-bottom: 5px; }
    .home #col4 section.home-service ul li a {
      color: white;
      padding: 0 4px;
      background: transparent !important;
      border-right: 1px solid white;
      line-height: 1.2em; }
    .home #col4 section.home-service ul li:last-child a {
      border-right: 0 none; }

.home-dates a img.float_left {
  max-height: 60px;
  width: auto;
  margin: 0 15px 0 0 !important; }

.home-dates ul li a:after {
  position: relative;
  display: table;
  clear: both;
  content: ""; }

.home-locations figure {
  position: relative;
  overflow: hidden; }

.home #col4 .carousel-inner {
  background: #444 url(../images/locationbg.jpg) no-repeat center center;
  background-size: cover; }

.home #col4 #locations-slider.carousel .item, .home #col4 #media-slider.carousel .item {
  text-align: center;
  line-height: 10px; }

.home #col4 .carousel .item img {
  border: 1px solid white;
  border-width: 0 1px; }

.home #col4 section.home-locations ol.carousel-indicators, .home #col4 section.home-media ul.media-subnav {
  list-style-type: none;
  text-align: center;
  margin: 10px 0 0; }

.home #col4 section.home-locations ol.carousel-indicators li {
  display: inline-block;
  color: #333;
  font-size: .833333339em;
  margin: 0;
  cursor: pointer; }

.home-media .media-subnav li {
  display: inline-block;
  color: #333;
  font-size: .833333339em;
  margin: 0;
  cursor: pointer; }

.home #col4 section.home-locations ol.carousel-indicators li, .home-media .media-subnav li:before {
  display: none; }

.home #col4 section.home-locations ol.carousel-indicators li:after, .home-media .media-subnav li:after {
  content: "\00a0\2022"; }

.home #col4 section.home-locations ol.carousel-indicators li:last-child::after, .home-media .media-subnav li:last-child::after {
  display: none; }

.home #col4 section.home-locations ol.carousel-indicators li.active {
  color: #003d6d;
  font-weight: 700; }

.home #col4 section.home-locations .carousel {
  position: relative;
  margin: 8px 0 0 -1px; }

.home #col4 section.home-media .carousel {
  position: relative;
  margin: 8px 0 0 -1px; }

.home-media #media-slider .carousel-inner .item {
  text-align: center;
  overflow: hidden; }

.home-media #media-slider .carousel-control {
  margin-top: -15px; }

.home-media #media-slider .carousel-inner .item .item-bg {
  position: absolute;
  top: -30px;
  right: -30px;
  bottom: -30px;
  left: -30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  filter: blur(5px) grayscale(90%); }

.home-locations figcaption, .home #col4 section.home-media .item .media-link {
  position: absolute;
  right: 20px;
  left: 20px;
  bottom: 12px;
  overflow: hidden; }

.home-locations figcaption a {
  border: 1px solid white;
  background: rgba(0, 63, 111, 0.8);
  text-align: center;
  color: white;
  padding: 6px 10px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  font-size: .833339em; }

.home #col4 section.home-media .item .media-link a {
  border: 1px solid white;
  background: rgba(0, 63, 111, 0.8);
  text-align: center;
  color: white;
  padding: 6px 10px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  font-size: .833339em; }

.home #col4 section.home-media .media-subnav li {
  border: 0 none;
  line-height: 1.5em;
  padding-top: 2px;
  margin-bottom: 10px; }
  .home #col4 section.home-media .media-subnav li a {
    padding: 0;
    background: transparent;
    display: inline;
    border: 0 none; }

.home-cancerresearch p {
  margin: 20px 0; }

.home #col4_content section.home-cancerresearch img {
  max-width: 30%;
  margin: 10px 0 20px; }

.home-cancerresearch img, .home-intpartners img {
  opacity: .4; }

@media screen and (min-width: 768px) {
  .home #col4 {
    margin: 0 25px 0 18px; }
  .home section a.home-more {
    padding: 6px; }
  .home #col4 section ul.link-list li:hover {
    background-color: #e8e8e8; }
    .home #col4 section ul.link-list li:hover a {
      color: #005aa0; }
  .home section {
    position: relative;
    float: left;
    width: 50%;
    height: 300px;
    padding: 0 10px;
    box-sizing: border-box;
    margin: 30px 0;
    border-bottom: 0 none;
    overflow: hidden; }
    .home section a.home-more:hover {
      background-color: #e8e8e8; }
    .home section:after {
      position: absolute;
      bottom: 0;
      left: 10px;
      right: 0;
      height: 1px;
      background: #ddebf5;
      content: ""; }
    .home section.home-sectors {
      float: none;
      width: auto;
      overflow: visible; }
    .home section h3 {
      height: 1.588em;
      line-height: 1.38888889em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 0 0 1.6em; }
  .home-sectors .sector-indicator {
    top: -3px;
    left: -3px;
    width: 30px;
    height: 30px;
    background: white; }
  .home-sectors .sector-infos h4 {
    font-size: 1.4em; }
  .home-sectors .sector-bars {
    bottom: -10px;
    height: 10px; }
    .home-sectors .sector-bars div {
      height: 10px; }
  .home-sectors.energy .sector-bars div.energy, .home-sectors.health .sector-bars div.health, .home-sectors.matter .sector-bars div.matter {
    top: -10px; }
  .home section.home-sectors .sectors-textcloud {
    position: absolute;
    bottom: 25px;
    right: 20px;
    display: block;
    text-transform: lowercase;
    list-style-type: none !important; }
  .home section.home-sectors .sector-infos {
    padding: 0 45% 30px 60px;
    width: 50%; }
  .home-sectors .sector-infos .more {
    position: absolute; }
    .home-sectors .sector-infos .more:hover {
      background: white;
      color: #005aa0; }
  .home #col4 section.home-sectors .sectors-textcloud li {
    font-size: 1.4em;
    background: transparent;
    color: white;
    opacity: .4;
    padding: 0;
    text-align: right;
    line-height: .9em;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    text-shadow: -3px 0 6px rgba(0, 0, 0, 0.4), 3px 0 6px rgba(0, 0, 0, 0.4), 0 -3px 6px rgba(0, 0, 0, 0.4), 0 3px 6px rgba(0, 0, 0, 0.4); }
    .home #col4 section.home-sectors .sectors-textcloud li.energy {
      padding-right: 20px; }
    .home #col4 section.home-sectors .sectors-textcloud li.health {
      padding-right: 40px; }
    .home #col4 section.home-sectors .sectors-textcloud li.matter {
      padding-right: 0; }
    .home #col4 section.home-sectors .sectors-textcloud li.active {
      font-size: 2.4em;
      opacity: 1;
      margin-bottom: .1em; }
  .home section.home-news .news-item {
    position: absolute;
    top: 50px;
    right: 0;
    left: 10px;
    height: 133px;
    padding-left: 220px;
    visibility: hidden;
    opacity: 0;
    transform: scale(0.5);
    transition: all 200ms ease-in-out;
    border: 0 none; }
    .home section.home-news .news-item.active {
      visibility: visible;
      opacity: 1;
      transform: scale(1); }
  .home section.home-news .news-item h4 {
    margin-bottom: 6px;
    font-size: 1.1em; }
  .home section.home-news .news-item .teasertext {
    display: block;
    font-size: 14px; }
    .home section.home-news .news-item .teasertext p {
      display: inline;
      line-height: 20px; }
    .home section.home-news .news-item .teasertext a.more {
      display: inline;
      font-weight: 700;
      line-height: 20px; }
  .home section.home-news .news-item img {
    position: absolute;
    top: 10px;
    left: 0;
    width: 200px;
    height: 133px;
    max-width: none; }
  .home section.home-news .news-control {
    position: absolute;
    bottom: 1px;
    left: 10px;
    right: 0;
    display: block; }
    .home section.home-news .news-control a {
      position: relative;
      border: 1px dotted #ddebf5;
      border-width: 1px 1px 0 0;
      display: block;
      height: 50px;
      width: 32%;
      float: left;
      white-space: nowrap;
      font-size: 0;
      padding: 3px 6px;
      box-sizing: border-box;
      text-align: center;
      line-height: 1.3; }
      .home section.home-news .news-control a:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -.25em; }
      .home section.home-news .news-control a span {
        font-size: 12px;
        display: inline-block;
        vertical-align: middle;
        white-space: normal; }
      .home section.home-news .news-control a.active {
        color: #f0791d;
        border-top: 1px dotted transparent; }
        .home section.home-news .news-control a.active:after {
          position: absolute;
          left: 50%;
          top: -5px;
          margin-left: -5px;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-color: transparent transparent #f0791d;
          border-top-width: 0;
          content: ""; }
      .home section.home-news .news-control a.more-news {
        border-width: 1px 0 0;
        font-weight: 700;
        width: 4%;
        overflow: hidden;
        text-indent: 200%; }
        .home section.home-news .news-control a.more-news span:after {
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -7px 0 0 -4px;
          text-indent: 0;
          border-width: 7px 0 7px 10px; }
        .home section.home-news .news-control a.more-news:hover {
          background: #e8e8e8; }
  .home section.home-service {
    width: 25%;
    height: 266px;
    float: right;
    margin-top: 62px;
    padding: 0 15px; }
    .home section.home-service:after {
      display: none; }
  .home section.home-sectors:after {
    display: none; }
  .home #col4 section.home-service h3 {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 700;
    line-height: 1.4; }
  .home #col4 section.home-service ul {
    display: table-cell;
    vertical-align: middle;
    height: 160px; }
    .home #col4 section.home-service ul li {
      float: none;
      margin: 8px 0;
      font-size: 1em;
      margin: 0 0 5px;
      height: auto; }
      .home #col4 section.home-service ul li:before {
        display: none; }
      .home #col4 section.home-service ul li a {
        border: 0 none;
        font-size: 14px;
        padding: 1px 0; }
        .home #col4 section.home-service ul li a:hover {
          text-decoration: underline; }
  .home section.home-news {
    width: 75%;
    overflow: hidden;
    padding-right: 0; }
  .home section.home-locations #locations-slider {
    position: relative;
    height: 220px;
    margin-top: 10px; }
  .home section.home-locations .carousel-inner {
    height: 180px; }
  .home-intpartners a:hover img, .home-cancerresearch a:hover img {
    opacity: 1; }
  .home-intpartners.row-count-2 li {
    height: 111px; }
    .home-intpartners.row-count-2 li img {
      height: 65px;
      width: auto;
      margin-top: 16px !important; }
  .home-intpartners.row-count-3 li {
    height: 74px; }
    .home-intpartners.row-count-3 li img {
      height: 52px;
      width: auto; }
  .home-intpartners.row-count-4 li {
    height: 55px; }
    .home-intpartners.row-count-4 li img {
      height: 32px;
      width: auto; }
  .home #col4_content section.home-cancerresearch p {
    font-size: .83333339em;
    line-height: 1.4;
    margin: 10px 0; }
    .home #col4_content section.home-cancerresearch p a.more {
      display: inline;
      margin-left: 3px;
      font-weight: 700; }
  .home-media #media-slider {
    position: relative;
    height: 180px;
    margin-top: 10px; }
  .home-locations .carousel-inner figure {
    height: 180px; }
  .home-media #media-slider .carousel-inner {
    height: 180px; }
    .home-media #media-slider .carousel-inner .item {
      height: 180px; }
  .home-locations figcaption {
    background: transparent; }
    .home-locations figcaption a:hover {
      background: white;
      color: #003d6d; }
  .home #col4 section.home-media .item .media-link a:hover {
    background: white;
    color: #003d6d; }
  .home section a.home-more {
    padding: 5px 6px; }
  .home #col1 .mod_navigation > p, .home #col1_content > p {
    border-bottom: 1px dotted white;
    color: white; }
  .home #col1 p a {
    color: white !important; }
  .home #footer .footer-statistics {
    left: -30px; }
  .home #footer .adress a.audit-link {
    width: 150px; } }

@media screen and (min-width: 1024px) {
  .home.cms-edit #col1 {
    position: fixed;
    left: -235px;
    top: 120px;
    width: 225px;
    display: block;
    background: #333;
    background: rgba(51, 51, 51, 0.8);
    padding: 5px;
    z-index: 1100; }
    .home.cms-edit #col1.slide-in {
      left: 0; }
    .home.cms-edit #col1 .col1_content {
      height: 500px;
      overflow: scroll;
      overflow-y: auto; }
    .home.cms-edit #col1 .sidebar-toggle {
      display: block;
      cursor: pointer; }
      .home.cms-edit #col1 .sidebar-toggle .sidebar-icon {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYCAQAAABQQhX9AAAA9klEQVQoz82SIQ7CQBBFJ02KI2gMqpZA0nuQVFUgKnA9SZEcYhNuwAlWtG65AQk0DRZX8xDdlLJbBIKElTMvf3b+fJGfPQISFDUtLTWKhGAMizFoMuYiIszJ0BhiF9vQkDq1kJSGzbtaw8rBCq5ErGh6VQKMp3bgRs6FiBRj/0qC9tQqTihyroRokq6syBysZMaEEzcKETJU16i7TYeYCDMqDtaBumu1o1hJ0VdbB2TP2cdeoB3NlAd3lh7Wj7bLsOPIljvnITZcxtqDpuDIg71jVm9PgCFlAWh2TB3sZbg94ZrFSFSGJ/wUChEvFF/E7Ivg/vV7AouG/mg5mQrbAAAAAElFTkSuQmCC);
        width: 30px; }
    .home.cms-edit #col1 .mod_navigation {
      width: 225px; }
  .home #col4 {
    margin: 0 55px 0 18px; }
  .home section.home-news {
    width: 81%; }
  .home section.home-service {
    width: 19%; }
  .home section.home-sectors .item.energy {
    background-image: url(../images/homeheader-energy.jpg); }
  .home section.home-sectors .item.health {
    background-image: url(../images/homeheader-health.jpg); }
  .home section.home-sectors .item.matter {
    background-image: url(../images/homeheader-matter.jpg); }
  .home section.home-news .news-item h4 {
    margin-bottom: 16px; }
  .home #col4_content section.home-news .news-control {
    display: block; }
  .home-cancerresearch p {
    margin: 20px 0; }
  .home #footer .footer_content .row {
    margin: 0 55px 0 15px; }
  .home #footer .footer-statistics {
    left: 3px; } }

@media screen and (min-width: 1200px) {
  .home section {
    width: 33%; }
    .home section.home-sectors {
      height: 300px; }
      .home section.home-sectors #sectors-slider, .home section.home-sectors .item {
        height: 300px; }
      .home section.home-sectors .sector-infos h4 {
        font-size: 1.8em; }
      .home section.home-sectors .sectors-textcloud {
        right: 40px;
        font-size: 1.3em; }
      .home section.home-sectors .carousel-inner {
        height: 300px; }
        .home section.home-sectors .carousel-inner:after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 30%;
          content: " ";
          background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.25) 100%);
          background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.25) 100%);
          background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.25) 100%);
          background-repeat: repeat-x; }
    .home section.home-news {
      height: 280px; }
      .home section.home-news .news-control a {
        width: 28%; }
        .home section.home-news .news-control a.more-news {
          width: 16%;
          text-indent: 0;
          white-space: normal;
          padding: 0 15px; }
          .home section.home-news .news-control a.more-news span:after {
            left: auto;
            right: 0;
            border-width: 5px 0 5px 7px; }
      .home section.home-news .news-item h4 {
        margin: 5px 0 10px; }
      .home section.home-news .news-image-container {
        border: 1px dotted #ddebf5;
        border-width: 1px 0;
        padding: 15px 0; }
    .home section.home-service {
      height: 248px; }
      .home section.home-service h3 {
        padding: 13px 25px 6px; }
        .home section.home-service h3:before {
          top: 7px; }
  .home #col4 section.home-service ul {
    height: 150px; }
    .home #col4 section.home-service ul li {
      margin-bottom: 3px; } }

/*-------------------------------------------------------------------------*/
/* Carousel Settings */
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner > .item {
    position: relative;
    display: none;
    transition: .6s ease-in-out left; }
  .carousel-inner > img,
  .carousel-inner > a > img {
    line-height: 1; }
  @media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-inner > .item {
      transition: transform .6s ease-in-out;
      backface-visibility: hidden;
      perspective: 1000px; }
    .carousel-inner > .item.next,
    .carousel-inner .item.active.right {
      left: 0;
      transform: translate3d(100%, 0, 0); }
    .carousel-inner > .item.prev,
    .carousel-inner .item.active.left {
      left: 0;
      transform: translate3d(-100%, 0, 0); }
    .carousel-inner > .item.next.left,
    .carousel-inner .item.prev.right,
    .carousel-inner .item.active {
      left: 0;
      transform: translate3d(0, 0, 0); } }
  .carousel-inner > .active,
  .carousel-inner > .next,
  .carousel-inner > .prev {
    display: block; }
  .carousel-inner > .active {
    left: 0; }
  .carousel-inner > .next,
  .carousel-inner > .prev {
    position: absolute;
    top: 0;
    width: 100%; }
  .carousel-inner > .next,
  .carousel-inner > .active.right {
    left: 100%; }
  .carousel-inner > .prev,
  .carousel-inner > .active.left {
    left: -100%; }
  .carousel-inner > .next.left,
  .carousel-inner > .prev.right {
    left: 0; }

/*-------------------------------------------------------------------------*/
/* EOF */
/*-------------------------------------------------------------------------*/
/* EOF */

/**
 *
 * Intranet
 * ==========================================================================
 * These styles are all related to the intranet home and subpages
 *
 *
 */
/**
 *
 * Project variables
 * ==========================================================================
 * Value settings for colors, type, breakpoints and
 * base settings for size calculations
 * 
 *
 *
 */
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/* EOF */
/**
 *
 * Mixins
 * ==========================================================================
 * Own mixins for clearfixes, text removal,
 * gradients and some other minor stuff
 *
 *
 *
 */
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/* Basic setup for intranet elements and overwrite for navigation */
.intranet a.locked {
  position: relative; }
  .intranet a.locked:after {
    position: relative;
    width: 12px;
    height: 12px;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiI+PHBhdGggZD0iTTEwLjcgNC4xVjIuN0MxMC43IDEuMiA5LjUgMCA4IDBTNS4zIDEuMiA1LjMgMi43djEuNUM0LjYgNC40IDQgNS4xIDQgNnYyLjdjMCAxLjEuOSAyIDIgMmg0YzEuMSAwIDItLjkgMi0yVjZjMC0uOS0uNi0xLjYtMS4zLTEuOXpNOCA4Yy0uNSAwLS45LS40LS45LS45cy40LS45LjktLjkuOS40LjkuOS0uNC45LS45Ljl6bTIuMi00SDUuOFYyLjdDNS44IDEuNSA2LjguNSA4IC41czIuMiAxIDIuMiAyLjJWNHoiIG9wYWNpdHk9Ii4yNSIvPjwvc3ZnPg==) no-repeat center center;
    background-size: auto 12px;
    content: " ";
    display: inline-block;
    vertical-align: middle; }

.intranet #header .logo1 a {
  margin-top: 15px; }

.intranet #main {
  overflow: hidden;
  padding-bottom: 5px; }

.intranet #breadcrumb {
  display: block; }

.intranet #navigation .col3 .hlist > ul > li:first-child a {
  padding-right: 40px; }
  .intranet #navigation .col3 .hlist > ul > li:first-child a .intranet-home-icon {
    position: absolute;
    top: 0;
    right: 5px;
    width: 34px;
    height: 34px;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PGcgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDA1YWEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCI+PHBhdGggZD0iTTQuNzIgMTMuOTM1djcuMDM2aDUuMDQxdi00Ljg3NWg0LjY0MXY0Ljg3NWg0LjkwNHYtNy4wMzYiLz48cGF0aCBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNMy4wMjYgMTIuOTM1bDguOTg3LTguOTg3TDIxIDEyLjkzNSIvPjxwYXRoIGQ9Ik0xNS41MjMgMy4xMzJoMy42MDF2My42MDEiLz48L2c+PC9zdmc+) no-repeat center center;
    background-size: 24px;
    content: " "; }
  .intranet #navigation .col3 .hlist > ul > li:first-child a.active .intranet-home-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PGcgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRjA3ODFFIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCI+PHBhdGggZD0iTTQuNzIgMTMuOTM1djcuMDM2aDUuMDQxdi00Ljg3NWg0LjY0MXY0Ljg3NWg0LjkwNHYtNy4wMzYiLz48cGF0aCBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNMy4wMjYgMTIuOTM1bDguOTg3LTguOTg3TDIxIDEyLjkzNSIvPjxwYXRoIGQ9Ik0xNS41MjMgMy4xMzJoMy42MDF2My42MDEiLz48L2c+PC9zdmc+); }

@media screen and (min-width: 480px) {
  .intranet #header .logo1 a {
    margin-top: 25px; }
  .intranet #breadcrumb .mod_navigation {
    padding-right: 220px; } }

@media screen and (min-width: 768px) {
  .intranet #navigation .col3 .hlist ul li:first-child a {
    padding-right: 10px; }
    .intranet #navigation .col3 .hlist ul li:first-child a .intranet-home-icon {
      position: relative;
      top: -4px;
      right: auto;
      margin: -12px 0;
      display: inline-block;
      vertical-align: middle; } }

/**
 *
 * The intranet toolbar
 * ==========================================================================
 * Setup for the blue icon bar pinned to the right border
 * of intranet pages
 *
 */
/**
 *
 * Project variables
 * ==========================================================================
 * Value settings for colors, type, breakpoints and
 * base settings for size calculations
 * 
 *
 *
 */
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/* EOF */
/**
 *
 * Mixins
 * ==========================================================================
 * Own mixins for clearfixes, text removal,
 * gradients and some other minor stuff
 *
 *
 *
 */
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
.intranet .intranet-toolbar {
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.25s ease-in-out;
  transform: translateX(100%);
  z-index: 99; }
  .intranet .intranet-toolbar.slide-in {
    transform: translateX(0); }
    .intranet .intranet-toolbar.slide-in ul {
      box-shadow: -1px 2px 6px rgba(0, 0, 0, 0.15); }
  .intranet .intranet-toolbar button {
    position: absolute;
    top: 0;
    left: -40px;
    width: 40px;
    height: 40px;
    background: rgba(0, 90, 160, 0.95) url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTE3LjIgOC43bC0xLjgtLjkuMS0yLjMgNC0yLjNjLS4yLS4yLS42LS42LS44LS43LTEuNy0uNy0zLjYtLjMtNC45IDEtMS4yIDEuMi0xLjYgMy0xLjEgNC42TDMgMThzLTEuMiAxLjEuMiAyLjVjMS41IDEuNSAzLS4xIDMtLjFsOS4zLTkuNGMxLjYuNSAzLjQuMSA0LjYtMS4xIDEuMS0uOSAxLjUtMi4zIDEuMy0zLjZsLTQuMiAyLjR6IiBmaWxsPSIjZmZmIi8+PC9zdmc+) no-repeat center center;
    border: 0 none;
    outline: none;
    cursor: pointer; }
  .intranet .intranet-toolbar ul {
    position: relative;
    background: rgba(0, 90, 160, 0.95);
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);
    list-style: none;
    margin: 0;
    padding: 0; }
    .intranet .intranet-toolbar ul li {
      padding: 7px 10px !important;
      margin: 0 !important;
      border-bottom: 1px dotted rgba(255, 255, 255, 0.5); }
      .intranet .intranet-toolbar ul li:before {
        display: none;
        content: none; }
      .intranet .intranet-toolbar ul li:last-child {
        border-bottom: 0 none; }
      .intranet .intranet-toolbar ul li a {
        position: relative;
        padding: 5px 10px 5px 30px;
        color: white;
        text-decoration: none;
        display: block; }
        .intranet .intranet-toolbar ul li a img {
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -10px;
          height: 20px;
          width: 20px; }
        .intranet .intranet-toolbar ul li a .entry-count, .intranet .intranet-toolbar ul li a .badge {
          position: relative;
          background: white;
          color: #005aa0;
          display: inline-block;
          text-align: center;
          vertical-align: bottom;
          font-size: 70%;
          line-height: 1;
          white-space: nowrap;
          border-radius: .25rem;
          padding: .25em .4em;
          margin-left: .4rem; }
  @media screen and (min-width: 768px) {
    .intranet .intranet-toolbar button {
      top: 0; } }
  @media screen and (min-width: 1024px) {
    .intranet .intranet-toolbar {
      top: 0;
      right: 15px; }
      .intranet .intranet-toolbar button {
        display: none; }
      .intranet .intranet-toolbar ul {
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        opacity: 1; }
        .intranet .intranet-toolbar ul li {
          padding: 0  !important; }
          .intranet .intranet-toolbar ul li a {
            padding: 10px;
            text-indent: 40px;
            overflow: hidden;
            display: block;
            width: 40px;
            box-sizing: border-box;
            transition: all 0.25s ease-in-out;
            color: rgba(255, 255, 255, 0);
            white-space: nowrap; }
            .intranet .intranet-toolbar ul li a img {
              left: auto;
              right: 10px; }
            .intranet .intranet-toolbar ul li a:hover {
              background: rgba(255, 255, 255, 0.2);
              color: white; }
      .no-touchevents .intranet .intranet-toolbar:hover ul li a {
        padding: 10px 40px 10px 10px;
        text-indent: 0;
        width: 240px;
        color: white !important; }
      .touchevents-enabled .intranet .intranet-toolbar button {
        display: block; }
      .touchevents-enabled .intranet .intranet-toolbar ul {
        right: 40px;
        opacity: 0;
        pointer-events: none;
        transform: scale(0) translateX(100%); }
        .touchevents-enabled .intranet .intranet-toolbar ul li a {
          padding: 10px 40px 10px 10px;
          width: auto;
          color: white;
          text-indent: 0; }
      .touchevents-enabled .intranet .intranet-toolbar.slide-in ul {
        opacity: 1;
        transform: scale(1) translateX(0);
        pointer-events: all; } }

/*-------------------------------------------------------------------------*/
/**
 *
 * Intranet Indicator
 * ==========================================================================
 * Setup for intranet indicator 
 * (which is also shown on websites if user is logged in)
 *
 */
/**
 *
 * Project variables
 * ==========================================================================
 * Value settings for colors, type, breakpoints and
 * base settings for size calculations
 * 
 *
 *
 */
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/* EOF */
/**
 *
 * Mixins
 * ==========================================================================
 * Own mixins for clearfixes, text removal,
 * gradients and some other minor stuff
 *
 *
 *
 */
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
.intranet-indicator {
  display: none; }
  .logged-in .intranet-indicator {
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    border-top: 2px solid #f0791d;
    display: block; }
    .logged-in .intranet-indicator .intranet-indicator-wrapper {
      position: relative;
      margin: 0 25px; }
    .logged-in .intranet-indicator .location-select {
      position: absolute;
      bottom: 15px;
      left: 0;
      font-weight: bold; }
      .logged-in .intranet-indicator .location-select label {
        color: #d6650e;
        font-size: 12px;
        font-weight: bold; }
      .logged-in .intranet-indicator .location-select i.dropdown-icon {
        position: absolute;
        top: 50%;
        right: 7px;
        margin-top: -2px;
        width: 0;
        height: 0;
        border: 4px solid transparent;
        border-bottom-width: 0;
        border-top-color: #999; }
      .logged-in .intranet-indicator .location-select select {
        padding: 4px 18px 4px 6px;
        background: white;
        border-radius: 0;
        border: 1px solid #ddd;
        border-bottom-width: 2px;
        color: #003d6d;
        padding-right: 15px;
        appearance: none;
        font-size: 12px; }
    @media screen and (min-width: 480px) {
      .logged-in .intranet-indicator .location-select {
        bottom: -50px;
        right: 30px;
        left: auto;
        z-index: 30; } }
    @media screen and (min-width: 768px) {
      .logged-in .intranet-indicator {
        z-index: 99; }
        .logged-in .intranet-indicator .intranet-indicator-wrapper {
          margin: 0; }
        .logged-in .intranet-indicator .location-select {
          bottom: -43px;
          right: 50px; } }
    @media screen and (min-width: 1024px) {
      .logged-in .intranet-indicator .intranet-indicator-wrapper:before {
        right: 76px; }
      .logged-in .intranet-indicator .location-select {
        right: 65px; } }

/*-------------------------------------------------------------------------*/
/**
 *
 * Intranet Homepage
 * ==========================================================================
 * All special styles for the intranet areas homepage
 *
 *
 */
/**
 *
 * Project variables
 * ==========================================================================
 * Value settings for colors, type, breakpoints and
 * base settings for size calculations
 * 
 *
 *
 */
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/* EOF */
/**
 *
 * Mixins
 * ==========================================================================
 * Own mixins for clearfixes, text removal,
 * gradients and some other minor stuff
 *
 *
 *
 */
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------*/
/* Basic intranet homepage settings */
.intranet.home #col4 section {
  border: 0 none;
  font-size: 16px;
  line-height: 24px; }
  .intranet.home #col4 section:first-child {
    margin-top: 20px; }
  .intranet.home #col4 section .section-head {
    border-bottom: 2px solid #005aa0;
    padding-bottom: 10px; }
    .intranet.home #col4 section .section-head h3 {
      border-bottom: 0 none; }
    .intranet.home #col4 section .section-head .addition-links {
      display: none; }
  .intranet.home #col4 section ul {
    margin: 8px 0 0 0; }
    .intranet.home #col4 section ul li {
      border-bottom: 1px solid #ddebf5; }
  .intranet.home #col4 section a {
    color: #005aa0; }
    .intranet.home #col4 section a.button {
      border: 1px solid #d6650e;
      color: #d6650e;
      margin: 20px 0;
      padding: 10px;
      display: inline-block;
      text-decoration: none; }
    .intranet.home #col4 section a.intranet-home-more {
      background: transparent;
      padding: 0;
      margin: 10px 0;
      font-weight: bold;
      text-decoration: none; }
      .intranet.home #col4 section a.intranet-home-more:after {
        position: relative;
        width: 0;
        height: 0;
        border: 4px solid transparent;
        border-left: 6px solid #f0791d;
        border-right-width: 0;
        content: " ";
        display: inline-block;
        margin: 0 0 0 5px;
        top: -1px; }
    .intranet.home #col4 section a:hover {
      color: #f0791d; }

.intranet.home #col4_content > .ce_text {
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  .intranet.home #col4_content > .ce_text .intranet-home-col-1 {
    order: 2; }
  .intranet.home #col4_content > .ce_text .intranet-home-col-2 {
    order: 1; }
  .intranet.home #col4_content > .ce_text .intranet-home-col-3 {
    order: 3;
    min-width: 100%; }

@media screen and (min-width: 768px) {
  .intranet.home #col4 section {
    float: none;
    height: auto;
    width: auto;
    margin: 20px 0;
    flex: 0 0 100%; }
    .intranet.home #col4 section:after {
      background: transparent; } }

@media screen and (min-width: 1024px) {
  .intranet.home #col4 section {
    margin: 40px 0 0 0; }
    .intranet.home #col4 section:first-child {
      margin-top: 4px; }
    .intranet.home #col4 section .section-head {
      padding-bottom: 8px; }
  .intranet.home #col4 #col4_content > .ce_text {
    margin: 0 -10px; }
    .intranet.home #col4 #col4_content > .ce_text .intranet-home-col-1 {
      flex: 1 0 auto;
      width: 25%;
      order: 1; }
    .intranet.home #col4 #col4_content > .ce_text .intranet-home-col-2 {
      flex: 2 0 auto;
      width: 50%;
      order: 2; }
    .intranet.home #col4 #col4_content > .ce_text .intranet-home-col-3 {
      flex: 1 0 auto;
      width: 25%;
      min-width: inherit; } }

@media screen and (min-width: 1200px) {
  .intranet.home #col4 section .section-head {
    text-align: left; }
    .intranet.home #col4 section .section-head h3 {
      display: inline-block;
      vertical-align: middle; }
    .intranet.home #col4 section .section-head ul.addition-links {
      position: relative;
      display: inline-block;
      margin: 0;
      vertical-align: middle; }
      .intranet.home #col4 section .section-head ul.addition-links li {
        display: inline-block;
        border: 0 none;
        border-left: 1px solid #ddd;
        padding-left: 5px;
        margin: 0; }
        .intranet.home #col4 section .section-head ul.addition-links li a {
          position: relative;
          padding: 0 10px 0 0 !important;
          background: transparent;
          font-size: 12px;
          font-weight: bold; }
          .intranet.home #col4 section .section-head ul.addition-links li a:after {
            position: absolute;
            top: 50%;
            right: 1px;
            margin-top: -2px;
            width: 0;
            height: 0;
            border: 3px solid transparent;
            border-top-color: #d6650e;
            border-bottom-width: 0;
            content: " "; }
          .intranet.home #col4 section .section-head ul.addition-links li a.add-news:after {
            display: none;
            content: none; }
          .intranet.home #col4 section .section-head ul.addition-links li a:hover {
            color: #f0791d; }
        .intranet.home #col4 section .section-head ul.addition-links li:first-child {
          padding-left: 0;
          border-left: 0 none; } }

/*-------------------------------------------------------------------------*/
/* Intranet homepage events area */
.intranet.home #col4 section.intranet-home-events h3:before {
  content: "a"; }

.intranet.home #col4 section.intranet-home-events ul li {
  margin: 0; }
  .intranet.home #col4 section.intranet-home-events ul li a {
    color: #005aa0; }
    .intranet.home #col4 section.intranet-home-events ul li a .date {
      color: #555; }
    .intranet.home #col4 section.intranet-home-events ul li a .title {
      font-weight: bold;
      font-size: 16px; }
  .intranet.home #col4 section.intranet-home-events ul li:hover {
    background-color: #e8e8e8; }

/*-------------------------------------------------------------------------*/
/* Intranet homepage new coleagues area */
.intranet.home #col4 section.intranet-home-colleagues h3:before {
  content: "k";
  left: -2px; }

.intranet.home #col4 section.intranet-home-colleagues ul li {
  padding: 10px 0; }
  .intranet.home #col4 section.intranet-home-colleagues ul li a {
    background: transparent; }
  .intranet.home #col4 section.intranet-home-colleagues ul li figure {
    display: flex;
    align-items: center;
    max-width: none; }
    .intranet.home #col4 section.intranet-home-colleagues ul li figure > a {
      margin-right: 15px;
      flex: 0 0 30%; }
    .intranet.home #col4 section.intranet-home-colleagues ul li figure figcaption {
      background: transparent;
      color: black;
      padding: 0; }
      .intranet.home #col4 section.intranet-home-colleagues ul li figure figcaption h4 {
        margin: 0; }
        .intranet.home #col4 section.intranet-home-colleagues ul li figure figcaption h4 a {
          padding: 0;
          font-weight: bold;
          text-decoration: none;
          font-size: 16px;
          color: #005aa0; }
      .intranet.home #col4 section.intranet-home-colleagues ul li figure figcaption p.desc {
        font-size: 12px;
        margin: 10px 0; }
      .intranet.home #col4 section.intranet-home-colleagues ul li figure figcaption a.intranet-home-more {
        margin: 0; }

@media screen and (min-width: 480px) {
  .intranet.home #col4 section.intranet-home-colleagues ul {
    display: flex;
    margin: 5px -10px; }
    .intranet.home #col4 section.intranet-home-colleagues ul li {
      padding: 10px; } }

@media screen and (min-width: 1024px) {
  .intranet.home #col4 section.intranet-home-colleagues ul {
    flex-wrap: wrap;
    margin: 0; }
    .intranet.home #col4 section.intranet-home-colleagues ul li {
      padding: 30px 0; }
      .intranet.home #col4 section.intranet-home-colleagues ul li figure {
        align-items: flex-start; }
        .intranet.home #col4 section.intranet-home-colleagues ul li figure figcaption p.desc {
          font-size: 16px;
          margin: 0; } }

@media screen and (min-width: 1200px) {
  .intranet.home #col4 section.intranet-home-colleagues ul li:first-child {
    padding-top: 7px; } }

/*-------------------------------------------------------------------------*/
/* Intranet homepage news area */
.intranet.home #col4 section.intranet-home-news h3:before {
  content: "j"; }

.intranet.home #col4 section.intranet-home-news ul.news-list {
  position: relative;
  width: 100%; }
  .intranet.home #col4 section.intranet-home-news ul.news-list li {
    border-bottom: 0 none;
    margin: 0; }
    .intranet.home #col4 section.intranet-home-news ul.news-list li .news-item {
      padding: 10px 0;
      border-bottom: 1px solid #ddebf5; }
      .intranet.home #col4 section.intranet-home-news ul.news-list li .news-item .news-metadata {
        margin-bottom: 10px;
        font-size: 12px;
        color: #555; }
        .intranet.home #col4 section.intranet-home-news ul.news-list li .news-item .news-metadata .date {
          font-weight: bold;
          display: inline;
          font-size: 12px;
          border-right: 1px solid #555;
          padding-right: 5px;
          margin-right: 2px;
          color: #555; }
        .intranet.home #col4 section.intranet-home-news ul.news-list li .news-item .news-metadata .category {
          background: #e9f2f9;
          padding: 2px 5px;
          border-radius: 4px;
          text-decoration: none; }
      .intranet.home #col4 section.intranet-home-news ul.news-list li .news-item h4 a {
        font-size: 16px;
        text-decoration: none;
        font-weight: bold; }
      .intranet.home #col4 section.intranet-home-news ul.news-list li .news-item p {
        margin: 10px 0;
        word-wrap: break-word;
        overflow-wrap: break-word; }
    .intranet.home #col4 section.intranet-home-news ul.news-list li.pinned .news-item {
      padding: 10px;
      background: #ddebf5;
      border-bottom: 2px solid #f0791d; }
      .intranet.home #col4 section.intranet-home-news ul.news-list li.pinned .news-item .news-metadata .category {
        background: none; }
      .intranet.home #col4 section.intranet-home-news ul.news-list li.pinned .news-item img {
        width: 100%;
        height: auto;
        margin: 10px 0; }
      @media screen and (min-width: 480px) {
        .intranet.home #col4 section.intranet-home-news ul.news-list li.pinned .news-item:before, .intranet.home #col4 section.intranet-home-news ul.news-list li.pinned .news-item:after {
          content: " ";
          display: table;
          background: transparent; }
        .intranet.home #col4 section.intranet-home-news ul.news-list li.pinned .news-item:after {
          clear: both; }
        .intranet.home #col4 section.intranet-home-news ul.news-list li.pinned .news-item img {
          float: left;
          width: 20%;
          margin: 0 10px 0 0; } }
      @media screen and (min-width: 1200px) {
        .intranet.home #col4 section.intranet-home-news ul.news-list li.pinned .news-item {
          padding: 18px 15px 21px 15px; }
          .intranet.home #col4 section.intranet-home-news ul.news-list li.pinned .news-item img {
            width: 100%;
            height: 200px;
            margin: -5px 0 25px 0;
            object-fit: cover; } }

.intranet.home #col4 section.intranet-home-news ul.warnings-list {
  position: relative;
  width: 100%;
  margin: 0; }
  .intranet.home #col4 section.intranet-home-news ul.warnings-list li {
    padding: 10px 0;
    margin: 0;
    border-bottom: 1px solid #ddebf5; }
    .intranet.home #col4 section.intranet-home-news ul.warnings-list li a {
      position: relative;
      padding: 10px 30px 10px 34px;
      text-decoration: none;
      display: block; }
      .intranet.home #col4 section.intranet-home-news ul.warnings-list li a:before {
        position: absolute;
        top: 50%;
        left: -4px;
        width: 32px;
        height: 32px;
        margin: -16px 0 0 0;
        font-family: hzdr-font;
        font-size: 32px;
        line-height: 1;
        display: block;
        font-weight: normal;
        content: "\75";
        color: #c00; }
      .intranet.home #col4 section.intranet-home-news ul.warnings-list li a .entry-count {
        position: relative;
        background: #005aa0;
        border-radius: 20px;
        max-height: 20px;
        line-height: 20px;
        min-width: 20px;
        text-align: center;
        color: white;
        font-weight: bold;
        font-size: 12px;
        padding: 2px;
        display: inline-block; }

@media screen and (min-width: 1024px) {
  .intranet.home #col4 section.intranet-home-news ul.news-list {
    margin: 4px 0 0 0; }
    .intranet.home #col4 section.intranet-home-news ul.news-list li .news-item {
      padding: 30px 0;
      box-sizing: border-box; }
    .intranet.home #col4 section.intranet-home-news ul.news-list li.pinned .news-item {
      padding: 30px 15px; } }

@media screen and (min-width: 1200px) {
  .intranet.home #col4 section.intranet-home-news {
    text-align: center; }
    .intranet.home #col4 section.intranet-home-news h3 {
      text-align: left; }
    .intranet.home #col4 section.intranet-home-news ul.warnings-list {
      text-align: left; }
      .intranet.home #col4 section.intranet-home-news ul.warnings-list li {
        padding: 0; }
        .intranet.home #col4 section.intranet-home-news ul.warnings-list li a {
          padding: 19px 70px 20px 34px; }
          .intranet.home #col4 section.intranet-home-news ul.warnings-list li a:before {
            margin-top: -18px; }
          .intranet.home #col4 section.intranet-home-news ul.warnings-list li a .entry-count {
            position: absolute;
            top: 50%;
            margin-top: -11px;
            right: 30px; }
        .intranet.home #col4 section.intranet-home-news ul.warnings-list li:first-child a {
          padding-top: 16px; }
    .intranet.home #col4 section.intranet-home-news ul.news-list {
      margin-top: 7px;
      box-sizing: border-box;
      column-count: 2;
      column-gap: 20px; }
      .intranet.home #col4 section.intranet-home-news ul.news-list li {
        text-align: left;
        box-sizing: border-box;
        break-inside: avoid-column;
        page-break-inside: avoid; }
        .intranet.home #col4 section.intranet-home-news ul.news-list li .news-item .news-metadata {
          margin: 0 0 -1px 0; }
        .intranet.home #col4 section.intranet-home-news ul.news-list li .news-item h4 {
          margin-bottom: 10px; }
        .intranet.home #col4 section.intranet-home-news ul.news-list li .news-item p {
          margin-bottom: 10px; } }

/*-------------------------------------------------------------------------*/
/* Intranet homepage shortcuts area */
.intranet.home #col4 section.intranet-home-shortcuts h4,
.intranet.home #col4 section.intranet-home-favorites h4 {
  position: relative;
  margin: 10px 0;
  padding-left: 30px;
  font-size: 18px;
  color: #555; }
  .intranet.home #col4 section.intranet-home-shortcuts h4:before,
  .intranet.home #col4 section.intranet-home-favorites h4:before {
    position: absolute;
    top: -5px;
    left: -5px;
    width: 30px;
    height: 30px;
    font-family: hzdr-font;
    font-size: 30px;
    line-height: 1;
    color: #f0781e;
    transform: scale(0.8); }

.intranet.home #col4 section.intranet-home-shortcuts ul,
.intranet.home #col4 section.intranet-home-favorites ul {
  min-width: 100%; }

.intranet.home #col4 section.intranet-home-shortcuts h3:before {
  content: "m";
  top: -2px; }

.intranet.home #col4 section.intranet-home-favorites h3:before {
  content: "p";
  top: -2px; }

/*-------------------------------------------------------------------------*/
/* EOF */
