/**
 *
 * Project variables
 * ==========================================================================
 * Value settings for colors, type, breakpoints and
 * base settings for size calculations
 * 
 *
 *
 */

/*-------------------------------------------------------------------------*/

// Media queries
$phoneLandscapeViewportWidth: 480px;
$tabletViewportWidth: 768px;
$tabletLandscapeViewportWidth: 1024px;
$desktopViewportWidth: 1200px;
$ultraWideViewportWidth: 1500px;

/*-------------------------------------------------------------------------*/

// Colors
$hzdrBlue: rgb(0,90,160); //alt rgb(0,91,161)
$hzdrBlueDark: darken($hzdrBlue, 10%);
$hzdrBlueUltraLight: desaturate(lighten($hzdrBlue, 60%),45%);
$hzdrOrange: rgb(240,121,29);
$hzdrOrangeDark: darken($hzdrOrange, 8%);
$hzdrLightgrey: rgb(232,232,232);

//Topic Colors
$topicMatter: $hzdrOrange;
$topicHealth: #d23264;
$topicEnergy: #ffd228;

/*-------------------------------------------------------------------------*/

// Standard Transition for CSS based animations
$transition: all .25s ease-in-out;

/*-------------------------------------------------------------------------*/

/* EOF */
