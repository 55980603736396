/**
 *
 * Content
 * ==========================================================================
 * Design for content elements
 *
 *
 */

/*-------------------------------------------------------------------------*/

/* Basic HTML elements setup */

h1, h2, h3, h4, h5, h6, ul, #col2 ul, #col3 ul, #col4 ul, #col5 ul, #col3 table, #col4 table, #col5 table, #col3 caption, #col4 caption, #col5 caption {
  margin: 0 0 8px 0;
}

textarea, pre, code, kbd, samp, var, tt {
  font-family:Consolas, "Lucida Console", "Andale Mono", "Bitstream Vera Sans Mono", "Courier New", Courier;
}

body {
  font-family:Arial,sans-serif;
  font-size: 16px;
  color:black;
}

/*-------------------------------------------------------------------------*/

/* Headlines */

h1,h2,h3,h4,h5,h6 {
  font-family:Arial,sans-serif;
  font-weight:normal;
  color:black;
}
h1 {
  font-size:24px;
  color:$hzdrBlue;
  font-weight:bold;
  line-height: 36px;
  margin: 0 0 20px 0;
}
/*h1:first-of-type {
  margin-top: 0px;
}*/
h2 {
  font-size: 20px;
  font-weight:bold;
  color: $hzdrBlue;
  line-height: 30px;
  margin: 0 0 14px 0;
  p & +, ul & + {
    margin-top: 16px;
  }
}
h3 {
  font-size: 18px;
  font-weight:bold;
  color: $hzdrBlue;
  line-height: 25px;
  margin: 0 0 12px 0;
  p & +, ul & + {
    margin-top: 14px;
  }
}
h4 {
  font-size: 16px;
  font-weight: bold;
  color: black;
}
h5, h6 {
  font-size:100%;
}

/*-------------------------------------------------------------------------*/

/* Lists */

#col3, #col4, #col5 {
  ul {
    margin:0 0 1rem 0;
    padding:0;
    list-style-type: none !important;
    overflow: hidden;
    li {
      position: relative;
      padding-left:16px;
      margin:0 0 5px 0;
      line-height: 24px;
      &:before {
        position: absolute;
        top: 8px;
        left: 0;
        width: 6px;
        height: 6px;
        background: $hzdrOrange;
        content: " ";
        box-sizing: border-box;
      }
      ul {
        margin-top: 5px;
      }
      li {
        &:before {
          border: 2px solid $hzdrOrange;
          background: white;
        }
        li {
          &:before {
            border-width: 1px;
          }
        }
      }
      p {
        margin:0;
        padding:0;
        line-height:24px;
      }
    }
    ul {
      list-style-type:none;
      margin: 0 0 3px 0;
      display: block;
    }
  }
  ol, dl {
    padding:0;
    line-height: 24px;
  }
  ol {
    list-style-type:decimal;
    margin:0 0 1rem 20px;
    li {      
      margin:0 0 5px 0;
    }
    ol {
      list-style-type:lower-latin;
      margin: 5px 0 5px 20px;
    }
  }
  dl {
    dt {
      font-weight:bold;
    }
    dd {
      margin:0 0 1em 0.8em;
    }
  }
}

#col4_content {
  ul > li, ul > li > p {
    line-height: 18px;
  }
}

/*-------------------------------------------------------------------------*/

/* Text designs */

p {
  line-height: 24px;
  margin-bottom: 1rem;
  &[style^="text-align: justify;"] {
    text-align: left!important;
  }
  &.abstract {
    font-weight: bold;
    line-height: 22px;
    margin: 0 0 1rem 0;
  }
  #col2 & {
    line-height: 1.4;
  }
}

blockquote {
  margin:40px 0;
  border-left: 1px solid $hzdrBlue;
  padding: 10px 0 10px 20px;
  font-size: 18px;
  footer {
    font-weight: bold;
    color: $hzdrBlue;
    cite {
      font-style: normal;
      font-size: 14px;
    }
  }
  @media screen and (min-width: $desktopViewportWidth) {
    padding-right: 100px;
  }
}

strong, b {
  font-weight:bold;
}

em, i {
  font-style:italic;
}

big {
  font-size:116.667%;
}
small {
  font-size:91.667%;
}

pre {
  line-height:1.5em;
  margin:0 0 1em 0;
}

pre, code, kbd, tt, samp, var {
  font-size:110% !important;
}

pre, code {
  font-family:"Courier New", Courier;
}

kbd, samp, var, tt {
  color:#666;
  font-weight:bold;
}
var, dfn {
  font-style:italic;
}

acronym, abbr {
  border-bottom:1px #aaa dotted;
  font-variant:small-caps;
  letter-spacing:.07em;
  cursor:help;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

hr {
  position: relative;
  height: 1px;
  color:white;
  background:transparent;
  margin:20px 0;
  border:0;
  border-bottom: 1px solid lighten($hzdrBlue,0%);
  clear:both;

  &.dashed {
    margin:0 0 10px 0;
    padding:0;
    display:block;
    overflow: hidden;
    border-bottom-style: dashed;
  }
  &.solid {
    margin:0 0 10px 0;
    padding:0;
    display:block;
    overflow: hidden;
    border-bottom-style: solid;
  }
}

/*-------------------------------------------------------------------------*/

/* Links */

a {
  color:$hzdrBlue;
  background:transparent;
  text-decoration:underline;
  &:visited {
    color: desaturate(lighten($hzdrBlue,20%),30%);
  }
}

#footer a {
  color:white;
  line-height: 20px;
}

#main {
  a:hover, a:active {
    color: $hzdrOrange;
  }
}

/*-------------------------------------------------------------------------*/

/* Images with captions */

p {
  &.icaption_left {
    float:left;
    display:inline;
    margin:0 1em 0.15em 0;
  }
  &.icaption_right {
    float:right;
    display:inline;
    margin:0 0 0.15em 1em;
  }
  &.icaption_left, &.icaption_right {
    img {
      padding:0;
      border:1px #888 solid;
    }
    strong {
      display:block;
      overflow:hidden;
      margin-top:2px;
      padding:0.3em 0.5em;
      background:#eee;
      font-weight:normal;
      font-size:91.667%;
    }
  }
}
.main-content-image {
  #col4_content &,
  #col3_content & {
    position: relative;
    max-width: 100%;
    height: auto;
    margin: 0 0 40px 0;
  }
}

figure.image {
  display: table;
  line-height: 0;
  background: $hzdrLightgrey;
  padding: 1px;
  img {
    max-width: 100%;
    height: auto;
    margin: 0;
  }
  figcaption, figcaption p {
    font-size: 14px;
    line-height: 18px;
  }
  figcaption {
    display: table-caption;
    caption-side: bottom;
    max-width: 100%;
    padding: 10px;
    background: $hzdrLightgrey;
    p {
      margin: 2px 0;
      &.figDownload, &.figCopyright {
        color: #555;
      }
    }
    *:empty {
      display: none;
    }
  }
}

figure, aside.figContainer {
  position: relative;
  margin-bottom: 20px;

  &.align-left {
    margin-right: 15px;
    float: left;
    max-width: 60%;
    &:nth-last-child(2) {
      margin-right: 0px;
    }
  }
  &.align-right {
    margin-left: 15px;
    float: right;
    max-width: 60%;
  }
  &.align-center {
    margin-left: auto;
    margin-right: auto;
  }
}

/*-------------------------------------------------------------------------*/

/* Generic content classes */

.highlight {
  color:#c30;
}

.dimmed {
  color:#888;
}

.info {
  background:#f8f8f8;
  color:#666;
  padding:10px;
  margin-bottom:0.5em;
  font-size:91.7%;
}

.note {
  background:#efe;
  color:#040;
  border:2px #484 solid;
  padding:10px;
  margin-bottom:1em;
}

.important {
  background:#ffe;
  color:#440;
  border:2px #884 solid;
  padding:10px;
  margin-bottom:1em;
}

.warning {
  background:#fee;
  color:#400;
  border:2px #844 solid;
  padding:10px;
  margin-bottom:1em;
}

.float_left {
  float:left;
  display:inline;
  margin-right:1em;
  margin-bottom:0.2em;
}

.float_right {
  float:right;
  display:inline;
  margin-left:1em;
  margin-bottom:0.2em;
}

.center {
  display:block;
  text-align:center;
  margin:0.5em auto;
}

/*-------------------------------------------------------------------------*/

/* Boxes and headlines */

.subc3box {
  &.b643 {
    width:634px;
    a {
      text-decoration:none;
    }
  }
  h1 {
    font-size:12px;
  }
  .ce_text {
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    > a {
      margin-left:8px;
    }
  }
  .subcl {
    margin:0 11px 0 0;
    padding:0;
  }
  .subc {
    margin:0 5.5px;
    padding:0
  }
  .subcr {
    margin:0 0 0 11px;
    padding:0
  }
  .subcl, .subc, .subcr {
    h1.box {
      font-size:12px;
      height:31px;
      padding:0 10px;
      background:url("../images/main-page_subc_bg.png") no-repeat scroll left top;
      line-height:2.5em;
      display:block;
      margin:0;
    }
  }
  h1.box_634 {
    font-size:12px;
    height:31px;
    padding:0 10px;
    background:url("../images/h1_box-634.png") no-repeat scroll left top;
    line-height:2.5em;
    display:block;
    margin:0;
  }
}

#col2_content {
  h1 {
    margin:0 0 13px 0;
  }
  .ce_text {
    margin:3px 0 10px 0;
  }
}

.ce_image {
  .caption {
    color:white;
    background:#868889;
    padding:3px;
    a {
      display:block;
      line-height:16px;
      color:white;
      text-decoration:none;
      &.download {
        background:url("../images/caption.png") no-repeat scroll left top;
        padding-left: 20px;
      }
    }
  }
}

#footer .ce_text {
  overflow:hidden;
  padding:0 0 0 5px;
  float:left;
}

.gray_box {
  border: 1px solid $hzdrBlue;
  font-size: 12px;
  padding-top:8px;
  padding-bottom:8px;
  padding-left:5px;
  padding-right:5px;
  margin: 3px 0;
  overflow: hidden;
  h1 {
    font-size:120%;
  }
  .c80l a {
    line-height: 22px;
    text-decoration: none;
    display:block;
  }
  a:hover {
    text-decoration: underline;
  }
}


.more {
  display:block;
  padding:0 12px 0 0;
  margin:0 0 2px 0;
  text-align:right;
  background:url("../images/nav_more.png") no-repeat scroll right 50%;
  text-decoration:none;
  &.left {
    display:inline;
  }
}

.c10l {
  float:left;
  width:10%;
}

.c90r {
  float:right;
  width:90%;
  margin-left:-5px;
}

#col3, #col4 {
  .mod_search {
    span {
      color:$hzdrBlueDark;
      font-weight:bold;
    }
    .form_text span {
      margin:0 20px 0 0;
    }
    .form_checkbox {
      margin:10px 0 0 65px;
      input {
        border:1px solid $hzdrBlueDark;
        background-color:white;
      }
    }
    .form_checkbox span {
      margin:0 15px 0 8px;
    }
    .type-image {
      margin:0 0 0 10px;
    }
    .type-text {
      border: 1px solid black;
      padding:2px;
      width:350px;
    }
  }
}

/*-------------------------------------------------------------------------*/

/* Sidebar styles */

.sidebar, #col2_content {
  h1 {
    display: block;
    font-size: 12px;
    font-weight: normal;
    padding: 5px 0 5px 10%;
    text-decoration: none;
    color:white;
    margin:0;
    line-height: 16px;
    background: #555;
    &.blue {
      background-color: #004776;
    }
  }
  .sidebarelement {
    margin-top: 1px;
    h1 {
      padding-left: 6px;
      a {
        color: white;
      }
    }
    .sidebartext {
      font-size:0.79em;
      line-height:1.3em;
      margin: 5px 0 7px 0;
      h1 {
        font-weight: bold;
        margin: 0 0 5px 0;
        clear: both;
      }
      h2, h3 {
        font-size:11px;
        margin: 0 5px 3px 5px;
        clear: both;
      }
      p {
        margin: 5px 0;
        padding-bottom:0;
        padding-top: 0;
        clear: both;
      }
      ul, ol {
        margin: 5px 0 5px 3px;
        padding: 0 0 3px 3px;
      }
      hr {
        color:white;
        background:transparent;
        margin: 6px 6px 6px 3px;
        padding: 0 0 0 0;
        border-bottom:1px $hzdrBlue dashed;
      }
      img {
        margin: 0 3px 0 0;
        max-width: 200px;
      }
    }
  }
  .sidebarpic {
    width: 180px;
    text-align:center;
    img {
      max-width: 200px;
    }
  }
  .sidbar90pic {
    width: 100px;
    max-width: 100px !important;
  }
}


/*-------------------------------------------------------------------------*/

/* Tables */
table {
  &.intra {
    width:auto;
    border-collapse:separate;
    border:0;
    margin-top:2em;
    margin-bottom:1em;
    border-spacing: 1px;
    th, td {
      border:0;
      padding:0.5em;
      padding-left:1.5em;
    }
    thead {
      th, td {
        background:$hzdrBlue;
        color:white;
      }
    }
    tbody {
      th.odd, td.odd {
        background:#dce5f2;
      }
      th.even, td.even {
        background:white;
      }
      tr:hover {
        th, td {
          background:#eddbc1;
        }
      }
    }
    tfoot {
      th, td {
        background:#a9abac;
        color:white;
        font-weight:bold;
      }
    }
  }
  &.tbSiteMap {
    td img {
      display:block;
    }
  }
  caption {
    font-weight:bold;
    font-size:80%;
  }
  &.full {
    width:100%;
  }
  &.fixed {
    table-layout:fixed;
  }

  tbody th {
    text-align:left;
  }
  h1, h2, h3 {
    margin: 0;
    padding: 0;
    line-height:1.3em;
  }
}

/*-------------------------------------------------------------------------*/

/* Contact Informations inside content area */
.additional-content {
  #col4 & {
    .contact-container {
      border: 1px solid lighten($hzdrBlue,50%);
      border-width: 1px 0;
      padding: 20px 0;
      li {
        padding: 10px 0;
        &:before {
          display: none;
        }
        h4 {
          margin: 0;
        }
        p {
          line-height: 20px;
        }
      }
      @media screen and (min-width: $phoneLandscapeViewportWidth) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        li {
          flex: 1 0 auto;
          padding: 10px;
        }
      }
    }
  }  
}

/*-------------------------------------------------------------------------*/
/* EOF */
